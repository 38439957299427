import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { editMarketplaceResponse } from '../actions';

export default function* editMarketplace({ payload: { data } }) {
  const url = 'users/v1/seller/updateStore';

  try {
    const response = yield call(fetchWrapper, {
      url,
      method: 'PUT',
      params: data,
    });

    yield put(editMarketplaceResponse(response));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
