import { put } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import config from 'config';
import { downloadFakeReportResponse } from '../actions';

export default function* downloadFakeReport({ payload: { token } }) {
  const url = `fakeReport/v1/download/${token}`;

  try {
    FileSaver.saveAs(`${config.api.hostname}/${url}`, `refundassistancereport-${token}`);
    yield put(downloadFakeReportResponse());
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
