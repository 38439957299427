import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { getAuthenticationResponseConfResponse } from '../actions';

export default function* getAuthenticationResponseConf({ payload: { authenticationId } }) {
  const url = 'config/v2/authentication/response';

  try {
    const response = yield call(fetchWrapper, {
      url,
      withQuery: true,
      params: {
        authenticationId,
      },
    });

    yield put(getAuthenticationResponseConfResponse(response));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
