import { Div } from '@ebay-certilogo/design-system-web';
import styled from 'styled-components';

export default styled(Div)`
  z-index: 3;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
