import fetchWrapper from 'utils/Api/fetchWrapper';
import { call, put } from 'redux-saga/effects';
import { getBrandDomainName, isBrandedDomain } from 'utils/utils';
import packageJson from 'containers/../../package.json';
import { codeLoginCheckResponse, loginResponse } from '../actions';

export default function* codeLoginCheck({ payload: { email, pin, token, redirect } }) {
  const url = `users/v2/code-login/check`;

  try {
    const response = yield call(fetchWrapper, {
      url,
      method: 'POST',
      params: {
        email,
        pin,
        token,
        applicationVersion: packageJson.version,
        domain: isBrandedDomain() ? getBrandDomainName() : null,
      },
    });

    yield put(codeLoginCheckResponse({ data: response }));
    yield put(loginResponse({ data: response, network: 'local', redirect }));
  } catch ({ packet, response }) {
    if (response.status >= 400) {
      console.log({ packet });
      yield put(
        codeLoginCheckResponse({
          error: packet.error || 'invalid',
          data: { timeToWait: packet.timeToWait },
        }),
      );
    }
  }
}
