import { getBaseObject } from './utils';

const interactionEvent = (eventDef) => (action, prevState, nextState) => {
  const { skipEvent, ...event } = eventDef(action, prevState, nextState);

  if (skipEvent) return null;

  const baseObject = getBaseObject({ action, prevState, nextState });
  return {
    ...baseObject,
    event: 'GAinteraction',
    eventName: 'interaction',
    tool: action?.payload?.gtm?.tool?.name,
    status: action?.payload?.locked ? 'inactive' : 'active',
    action: action?.payload?.action,
    label: action?.payload?.label,
    link: action?.payload?.link,
    type: action?.payload?.type,
    step: action?.payload?.step,
    view: action?.payload?.view || 0,
    start: action?.payload?.start || 0,
    finish: action?.payload?.finish || 0,
    sessionID: action?.payload?.sessionId,
    ...event,
  };
};

export default interactionEvent;
