import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  createRequest,
  createResponse,
  flowRequest,
  flowResponse,
  imageUploadRequest,
  imageLabelUploadResponse,
  imageProductUploadResponse,
  marketplaceRequest,
  marketplaceResponse,
  addMarketplaceRequest,
  addMarketplaceResponse,
  editMarketplaceRequest,
  editMarketplaceResponse,
  resetSoa,
  getMarketplaceSoaRequest,
  getMarketplaceSoaResponse,
  downloadSoaRequest,
  downloadSoaResponse,
  getSoaRequest,
  getSoaResponse,
} from './actions';

export const initialState = {
  loading: false,
  data: null,
  uploadLoading: false,
  uploadLabels: [],
  productImage: null,
  marketplaceLoading: false,
  marketplace: null,
  searchLoading: false,
  search: null,
  downloadSoaLoading: false,
  marketplaceSoa: {
    data: null,
    loading: false,
  },
};

const mutableReducer = handleActions(
  {
    [createRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [createResponse]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload;
    }),
    [flowRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [flowResponse]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload;
    }),
    [getMarketplaceSoaRequest]: produce((draft) => {
      draft.marketplaceSoa.loading = true;
    }),
    [getMarketplaceSoaResponse]: produce((draft, { payload }) => {
      draft.marketplaceSoa.loading = false;
      draft.marketplaceSoa.data = payload;
    }),
    [imageUploadRequest]: produce((draft) => {
      draft.uploadLoading = true;
    }),
    [imageLabelUploadResponse]: produce((draft, { payload }) => {
      draft.uploadLoading = false;
      draft.uploadLabels.push(payload.filename);
    }),
    [imageProductUploadResponse]: produce((draft, { payload }) => {
      draft.uploadLoading = false;
      draft.productImage = payload.filename;
    }),
    [marketplaceRequest]: produce((draft) => {
      draft.marketplaceLoading = true;
    }),
    [marketplaceResponse]: produce((draft, { payload }) => {
      draft.marketplaceLoading = false;
      draft.marketplace = payload;
    }),
    [addMarketplaceRequest]: produce((draft) => {
      draft.marketplaceLoading = true;
    }),
    [addMarketplaceResponse]: produce((draft, { payload }) => {
      draft.marketplaceLoading = false;
      draft.marketplace.push(payload);
    }),
    [editMarketplaceRequest]: produce((draft) => {
      draft.marketplaceLoading = true;
    }),
    [downloadSoaRequest]: produce((draft) => {
      draft.downloadSoaLoading = true;
    }),
    [downloadSoaResponse]: produce((draft) => {
      draft.downloadSoaLoading = false;
    }),
    [getSoaRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [getSoaResponse]: produce((draft) => {
      draft.loading = false;
    }),
    [editMarketplaceResponse]: produce((draft, { payload }) => {
      draft.marketplaceLoading = false;
      const objIndex = draft.marketplace.findIndex((obj) => obj.id === payload.id);
      draft.marketplace[objIndex] = payload;
    }),
    [resetSoa]: () => initialState,
  },
  initialState,
);

export default mutableReducer;
