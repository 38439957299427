/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Br, Slider } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import HeaderBox from 'containers/Authentication/components/HeaderBox';
import ButtonComponent from 'components/ButtonComponent';
import FlowButton from 'containers/Authentication/components/FlowButton';
import { imageUploadTakePicture } from 'utils/reduxBeaconGtm/actions/authentication';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { selectResellWithEbayImagesCount } from 'containers/ResellWithEbay/selectors';
import useColorType from 'components/hooks/useColorType';
import LaterPopup from 'components/Camera/LaterPopup';
import { Image, SliderWrapper } from '../styles';

const Intro = ({ onSkip, log, config = {}, images, messages }) => {
  const colorType = useColorType('resellWithEbay');
  const [laterPopupIsOpen, setLaterPopupIsOpen] = useState(false);
  const imagesCount = useSelector(selectResellWithEbayImagesCount);
  const dispatch = useDispatch();
  const introImages = config?.introImages;
  const requestedImages = images?.length || 1;

  const handleSkip = () => {
    log('pageSkip');
    onSkip();
  };

  const handleLater = () => {
    if (imagesCount < requestedImages) {
      setLaterPopupIsOpen(true);
    } else {
      handleSkip();
    }
  };
  return (
    <>
      <HeaderBox
        monochrome
        colorType={colorType}
        marginBottom="s"
        text={<FormattedMessage {...messages.intro.title} values={{ linebreak: <Br /> }} />}
      />
      {!isEmpty(introImages) && (
        <SliderWrapper>
          <Slider arrows={false} showsButtons={false}>
            {introImages.map((introImage) => (
              <Image
                src={introImage.url}
                source={{ url: introImage.url }}
                alt={introImage.id}
                margin={{ size: 's', position: 'bottom' }}
              />
            ))}
          </Slider>
        </SliderWrapper>
      )}
      <ButtonComponent
        component={FlowButton}
        to="image_upload/camera"
        colorType={colorType}
        noMargin
        onClick={() => {
          dispatch(imageUploadTakePicture());
        }}
      >
        <FormattedMessage {...messages.intro.buttonStart} />
      </ButtonComponent>
      <Button onClick={handleLater} colorType={colorType} buttonType="link" arrow={false}>
        <FormattedMessage {...messages.intro.buttonSkip} />
      </Button>
      <LaterPopup
        colorType={colorType}
        isOpen={laterPopupIsOpen}
        setIsOpen={setLaterPopupIsOpen}
        handleSubmit={handleSkip}
        message={<FormattedMessage {...messages.intro.laterPopup.message} />}
        primaryButtonMessage={<FormattedMessage {...messages.intro.laterPopup.primaryButton} />}
        secondaryButtonMessage={<FormattedMessage {...messages.intro.laterPopup.secondaryButton} />}
      />
    </>
  );
};

Intro.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  config: PropTypes.object,
  images: PropTypes.array,
  messages: PropTypes.object,
  onSkip: PropTypes.func,
  log: PropTypes.func,
};

export default Intro;
