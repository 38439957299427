import { identity, pickBy } from 'lodash';
import set from 'lodash/set';

export const getFetchHeaders = ({
  formData,
  authenticated = true,
  headers: { jwtToken, locale } = {},
}) => {
  const token = authenticated && jwtToken ? `Bearer ${jwtToken}` : undefined;

  const contentType = !(formData || {}).get && 'application/json';

  return pickBy(
    {
      Authorization: token,
      Accept: !formData && 'application/json',
      'Content-Type': contentType,
      'Accept-Language': locale,
    },
    identity,
  );
};

// this change a path error like "data/locations/2/address/0/city"
// to a more readable for lodash "locations[2].address[0].city"
const strErrorForLodash = (errorPath) =>
  errorPath
    .replace('data/', '')
    .replace(/(\/([0-9]+))/g, '[$2]')
    .replace(/[/]/g, '.');

// this return an object to inject in @@redux-form/SubmitErrors
export const remodelServerErrors = (errors) =>
  Object.entries(errors).reduce((acc, [key, value]) => {
    set(acc, strErrorForLodash(key), value.join(', '));
    return acc;
  }, {});
