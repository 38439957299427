import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ProgressBarTimer, Text, Heading } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import { unsetBack } from 'containers/App/actions';
import { flowRequest } from 'containers/Authentication/actions';
import { useParams } from 'react-router-dom';
import useColorType from 'components/hooks/useColorType';
import messages from './messages';
import { Wrapper, Body, Image } from './styles';
import soaSeal from './assets/soaseal.png';

function CompletedSeller() {
  const colorType = useColorType('soaCompletedSeller');
  const { authenticationId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(unsetBack());
  }, []);

  const handleEndTimer = () => {
    dispatch(flowRequest({ action: 'next', authenticationId, status: 'soa' }));
  };

  return (
    <Wrapper>
      <Body>
        <Heading type="h1" textAlign="center" themeField="primaryAccentColor" colorType={colorType}>
          <FormattedMessage {...messages.title} />
        </Heading>
        <Image src={soaSeal} />
        <Text textAlign="center" colorType={colorType}>
          <FormattedMessage {...messages.text} />
        </Text>
      </Body>
      <ProgressBarTimer
        interval={7000}
        onComplete={handleEndTimer}
        colorType={colorType}
        marginBottom="m"
      />
    </Wrapper>
  );
}

CompletedSeller.propTypes = {
  colorType: PropTypes.string,
};

export default CompletedSeller;
