import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { authenticationResultRequest } from 'containers/Authentication/actions';
import LoadingIndicator from 'components/LoadingIndicator';
import { useInjectSaga } from 'utils/injectSaga';
import { useParams } from 'react-router-dom';
import saga from '../saga';

function Result() {
  useInjectSaga({ key: 'authentication', saga });
  const dispatch = useDispatch();
  const { hash } = useParams();

  useEffect(() => {
    if (hash) {
      dispatch(authenticationResultRequest({ hash }));
    }
  }, []);

  return <LoadingIndicator />;
}

Result.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
  }),
};

export default Result;
