import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { confirmEmailResponse, loginResponse } from '../actions';

export default function* confirmPin({ payload: { pin, hash } }) {
  const url = `users/v2/confirm/${pin ? 'pin' : 'hash'}`;

  try {
    const response = yield call(fetchWrapper, {
      url,
      method: 'POST',
      params: {
        pin,
        hash,
      },
    });
    yield put(confirmEmailResponse({}));
    yield put(loginResponse({ data: response, redirect: true }));
  } catch ({ packet, response }) {
    if (response.status >= 400) {
      yield put(confirmEmailResponse({ errors: packet.errors || ['confirm_email.error'] }));
    }
  }
}
