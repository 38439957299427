import genericEvents from './generic';
import pageLoadEvents from './pageLoad';
import featureLoadEvents from './featureLoad';
import authenticationEvents from './authentication';
import dynamicComponentsEvents from './dynamicComponents';

export default {
  ...pageLoadEvents,
  ...featureLoadEvents,
  ...genericEvents,
  ...authenticationEvents,
  ...dynamicComponentsEvents,
};
