import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { finalizeResponse } from '../actions';

export default function* finalize({ payload: { id } }) {
  const url = 'claim-ownership/v1/finalize/:id';
  // const url = 'http://localhost:3101/claim-ownership/v1/finalize/:id';

  try {
    const response = yield call(fetchWrapper, {
      method: 'POST',
      url: url.replace(':id', id),
    });

    yield put(finalizeResponse(response));
  } catch (err) {
    console.log(err);
    yield put(finalizeResponse());
  }
}
