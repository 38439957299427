import { createActions } from 'redux-actions';

const collection = 'fakeReport';

export const {
  createRequest,
  createResponse,
  updateRequest,
  updateResponse,
  downloadFakeReportRequest,
  downloadFakeReportResponse,
} = createActions(
  {
    CREATE_REQUEST: ({ authenticationId, channel }) => ({
      authenticationId,
      channel,
    }),
    CREATE_RESPONSE: (data) => ({ ...data }),
    UPDATE_REQUEST: ({ params, action, authenticationId, id, status, goToHome }) => ({
      params,
      action,
      authenticationId,
      id,
      status,
      goToHome,
    }),
    UPDATE_RESPONSE: (data) => ({ ...data }),
    DOWNLOAD_FAKE_REPORT_REQUEST: ({ token }) => ({
      token,
    }),
    DOWNLOAD_FAKE_REPORT_RESPONSE: () => null,
  },
  { prefix: collection },
);
