import frameDefault from './frame.svg';

export default {
  images: [
    { type: 'label', required: true },
    { type: 'product', required: true },
  ],
  config: {
    introImages: null,
    camera: {
      captions: [
        {
          frame: {
            url: frameDefault,
          },
        },
        {
          frame: {
            url: frameDefault,
          },
        },
      ],
    },
  },
};
