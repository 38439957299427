
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Modal, Heading, Text } from '@ebay-certilogo/design-system-web';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import { Wrapper } from './styles';

function HelpModal({ colorType, messages, ...rest }) {
  return (
    <Modal colorType="dark" close {...rest}>
      <Wrapper colorType={colorType}>
        <Heading type="h2" themeField="primaryAccentColor" colorType="dark">
          <FormattedMessageMarkdown {...messages.title1} />
        </Heading>
        <Text colorType="dark" marginBottom="l">
          <FormattedMessageMarkdown {...messages.text1} />
        </Text>
        <Heading type="h2" themeField="primaryAccentColor" colorType="dark">
          <FormattedMessageMarkdown {...messages.title2} />
        </Heading>
        <Text colorType="dark" marginBottom="l">
          <FormattedMessageMarkdown {...messages.text2} />
        </Text>
        <Heading type="h2" themeField="primaryAccentColor" colorType="dark">
          <FormattedMessageMarkdown {...messages.title3} />
        </Heading>
        <Text colorType="dark" marginBottom="l">
          <FormattedMessageMarkdown {...messages.text3} />
        </Text>
      </Wrapper>
    </Modal>
  );
}

HelpModal.propTypes = {
  colorType: PropTypes.string,
  messages: PropTypes.object,
};

export default injectIntl(HelpModal);
