import produce from 'immer';
import { handleActions } from 'redux-actions';
import { getRequest, getResponse } from './actions';

export const initialState = {
  loading: true,
  data: null,
};

const mutableReducer = handleActions(
  {
    [getRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [getResponse]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload;
    }),
  },
  initialState,
);

export default mutableReducer;
