import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'generic_error.title1',
    defaultMessage: 'We’re sorry…',
  },
  text: {
    id: 'generic_error.text1',
    defaultMessage:
      "Something's gone wrong with our system. Our tech team has been notified. Please try again later.",
  },
  tryAgain: {
    id: 'generic_error.button1',
    defaultMessage: 'Try again',
  },
  link: {
    id: 'generic_error.text2',
    defaultMessage: 'Visit our website',
  },
});
