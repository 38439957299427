import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';

import { push } from 'connected-react-router';
import { codeReviewResponse } from '../actions';

export default function* create({ payload: { token } }) {
  const url = `codeReview/v3/update/${token}`;

  try {
    const response = yield call(fetchWrapper, {
      method: 'PUT',
      url,
    });

    yield put(codeReviewResponse(response));
    yield put(push(`/code-review/${response.token}/${response.status}`));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}
