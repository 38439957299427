import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { imageUploadResponse, imageDeleteResponse } from '../actions';

export function* upload({ payload: { claimOwnershipId, type, file: fileData } }) {
  // const url = 'claim-ownership/v1/image';
  const url = 'claim-ownership/v1/image';

  const params = new FormData();
  params.append('file', fileData);
  params.append('type', type);
  params.append('claimOwnershipId', claimOwnershipId);

  try {
    const response = yield call(fetchWrapper, {
      method: 'POST',
      url,
      formData: params,
    });

    if (response.eligible) {
      yield put(imageUploadResponse(response));
    } else {
      yield put(imageUploadResponse());
      yield put(push('?', { tryAgain: true }));
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
    yield put(imageUploadResponse());
  }
}

export function* remove({ payload: { id } }) {
  // const url = 'claim-ownership/v1/image/:id';
  const url = 'claim-ownership/v1/image/:id';

  try {
    yield call(fetchWrapper, {
      method: 'DELETE',
      url: url.replace(':id', id),
    });

    yield put(imageDeleteResponse(id));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
    yield put(imageDeleteResponse());
  }
}
