import React, { useCallback } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { fetchWrapper } from 'utils/Api/fetchWrapper';
import { selectJwt } from 'containers/Auth/selectors';
import { FormFormik, Button, Box, Modal, Div } from '@ebay-certilogo/design-system-web';
import { StoreOnline } from '@ebay-certilogo/design-system-web/dist/components/icons';
import { addMarketplaceRequest, editMarketplaceRequest } from 'containers/Soa/actions';
import Form from 'components/form/FormComponent';
import messages from './messages';

const { TextInput, Select } = FormFormik;

const Wrapper = styled(Div)``;

const validationSchema = Yup.object().shape({
  storeId: Yup.string().required(),
  alias: Yup.string().required(),
});

const validationSchemaEdit = Yup.object().shape({
  alias: Yup.string().required(),
});

const debounce = (fn, delay) => {
  let timeoutId;
  return (...args) => {
    clearInterval(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
};

function AddMarketplaceModal({ colorType, intl, data, setMarketplaceIsOpen, marketplaceIsOpen }) {
  const dispatch = useDispatch();
  const jwtToken = useSelector(selectJwt);

  const handleSubmit = (params) => {
    if (!data) dispatch(addMarketplaceRequest({ data: params }));
    else dispatch(editMarketplaceRequest({ data: { storeId: data.id, alias: params.alias } }));

    setMarketplaceIsOpen(null);
  };

  const searchCall = (inputValue, callback) => {
    fetchWrapper({
      url: 'store/v3/search',
      withQuery: true,
      options: {
        headers: { jwtToken },
        body: {
          online: true,
          url: inputValue,
          category: 'marketplace',
        },
      },
    }).then(({ stores }) => {
      const options = (stores || []).map((el) => ({
        value: el.id,
        label: el.name,
      }));
      callback(options);
    });
  };

  const defaultValue = data && {
    value: data.id,
    label: data.store.name,
  };
  const handleSelectSearch = useCallback(debounce(searchCall, 500), []);

  return (
    <Modal
      colorType={colorType}
      isOpen={marketplaceIsOpen}
      onClose={() => setMarketplaceIsOpen(null)}
      fullScreen
      close
    >
      <Wrapper colorType={colorType}>
        <Box
          colorType={colorType}
          title={<FormattedMessage {...messages.title} />}
          description={<FormattedMessage {...messages[data ? 'editStore' : 'addStore']} />}
        />
        <Formik
          initialValues={{ ...data }}
          validationSchema={data ? validationSchemaEdit : validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <Field
                name="storeId"
                component={Select}
                colorType={colorType}
                defaultValue={defaultValue}
                placeholder={intl.formatMessage(messages.nameOrAddress)}
                icon={<StoreOnline width={26} height={26} colorType={colorType} />}
                isDisabled={!!data}
                loadOptions={handleSelectSearch}
                async
                isSearchable
              />
              <Field
                name="alias"
                component={TextInput}
                colorType={colorType}
                // eslint-disable-next-line react/jsx-no-useless-fragment
                icon={<></>}
                placeholder={intl.formatMessage(messages.alias)}
              />
              <Button colorType={colorType} type="submit">
                <FormattedMessage {...messages.button} />
              </Button>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </Modal>
  );
}

AddMarketplaceModal.propTypes = {
  colorType: PropTypes.string,
  intl: PropTypes.object,
  setMarketplaceIsOpen: PropTypes.func,
  data: PropTypes.object,
  marketplaceIsOpen: PropTypes.bool,
};

export default injectIntl(AddMarketplaceModal);
