import { createActions } from 'redux-actions';

const collection = 'claimOwnership';

export const {
  getRequest,
  createRequest,
  flowRequest,
  claimOwnershipResponse,
  imageUploadRequest,
  imageUploadResponse,
  imageDeleteRequest,
  imageDeleteResponse,
  resetClaimOwnership,
  finalizeRequest,
  finalizeResponse,
} = createActions(
  {
    GET_REQUEST: ({ id }) => ({ id }),
    CREATE_REQUEST: ({ sessionId }) => ({ sessionId }),
    FLOW_REQUEST: ({ id, action, status, data }) => ({
      id,
      action,
      status,
      data,
    }),
    CLAIM_OWNERSHIP_RESPONSE: (data) => ({ ...data }),
    IMAGE_UPLOAD_REQUEST: ({ claimOwnershipId, type, file }) => ({
      claimOwnershipId,
      type,
      file,
    }),
    IMAGE_UPLOAD_RESPONSE: (data) => ({ ...data }),
    IMAGE_DELETE_REQUEST: (id) => ({ id }),
    IMAGE_DELETE_RESPONSE: (id) => ({ id }),
    RESET_CLAIM_OWNERSHIP: () => null,
    FINALIZE_REQUEST: (id) => ({ id }),
    FINALIZE_RESPONSE: (data) => data,
  },
  { prefix: collection },
);
