import produce from 'immer';
import { handleActions } from 'redux-actions';
import { serviceRequest, serviceResponse, resetProxy } from './actions';

export const initialState = {
  loading: false,
  data: null,
};

const mutableReducer = handleActions(
  {
    [serviceRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [serviceResponse]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload;
    }),
    [resetProxy]: produce((draft) => {
      draft.loading = initialState.loading;
      draft.data = initialState.data;
    }),
  },
  initialState,
);

export default mutableReducer;
