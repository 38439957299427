import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getSoaRequest } from 'containers/Soa/actions';
import LoadingIndicator from 'components/LoadingIndicator';
import { useInjectSaga } from 'utils/injectSaga';
import { useParams } from 'react-router-dom';
import saga from '../saga';

function YourSoa() {
  useInjectSaga({ key: 'soa', saga });
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getSoaRequest(id));
    }
  }, []);

  return <LoadingIndicator />;
}

YourSoa.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
  }),
};

export default YourSoa;
