import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { setAuthErrors, forgotPasswordResponse } from '../actions';

export default function* forgotPassword({ payload: { email } }) {
  const url = `users/v2/password/forgot`;

  try {
    yield call(fetchWrapper, {
      url,
      method: 'POST',
      params: {
        email,
      },
    });
    yield put(forgotPasswordResponse());
  } catch ({ packet, response }) {
    if (response.status >= 400) {
      if (packet.errors[0] === 'login.errors.locked') yield put(push(`/auth/account-blocked`));
      else yield put(setAuthErrors(packet.errors));
    }
  }
}
