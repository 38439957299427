import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'imp_after_appeal.title1',
    defaultMessage: 'Please, have a little patience',
  },
  text: {
    id: 'imp_after_appeal.text1',
    defaultMessage:
      "This is a special case: our Artificial Intelligence system needs the assistance of one of our experts. We'll send you a response via email.This usually takes less than 24 hours.",
  },
  button1: {
    id: 'imp_after_appeal.button1',
    defaultMessage: 'Learn more about Certilogo',
  },
  button2: {
    id: 'imp_after_appeal.button2',
    defaultMessage: "Visit the brand's website",
  },
});
