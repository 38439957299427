import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectQueryParams } from 'containers/App/selectors';
import { getConfirmAuthenticationRequest } from 'containers/Authentication/actions';
import LoadingIndicator from 'components/LoadingIndicator';
import { useInjectSaga } from 'utils/injectSaga';
import saga from '../saga';

function Confirm() {
  useInjectSaga({ key: 'authentication', saga });
  const dispatch = useDispatch();
  const params = useSelector(selectQueryParams);

  useEffect(() => {
    if (params) {
      dispatch(getConfirmAuthenticationRequest(params));
    }
  }, []);

  return <LoadingIndicator />;
}

Confirm.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
  }),
};

export default Confirm;
