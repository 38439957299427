import { createActions } from 'redux-actions';

const collection = 'gtm/authentication';

export const { checkAutenticity, imageUploadTakePicture, optinPrivacy } = createActions(
  {
    CHECK_AUTENTICITY: () => ({}),
    IMAGE_UPLOAD_TAKE_PICTURE: () => ({}),
    OPTIN_PRIVACY: () => ({}),
  },
  { prefix: collection },
);
