import { call, put } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import config from 'config';
import getHeaders from 'utils/Api/getHeaders';
import { downloadSoaResponse } from '../actions';

export default function* downloadSoa({ payload: { id } }) {
  const { jwtToken } = yield call(getHeaders, { noCheckJWT: false });
  const url = `soa/v1/download/${id}?access_token=${jwtToken}`;

  try {
    FileSaver.saveAs(`${config.api.hostname}/${url}`, `soa_${id}`);
    yield put(downloadSoaResponse());
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
