import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';

import { push } from 'connected-react-router';
import { codeReviewResponse } from '../actions';

export default function* create({ payload: { token, authenticationId } }) {
  const url = 'codeReview/v3/create';

  try {
    const response = yield call(fetchWrapper, {
      method: 'POST',
      url,
      params: {
        token,
        authenticationId,
      },
    });

    yield put(codeReviewResponse(response));
    yield put(push(`/code-review/${response.token}/${response.status}`));
  } catch ({ response }) {
    if (response.status === 404) {
      yield put(push(`/`));
    }
  }
}
