import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { getImage } from 'utils/images';
import Div from '@ebay-certilogo/design-system-web/dist/components/Div';

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  vertical-align: middle; // Fix bottom space between wrapper and image
`;
const Svg = styled(SVG)`
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
`;

function Image({
  image,
  imageVar,
  description,
  style,
  link,
  componentType,
  onClick,
  propsBag: { logger } = {},
  gtm,
  ...rest
}) {
  const [isLoading, setIsLoading] = useState(false);
  const imageUrl = getImage(imageVar || image);
  const isSvg = useMemo(() => /\.(svg)$/i.test(imageUrl), [imageUrl]);
  const Component = isSvg ? Svg : Img;
  const linkProps = {
    href: link?.url,
    target: link?.blank && '_blank',
  };
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (logger) {
      logger({
        action: 'click',
        type: componentType,
        link: link?.url,
        step: 'finish',
        start: 1,
        finish: 1,
        gtm,
      });
    }
  };

  useEffect(() => {
    if (isSvg) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 10);
    }
  }, [imageUrl, isSvg]);

  return (
    imageUrl && (
      <Div as={link && 'a'} {...linkProps} {...style} {...rest} onClick={handleClick}>
        {!isLoading && <Component src={imageUrl} alt={description} />}
      </Div>
    )
  );
}
Image.propTypes = {
  image: PropTypes.object,
  imageVar: PropTypes.object,
  description: PropTypes.string,
  style: PropTypes.object,
  link: PropTypes.object,
  componentType: PropTypes.string,
  propsBag: PropTypes.object,
  onClick: PropTypes.func,
  gtm: PropTypes.object,
};

export default Image;
