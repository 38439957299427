import find from 'lodash/find';
import { getBaseObject } from './utils';

const pageTypes = {
  '/connect': 'brand_homepage',
  '/auth/profile/settings': 'profile',
  '/auth/sign-up': 'signup',
  '/terms': 'info',
  '/privacy': 'info',
  '/cookie-policy': 'info',
  '/generic-error': 'error',
  '/authentication': 'authentication',
};

const getPageType = (action) => {
  const pathname = action.payload.location.pathname;
  return find(pageTypes, (obj, key) => pathname.includes(key)) || 'other';
};

const pageLoadEvent = (eventDef) => (action, prevState, nextState) => {
  const event = eventDef(action, prevState, nextState);
  const baseObject = getBaseObject({ action, prevState, nextState });

  return {
    ...baseObject,
    event: 'pageLoad',
    page: action.payload.location.pathname,
    pageType: getPageType(action),
    ...event,
  };
};

export default pageLoadEvent;
