export default [
  'code',
  'qr',
  'ag',
  'nfc',
  'ocr',
  'ocr-typed',
  'check',
  'extqr',
  'extnfc',
  'otp',
  'tid',
];
