import { defineMessages } from 'react-intl';

export default defineMessages({
  done: {
    id: 'fake_report_response_download.title1',
    defaultMessage: 'Done',
  },
  downloadComplete: {
    id: 'fake_report_response_download.text1',
    defaultMessage: 'Download complete',
  },
  description: {
    id: 'fake_report_response_download.text2',
    defaultMessage:
      'Now you can send the Fake Report to your seller, platform or payment service with your refund request, then shop for the authentic product you wanted all along.',
  },
});
