import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Box, Br, Button, Div, ModalAssistant, Text } from '@ebay-certilogo/design-system-web';
import { useParams } from 'react-router-dom';
import { useInjectSaga } from 'utils/injectSaga';
import get from 'lodash/get';
import LoadingIndicator from 'components/LoadingIndicator';
import useColorType from 'components/hooks/useColorType';
import messages from './messages';
import { createRequest, downloadSoaRequest, flowRequest, resetSoa } from '../actions';
import saga from '../saga';
import { selectDownloadSoaLoading, selectSoaData, selectSoaLoading } from '../selectors';
import MarketplaceListForm from '../MarketplaceListForm';

function Manage() {
  const colorType = useColorType('soaManage');
  useInjectSaga({ key: 'soa', saga });
  const { authenticationId } = useParams();
  const [isOpenSoldModal, setIsOpenSoldModal] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(createRequest({ authenticationId }));
    dispatch(resetSoa());
  }, []);

  const isLoading = useSelector(selectSoaLoading);
  const isDownloadSoaLoading = useSelector(selectDownloadSoaLoading);
  const data = useSelector(selectSoaData);
  const id = get(data, 'id');
  const status = get(data, 'status');
  const page = get(data, 'page');
  const certilogoCode = get(data, 'code.certilogoCode');
  const primaryBrandName = get(data, 'code.brandName');

  const handleDownload = () => {
    dispatch(downloadSoaRequest(id));
  };

  const handleSubmit = (values) => {
    dispatch(
      flowRequest({
        action: 'next',
        authenticationId,
        id,
        status,
        data: { id, marketplaceAliases: values },
      }),
    );
  };

  const handleSell = () => {
    dispatch(
      flowRequest({
        action: 'sell',
        authenticationId,
        id,
        status,
        page,
      }),
    );
  };

  if (isLoading || !data) return <LoadingIndicator />;

  return (
    <>
      <Box
        colorType={colorType}
        title={<FormattedMessage {...messages.title} />}
        description={
          <>
            {primaryBrandName}
            <Br />
            CLG {certilogoCode}
          </>
        }
        marginBottom="m"
      />
      {status === 'active' ? (
        <Button
          colorType={colorType}
          marginBottom="m"
          onClick={handleDownload}
          loading={isDownloadSoaLoading}
        >
          <FormattedMessage {...messages.buttonDownload} />
        </Button>
      ) : (
        <Text colorType={colorType} marginBottom="l">
          <FormattedMessage {...messages.pending} />
        </Text>
      )}
      <MarketplaceListForm
        soaId={data.id}
        certilogoCode={certilogoCode}
        onSubmit={handleSubmit}
        colorType={colorType}
      />
      {status === 'active' && (
        <>
          <Button
            buttonType="link"
            arrow={false}
            colorType={colorType}
            onClick={() => setIsOpenSoldModal(true)}
          >
            <FormattedMessage {...messages.buttonSell} />
          </Button>
          <ModalAssistant
            colorType="accent"
            themeField="modalAssistant.color"
            isOpen={isOpenSoldModal}
            onClose={() => setIsOpenSoldModal(false)}
            text={
              <Div>
                <Text colorType="accent" size="m" weight="extrabold">
                  <FormattedMessage {...messages.soldModal.title} />
                </Text>
                <Text colorType="accent">
                  <FormattedMessage {...messages.soldModal.description} />
                </Text>
              </Div>
            }
            footer={[
              <Button colorType="accent" onClick={handleSell} loading={isLoading} noMargin>
                <FormattedMessage {...messages.soldModal.continue} />
              </Button>,
              <Button
                buttonType="link"
                arrow={false}
                colorType="accent"
                onClick={() => setIsOpenSoldModal(false)}
                noMargin
              >
                <FormattedMessage {...messages.soldModal.cancel} />
              </Button>,
            ]}
          />
        </>
      )}
    </>
  );
}

Manage.propTypes = {
  colorType: PropTypes.string,
};

export default Manage;
