
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import messages from './messages';

export default function MetaHeader() {
  const intl = useIntl();

  return (
    <Helmet>
      <title>{intl.formatMessage(messages.meta.title)}</title>
      <meta name="description" content={intl.formatMessage(messages.meta.description)} />
    </Helmet>
  );
}