import { createSelector } from 'reselect';
import get from 'lodash/get';

const selectAuthenticationDomain = () => (state) => state.authentication;

export const selectAuthenticationLoading = createSelector(selectAuthenticationDomain(), (state) =>
  get(state, 'loading'),
);

export const selectAuthenticationData = createSelector(selectAuthenticationDomain(), (state) =>
  get(state, 'data.authentication'),
);

export const selectAuthenticationConf = createSelector(selectAuthenticationDomain(), (state) =>
  get(state, 'data.conf'),
);

export const selectAuthenticationPlacesLoading = createSelector(
  selectAuthenticationDomain(),
  (state) => get(state, 'placesLoading'),
);

export const selectAuthenticationPlaces = createSelector(selectAuthenticationDomain(), (state) =>
  get(state, 'places'),
);

export const selectAuthenticationStoresLoading = createSelector(
  selectAuthenticationDomain(),
  (state) => get(state, 'storesLoading'),
);

export const selectAuthenticationStores = createSelector(selectAuthenticationDomain(), (state) =>
  get(state, 'stores'),
);

export const selectAuthenticationMapStores = createSelector(selectAuthenticationDomain(), (state) =>
  get(state, 'mapStores'),
);

export const selectAuthenticationGeolocationStores = createSelector(
  selectAuthenticationDomain(),
  (state) => get(state, 'geolocationStores'),
);

export const selectAuthenticationMapLoading = createSelector(
  selectAuthenticationDomain(),
  (state) => get(state, 'mapLoading'),
);

export const selectAuthenticationSelectedPlace = createSelector(
  selectAuthenticationDomain(),
  (state) => get(state, 'selectedPlace'),
);

export const selectAuthenticationAddStoreLoading = createSelector(
  selectAuthenticationDomain(),
  (state) => get(state, 'addStoreLoading'),
);

export const selectAuthenticationImageUploadLoading = createSelector(
  selectAuthenticationDomain(),
  (state) => get(state, 'imageUpload.loading'),
);

export const selectAuthenticationImageUploadData = createSelector(
  selectAuthenticationDomain(),
  (state) => get(state, 'imageUpload.data'),
);

export const selectAuthenticationImageUploadCount = createSelector(
  selectAuthenticationDomain(),
  (state) => get(state, 'imageUpload.data').length,
);

export const selectAuthenticationResponseConfLoading = createSelector(
  selectAuthenticationDomain(),
  (state) => get(state, 'authenticationResponseConf.loading'),
);

export const selectAuthenticationResponseConfData = createSelector(
  selectAuthenticationDomain(),
  (state) => get(state, 'authenticationResponseConf.data'),
);

export const selectConfirmAuthenticationLoading = createSelector(
  selectAuthenticationDomain(),
  (state) => get(state, 'confirmAuthenticationLoading'),
);
