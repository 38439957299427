import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { signUpLoginResponse } from '../actions';

export default function* setMailgun({ payload: { email } }) {
  try {
    yield put(
      signUpLoginResponse({
        setData: true,
        user: { email },
      }),
    );
    yield put(push(`/auth/create-password`));
  } catch ({ response = {}, packet, ...e }) {
    // if (response.status === 404) {
    //   console.log('Error, email doesn’t exist >>>>>>> ');
    // }
    if (response.status === 406) {
      // eslint-disable-next-line no-console
      console.log('Error, mailgun >>>>>>> ');
    }
  }
}
