import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { confirmAuthenticationResponse } from '../actions';

export default function* confirmAuthentication({ payload: { authenticationId, email } }) {
  const url = 'authentication/v3/confirm';

  try {
    yield call(fetchWrapper, {
      method: 'POST',
      url,
      params: {
        authenticationId,
        email,
      },
    });

    yield put(confirmAuthenticationResponse());
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  } finally {
    yield put(confirmAuthenticationResponse());
  }
}
