import { call, put } from 'redux-saga/effects';
import packageJson from 'containers/../../package.json';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { getBrandDomainName, channel, isBrandedDomain } from 'utils/utils';
import { push, replace } from 'connected-react-router';
import { flowRequest } from 'containers/Authentication/actions';

import { createResponse } from '../actions';

export default function* create({ payload: { authenticationId, redirect } }) {
  const url = 'soa/v1/create';

  try {
    const response = yield call(fetchWrapper, {
      method: 'POST',
      url,
      params: {
        authenticationId,
        channel,
        applicationVersion: packageJson.version,
        domain: isBrandedDomain() ? getBrandDomainName() : null,
      },
    });

    const authentication = response.authentication;
    const isSoaInsideAuthenticationFlow = authentication && authentication.status === 'soa';

    if (isSoaInsideAuthenticationFlow && response.page === 'completed') {
      yield put(push(`/authentication/${authenticationId}/soa/completed-seller`));
    }
    if (redirect) {
      if (response.status === 'sold') {
        yield put(push(`/authentication/${authenticationId}/soa/sold`));
      } else {
        yield put(replace(`/authentication/${authenticationId}/soa/${response.page}`));
      }
    }
    yield put(createResponse(response));
  } catch ({ response }) {
    if (response.status === 400) {
      yield put(flowRequest({ action: 'next', authenticationId }));
    }
  }
}
