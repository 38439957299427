import { takeLatest, all } from 'redux-saga/effects';
import { warrantyResponse } from 'containers/Warranty/actions';
import { authenticationResponse } from 'containers/Authentication/actions';
import {
  createResponse as fakeReportCreateResponse,
  updateResponse as fakeReportUpdateResponse,
} from 'containers/FakeReport/actions';
import { resellWithEbayResponse } from 'containers/ResellWithEbay/actions';
import { claimOwnershipResponse } from 'containers/ClaimOwnership/actions';
import {
  initApp,
  confRequest,
  resetConf,
  defaultConfRequest,
  logCta,
  setConf,
  clgFeatureConfRequest,
} from '../actions';
import initAppSaga from './initApp';
import defaultConfRequestSaga from './defaultConfRequest';
import confRequestSaga from './confRequest';
import confResetSaga from './confReset';
import logCtaSaga from './logCta';
import setConfSaga from './setConf';
import authenticatorConfSetter from './authenticatorConfSetter';
import clgFeatureConfRequestSaga from './clgFeatureConfRequest';

export default function* rootSaga() {
  yield all([takeLatest(initApp, initAppSaga)]);
  yield all([takeLatest(defaultConfRequest, defaultConfRequestSaga)]);
  yield all([takeLatest(confRequest, confRequestSaga)]);
  yield all([takeLatest(clgFeatureConfRequest, clgFeatureConfRequestSaga)]);
  yield all([takeLatest(resetConf, confResetSaga)]);
  yield all([
    takeLatest(
      [
        authenticationResponse,
        warrantyResponse,
        fakeReportCreateResponse,
        fakeReportUpdateResponse,
        resellWithEbayResponse,
        claimOwnershipResponse,
      ],
      authenticatorConfSetter,
    ),
  ]);
  yield all([takeLatest(setConf, setConfSaga)]);
  yield all([takeLatest(logCta, logCtaSaga)]);
}
