import history from 'utils/history';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';

const actions = {
  onboarding_complete: ({ completeAction }) => {
    if (isFunction(completeAction)) {
      completeAction();
    } else if (isString(completeAction)) {
      window.location = completeAction;
    }
  },
  start_authentication: ({ propsBag }) => {
    history.push(`/authentication`, { sessionId: propsBag?.id });
  },
  close_modal: ({ propsBag: { showModal } }) => {
    if (showModal) {
      showModal(false);
    }
  },
};

export const makeAction = (action, props) => {
  const actionFn = actions[action];
  if (actionFn) {
    actionFn(props);
  }
};

export default {
  makeAction,
};
