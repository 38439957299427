import { authenticationResponse } from 'containers/Authentication/actions';
import { getConnectResponse } from 'containers/Connect/actions';
import featureLoadEvent from './definitions/featureLoadEvent';

const featureLoadEvents = {
  [getConnectResponse]: featureLoadEvent(() => ({
    eventName: 'connect-response',
  })),
  [authenticationResponse]: featureLoadEvent(() => ({
    eventName: 'authentication-response',
  })),
};

export default featureLoadEvents;
