import { push } from 'connected-react-router';
import { put, select } from 'redux-saga/effects';

import { selectRedirectState, selectRedirectUrl } from '../selectors';
import { setRedirect, setIsAuthenticated, setProfileRequest, getProfileRequest } from '../actions';

export default function* loginResponse({ payload: { data: response, userData, redirect } }) {
  try {
    const redirectUrl = yield select(selectRedirectUrl);
    const redirectState = yield select(selectRedirectState);
    const { username, roles, token, qaBrands, isConfirmed } = response;
    yield put(setIsAuthenticated({ user: username, roles, token, qaBrands, isConfirmed }));

    if (userData) {
      yield put(setProfileRequest({ ...userData, signup: true }));
    }

    if (redirect) {
      if (isConfirmed) {
        yield put(push(`${redirectUrl || '/'}`, redirectState));
        yield put(setRedirect(null));
        yield put(getProfileRequest());
      } else {
        yield put(push(`/auth/confirm-email`));
      }
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
