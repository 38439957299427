import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { getBrandDomainName } from 'utils/utils';
import { setConf } from '../actions';

export default function* confRequest() {
  const url = `cms-fury/authenticator-confs`;
  const domain = getBrandDomainName();

  try {
    const response = yield call(fetchWrapper, {
      url,
      authenticated: false,
      withQuery: true,
      params: {
        domain,
        type: 'default',
      },
    });

    yield put(setConf(response.shift()));
  } catch (err) {
    yield put(setConf(null));
    // eslint-disable-next-line
    console.log('error', err);
  }
}
