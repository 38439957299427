import { createActions } from 'redux-actions';

const collection = 'auth';

export const {
  codeLoginRequest,
  codeLoginResponse,
  codeLoginCheckRequest,
  codeLoginCheckResponse,
  signUpLoginRequest,
  signUpLoginResponse,
  loginRequest,
  loginResponse,
  resetLogin,
  forgotPasswordRequest,
  forgotPasswordResponse,
  tokenVerifyRequest,
  tokenVerifyResponse,
  passwordUpdateRequest,
  getProfileRequest,
  setProfileRequest,
  setProfileEbayRequest,
  profileResponse,
  getPrivacyPolicySignupConfigRequest,
  getPrivacyPolicySignupConfigResponse,
  getOptinRequest,
  setOptinRequest,
  optinResponse,
  setRedirect,
  setRedirectError,
  setAuthErrors,
  setIsAuthenticated,
  logout,
  deleteUser,
  setMailgun,
  confirmEmailRequest,
  confirmEmailResponse,
  changeEmailRequest,
  changeEmailResponse,
  setBrand,
  setEbayOauthFeature,
} = createActions(
  {
    SET_BRAND: ({ primaryBrand, secondaryBrand }) => ({
      primaryBrand,
      secondaryBrand,
    }),
    CODE_LOGIN_REQUEST: ({ email }) => ({
      email,
    }),
    CODE_LOGIN_RESPONSE: ({ email, error, timeToWait, data }) => ({
      email,
      error,
      timeToWait,
      data,
    }),
    CODE_LOGIN_CHECK_REQUEST: ({ email, pin, token, redirect }) => ({
      email,
      pin,
      token,
      redirect,
    }),
    CODE_LOGIN_CHECK_RESPONSE: ({ error, data }) => ({ error, data }),
    SIGN_UP_LOGIN_REQUEST: ({ email, redirect }) => ({
      email,
      redirect,
    }),
    SIGN_UP_LOGIN_RESPONSE: ({ setPasword, setData, user, mailgun }) => ({
      setPasword,
      setData,
      user,
      mailgun,
    }),
    LOGIN_REQUEST: ({
      email,
      password,
      login,
      setData,
      network,
      token,
      code,
      version,
      userData,
      redirect,
      firstName,
      lastName,
      feature,
    }) => ({
      email,
      password,
      login,
      setData,
      network,
      token,
      code,
      version,
      userData,
      redirect,
      firstName,
      lastName,
      feature,
    }),
    LOGIN_RESPONSE: ({ data, network, userData, redirect }) => ({
      data,
      network,
      userData,
      redirect,
    }),
    RESET_LOGIN: () => null,
    FORGOT_PASSWORD_REQUEST: ({ email }) => ({
      email,
    }),
    FORGOT_PASSWORD_RESPONSE: () => null,
    TOKEN_VERIFY_REQUEST: ({ token }) => ({
      token,
    }),
    TOKEN_VERIFY_RESPONSE: ({ email }) => ({ email }),
    PASSWORD_UPDATE_REQUEST: ({ token, email, password, userData }) => ({
      token,
      email,
      password,
      userData,
    }),

    GET_PROFILE_REQUEST: () => null,
    SET_PROFILE_REQUEST: ({ email, firstName, lastName, birthdate, gender, redirect, signup }) => ({
      email,
      firstName,
      lastName,
      birthdate,
      gender,
      redirect,
      signup,
    }),
    SET_PROFILE_EBAY_REQUEST: ({ code, feature, redirect }) => ({
      code,
      feature,
      redirect,
    }),
    SET_EBAY_OAUTH_FEATURE: (feature) => ({ feature }),
    PROFILE_RESPONSE: ({ data, signup }) => ({
      data,
      signup,
    }),
    GET_PRIVACY_POLICY_SIGNUP_CONFIG_REQUEST: (data) => data,
    GET_PRIVACY_POLICY_SIGNUP_CONFIG_RESPONSE: (data) => ({ data }),
    GET_OPTIN_REQUEST: () => null,
    SET_OPTIN_REQUEST: ({ type, id, value }) => ({
      type,
      id,
      value,
    }),
    OPTIN_RESPONSE: ({ data }) => ({
      data,
    }),
    SET_REDIRECT: (url, state) => ({
      url,
      state,
    }),
    SET_REDIRECT_ERROR: (url, state) => ({
      url,
      state,
    }),
    SET_AUTH_ERRORS: (errors) => ({
      errors,
    }),
    SET_IS_AUTHENTICATED: ({ token, roles, user, qaBrands, isConfirmed }) => ({
      token,
      roles,
      user,
      qaBrands,
      isConfirmed,
    }),
    LOGOUT: () => null,
    DELETE_USER: (id) => ({ id }),
    SET_MAILGUN: (email) => ({ email }),
    CONFIRM_EMAIL_REQUEST: ({ pin, hash }) => ({
      pin,
      hash,
    }),
    CONFIRM_EMAIL_RESPONSE: ({ errors }) => ({ errors }),
    CHANGE_EMAIL_REQUEST: ({ email }) => ({
      email,
    }),
    CHANGE_EMAIL_RESPONSE: ({ errors }) => ({ errors }),
  },
  { prefix: collection },
);
