import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import { loginResponse, setProfileRequest } from '../actions';

export default function* passwordUpdate({ payload: { email, token, password, userData } }) {
  const url = `users/v2/password/update`;

  try {
    const data = yield call(fetchWrapper, {
      url,
      method: 'PUT',
      params: {
        email,
        token,
        password,
      },
    });

    yield put(loginResponse({ data }));
    if (!isEmpty(userData)) {
      yield put(setProfileRequest(userData));
    }

    yield put(push(`/`));
  } catch (response) {
    // eslint-disable-next-line no-console
    console.log('error', response);
  }
}
