import { call } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';

export default function* logCta({ payload }) {
  const url = 'logger/log';

  try {
    yield call(fetchWrapper, {
      method: 'POST',
      url,
      params: {
        type: 'cta',
        ...payload,
      },
      unhandle: true,
    });
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
