import { put, select } from 'redux-saga/effects';
import { isBrandedDomain } from 'utils/utils';
import { setConf, setConfBrandedDomain } from '../actions';
import { selectDefaultConf } from '../selectors';

export default function* authenticatorConfSetter({ payload: { conf } }) {
  try {
    if (conf) {
      if (isBrandedDomain()) {
        const defaultConf = yield select(selectDefaultConf);
        if (conf.primaryBrand === defaultConf.primaryBrand) {
          yield put(setConf(conf));
        } else {
          yield put(setConfBrandedDomain(conf));
        }
      } else {
        yield put(setConf(conf));
      }
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
