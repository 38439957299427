import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { resellWithEbayResponse } from '../actions';

export default function* create({ payload: { sessionId } }) {
  // const url = 'resell-with-ebay/v1/create';
  const url = 'resell-with-ebay/v1/create';

  try {
    const response = yield call(fetchWrapper, {
      method: 'POST',
      url,
      params: {
        sessionId,
      },
    });

    yield put(push(`/resell-with-ebay/${response?.id}/${response?.status}`));
    yield put(resellWithEbayResponse(response));
  } catch (err) {
    console.log(err);
  }
}
