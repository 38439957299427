/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { logCta, setBack } from 'containers/App/actions';
import {
  flowRequest,
  imageDeleteRequest,
  imageUploadRequest,
} from 'containers/ClaimOwnership/actions';
import Switch from 'components/Switch';
import Route from 'components/Route';
import { useRouteMatch, useLocation } from 'react-router-dom';
import withColorType from 'components/HOC/withColorType';
import {
  selectClaimOwnershipImageUploadLoading,
  selectClaimOwnershipImages,
  selectClaimOwnershipImagesCount,
} from 'containers/ClaimOwnership/selectors';
import { push } from 'connected-react-router';
import useColorType from 'components/hooks/useColorType';
import { removeEmptyObjects } from 'utils/utils';
import Camera from 'components/Camera';
import defaultMessages from './defaultMessages';
import Intro from './Intro';
import defaultProps from './props';

function ImageUpload({ data }) {
  const colorType = useColorType('claimOwnership');
  const { status, id, sessionId } = data;
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const isLoading = useSelector(selectClaimOwnershipImageUploadLoading);
  const uploadedImages = useSelector(selectClaimOwnershipImages);
  const uploadedImagesCount = useSelector(selectClaimOwnershipImagesCount);
  const isSorryModalOpen = get(useLocation(), 'state.tryAgain');
  const props = useMemo(() => _.merge({}, defaultProps, removeEmptyObjects(data?.props)), [data]);
  const config = props?.config?.authenticator_image_upload || {};
  const images = props?.images;
  const requestedImages = props?.images?.length || 1;
  const { introImages, ...configMessages } = config;

  const messages = useMemo(
    () => _.merge({}, defaultMessages, removeEmptyObjects(configMessages)),
    [configMessages],
  );

  const log = (name, params) => {
    dispatch(
      logCta({
        type: 'event',
        event: { type: 'rwe.imageUpload', name },
        action: `rwe.imageUpload.${name}`,
        sessionId: data?.sessionId,
        params: {
          preprocessing: data?.props?.config?.camera?.preprocessing,
          primaryBrand: data?.primaryBrand,
          tmBrand: data?.tmBrand,
          media: data?.props?.config?.media,
          useCase: data?.props?.config?.useCase,
          configName: data?.props?.config?.name,
          flow: data?.flow,
          ...params,
        },
      }),
    );
  };

  useEffect(() => {
    log('page');
    dispatch(
      setBack({
        back: flowRequest({
          id,
          action: 'prev',
          status,
        }),
      }),
    );
  }, []);

  const handleOnNext = () => {
    dispatch(
      flowRequest({
        action: 'next',
        status,
        id,
      }),
    );
  };

  const handleOnSkip = () => {
    if (props?.images[uploadedImagesCount].required) {
      dispatch(push(`/connect/${sessionId}`));
    } else {
      handleOnNext();
    }
  };

  const handleOnBack = () => {
    dispatch(push(`/claim-ownership/${id}/image_upload`));
  };

  const handleSendImage = ({ file }) => {
    dispatch(
      imageUploadRequest({
        claimOwnershipId: id,
        type: props?.images[uploadedImagesCount]?.type,
        file,
      }),
    );
  };

  const handleDeleteImage = () => {
    if (uploadedImagesCount > 0) {
      dispatch(imageDeleteRequest(uploadedImages[uploadedImagesCount - 1].imageId));
    }
  };

  useEffect(() => {
    if (uploadedImagesCount === requestedImages) {
      handleOnNext();
    }
  }, [uploadedImages]);

  return (
    <Switch>
      <Route path={path} exact>
        <Intro
          colorType={colorType}
          onSkip={handleOnSkip}
          log={log}
          id={id}
          messages={messages}
          images={images}
          config={config}
        />
      </Route>
      <Route path={`${path}/camera`}>
        <Camera
          colorType={colorType}
          id={id}
          images={images}
          config={config}
          onSkip={handleOnSkip}
          onBack={handleOnBack}
          messages={messages.camera}
          log={log}
          isLoading={isLoading}
          uploadedImages={uploadedImages}
          uploadedImagesCount={uploadedImagesCount}
          isSorryModalOpen={isSorryModalOpen}
          onCloseSorryModal={() => {
            dispatch(push('?', { tryAgain: false }));
          }}
          onSendImage={handleSendImage}
          onDeleteImage={handleDeleteImage}
        />
      </Route>
    </Switch>
  );
}

ImageUpload.propTypes = {
  colorType: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.object,
};

export default withColorType(ImageUpload);
