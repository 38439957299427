const COLOR_TYPES = {
  dark: 'dark',
  light: 'light',
  accent: 'accent',
};

export default {
  auth: COLOR_TYPES.dark,
  authLogin: COLOR_TYPES.light,
  authLoginForm: COLOR_TYPES.dark,
  authentication: COLOR_TYPES.light,
  authenticationImageUploadCamera: COLOR_TYPES.light,
  authenticationCompleteImpossible: COLOR_TYPES.accent,
  authenticationShortResponse: COLOR_TYPES.accent,
  soa: COLOR_TYPES.dark,
  soaManage: COLOR_TYPES.dark,
  soaSold: COLOR_TYPES.accent,
  soaCompletedSeller: COLOR_TYPES.dark,
  fakeReport: COLOR_TYPES.light,
  code: COLOR_TYPES.light,
  codeReview: COLOR_TYPES.light,
  cookiePolicy: COLOR_TYPES.dark,
  privacy: COLOR_TYPES.dark,
  terms: COLOR_TYPES.dark,
  fakeReportDownload: COLOR_TYPES.light,
  genericError: COLOR_TYPES.dark,
  home: COLOR_TYPES.light,
  qrCode: COLOR_TYPES.light,
  warranty: COLOR_TYPES.light,
  warrantyDownload: COLOR_TYPES.light,
  connect: COLOR_TYPES.dark,
  resellWithEbay: COLOR_TYPES.dark,
  claimOwnership: COLOR_TYPES.dark,
  imageUploadCamera: COLOR_TYPES.light,
};
