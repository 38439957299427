/* eslint-disable import/prefer-default-export */
import { createActions } from 'redux-actions';

const collection = 'gtm/dynamicComponents';

export const { interaction } = createActions(
  {
    interaction: (data) => ({ ...data }),
  },
  { prefix: collection },
);
