/* eslint-disable import/no-import-module-exports */
/**
 * Create the store with dynamic reducers
 */

import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';
import { gtmMiddleware } from 'utils/reduxBeaconGtm';
import createReducer from './reducers';

export default function configureStore(initialState = {}, history) {
  let composeEnhancers = compose;
  const reduxSagaMonitorOptions = {};

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});

    // NOTE: Uncomment the code below to restore support for Redux Saga
    // Dev Tools once it supports redux-saga version 1.x.x
    // if (window.__SAGA_MONITOR_EXTENSION__)
    //   reduxSagaMonitorOptions = {
    //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
    //   };
    /* eslint-enable */
  }

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history), gtmMiddleware];

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Transform the actions to remove sensitive information
    actionTransformer: (action) => {
      if (action.type === 'auth/LOGIN_REQUEST') {
        return {
          ...action,
          payload: {
            ...action.payload,
            password: null,
          },
        };
      }
      return action;
    },
    stateTransformer: (state) => {
      // Transform the state to remove sensitive information
      const transformedState = {
        ...state,
        auth: {
          ...state.auth,
          jwt: null,
        },
      };
      return transformedState;
    },
  });

  const enhancers = [applyMiddleware(...middlewares), sentryReduxEnhancer];

  const store = createStore(createReducer(), initialState, composeEnhancers(...enhancers));

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  return store;
}
