import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeColorType } from 'containers/App/actions';

import PropTypes from 'prop-types';

function withColorType(Component) {
  function WrappedComponent({ colorType, ...rest }) {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(changeColorType({ colorType }));
    }, [colorType]);

    return <Component colorType={colorType} {...rest} />;
  }

  WrappedComponent.propTypes = {
    colorType: PropTypes.string,
  };

  return WrappedComponent;
}

export default withColorType;
