import React, { useState, useEffect } from 'react';
import { ButtonLabel } from '@ebay-certilogo/design-system-web';
import { Plus } from '@ebay-certilogo/design-system-web/dist/components/icons';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Lists from 'components/Lists';
import messages from './messages';
import AddMarketplaceModal from './AddMarketplaceModal';
import ImportantModal from './ImportantModal';
import MarktplaceToggleForm from './MarktplaceToggleForm';

import { getMarketplaceSoaRequest, marketplaceRequest } from '../actions';
import { selectMarketplace } from '../selectors';

function MarketplaceListForm({ colorType, onSubmit, soaId, certilogoCode }) {
  const [marketplaceIsOpen, setMarketplaceIsOpen] = useState(null);
  const [importantModalIsOpen, setImportantModalIsOpen] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(marketplaceRequest());
    dispatch(getMarketplaceSoaRequest(soaId));
  }, []);
  const marketplace = useSelector(selectMarketplace);

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <>
      <Lists
        colorType={colorType}
        clickable
        onClick={() => null}
        // eslint-disable-next-line react/no-unstable-nested-components
        left={({ alias, aliasId, marketplaceId, enabled }) => (
          <MarktplaceToggleForm
            soaId={soaId}
            alias={alias}
            aliasId={aliasId}
            marketplaceId={marketplaceId}
            value={enabled}
            colorType={colorType}
          />
        )}
        accessoryRow={['name', 'alias']}
        rowProps={{
          height: 50,
        }}
        type="twoRow"
        bordered
        data={marketplace}
        marginBottom="m"
      />
      <ButtonLabel
        colorType={colorType}
        button={<Plus width={18} height={18} colorType="light" />}
        label={<FormattedMessage {...messages.addMarketplace} />}
        onClick={() => setMarketplaceIsOpen('add-marketplace')}
        margin={{ size: 'm', position: 'bottom' }}
        alignItems="center"
        accent
      />
      {importantModalIsOpen && (
        <ImportantModal
          colorType="light"
          isOpen={importantModalIsOpen}
          setIsOpen={setImportantModalIsOpen}
          handleSubmit={handleSubmit}
        />
      )}

      {marketplaceIsOpen && (
        <AddMarketplaceModal
          colorType={colorType}
          certilogoCode={certilogoCode}
          data={!isString(marketplaceIsOpen) && marketplaceIsOpen}
          setMarketplaceIsOpen={setMarketplaceIsOpen}
          marketplaceIsOpen={marketplaceIsOpen}
        />
      )}
    </>
  );
}

MarketplaceListForm.propTypes = {
  colorType: PropTypes.string,
  soaId: PropTypes.string,
  certilogoCode: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default MarketplaceListForm;
