import { createSelector } from 'reselect';
import get from 'lodash/get';

const selectResellWithEbayDomain = () => (state) => state.resellWithEbay;

export const selectResellWithEbayLoading = createSelector(selectResellWithEbayDomain(), (state) =>
  get(state, 'loading'),
);

export const selectResellWithEbayData = createSelector(selectResellWithEbayDomain(), (state) =>
  get(state, 'data'),
);

export const selectResellWithEbayImageUploadLoading = createSelector(
  selectResellWithEbayDomain(),
  (state) => get(state, 'imageUploadLoading'),
);

export const selectResellWithEbayImageDeleteLoading = createSelector(
  selectResellWithEbayDomain(),
  (state) => get(state, 'imageDeleteLoading'),
);

export const selectResellWithEbayImages = createSelector(selectResellWithEbayDomain(), (state) =>
  get(state, 'data.props.images', []),
);

export const selectResellWithEbayImagesCount = createSelector(
  selectResellWithEbayDomain(),
  (state) => get(state, 'data.props.images', []).length,
);

export const selectFinalize = createSelector(selectResellWithEbayDomain(), (state) =>
  get(state, 'finalize.data'),
);

export const selectFinalizeError = createSelector(selectResellWithEbayDomain(), (state) =>
  get(state, 'finalize.error'),
);

export const selectFinalizeLoading = createSelector(selectResellWithEbayDomain(), (state) =>
  get(state, 'finalize.loading'),
);
