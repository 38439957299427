
import PropTypes from 'prop-types';
import { List, Text } from '@ebay-certilogo/design-system-web';
import isFunction from 'lodash/isFunction';

function OneRow({
  colorType,
  text,
  left,
  right,
  onClick,
  bordered,
  rowPadding,
  item,
  descriptionProps,
}) {
  return (
    <List.Row
      colorType={colorType}
      onClick={onClick}
      clickable
      left={isFunction(left) ? left(item) : left}
      right={right}
      padding={(bordered && { size: 's', position: 'vertical' }) || rowPadding}
      noMargin={bordered}
      body={
        <Text
          colorType={colorType}
          weight="semibold"
          margin={{ size: 's', position: 'horizontal' }}
          {...descriptionProps}
        >
          {text}
        </Text>
      }
    />
  );
}

OneRow.propTypes = {
  colorType: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  left: PropTypes.node,
  right: PropTypes.node,
  onClick: PropTypes.func,
  bordered: PropTypes.bool,
  rowPadding: PropTypes.object,
  item: PropTypes.object,
  descriptionProps: PropTypes.object,
};

export default OneRow;
