import produce from 'immer';
import { handleActions } from 'redux-actions';
import { getConnectRequest, getConnectResponse, resetConnect } from './actions';

export const initialState = {
  loading: false,
  data: null,
};

const mutableReducer = handleActions(
  {
    [getConnectRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [getConnectResponse]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload;
    }),
    [resetConnect]: produce((draft) => {
      draft.loading = false;
      draft.data = null;
    }),
  },
  initialState,
);

export default mutableReducer;
