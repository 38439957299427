import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  createRequest,
  updateRequest,
  codeReviewResponse,
  imageUploadRequest,
  imageUploadResponse,
} from './actions';

export const initialState = {
  loading: false,
  data: null,
  uploadLoading: false,
  uploadImages: [],
};

const mutableReducer = handleActions(
  {
    [createRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [updateRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [codeReviewResponse]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload;
    }),
    [imageUploadRequest]: produce((draft) => {
      draft.uploadLoading = true;
    }),
    [imageUploadResponse]: produce((draft, { payload }) => {
      draft.uploadLoading = false;
      draft.uploadImages.push(payload);
    }),
  },
  initialState,
);

export default mutableReducer;
