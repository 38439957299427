import { openMenu } from 'containers/App/actions';
import {
  signUpLoginRequest,
  loginRequest,
  loginResponse,
  setProfileRequest,
  profileResponse,
  forgotPasswordRequest,
  tokenVerifyRequest,
  passwordUpdateRequest,
} from 'containers/Auth/actions';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { back, menuClick, closeMenu } from '../actions/generic';
import genericEvent from './definitions/genericEvent';

const pageViewEvents = {
  [signUpLoginRequest]: genericEvent(() => ({
    eventName: 'login_step1',
    method: 'email',
  })),
  [loginRequest]: genericEvent(({ payload: { network } }) => ({
    eventName: 'login_step1',
    method: network || 'email',
  })),
  [loginResponse]: genericEvent(({ payload: { network } }) => ({
    eventName: 'login_ok',
    method: network || 'email',
    logged: 'logged',
  })),
  [setProfileRequest]: genericEvent(({ payload: { signup } }) => ({
    eventName: 'signup_step2',
    skipEvent: !signup,
  })),
  [profileResponse]: genericEvent(({ payload: { signup } }) => ({
    eventName: 'signup_step3',
    skipEvent: !signup,
  })),
  [forgotPasswordRequest]: genericEvent(() => ({
    eventName: 'recover-pass_step1',
  })),
  [tokenVerifyRequest]: genericEvent(() => ({
    eventName: 'recover-pass_step2',
  })),
  [passwordUpdateRequest]: genericEvent(() => ({
    eventName: 'recover-pass_ok',
  })),
  [openMenu]: genericEvent((action, { global: { menuIsOpen } }) => ({
    eventName: 'menu_open',
    skipEvent: menuIsOpen,
  })),
  [closeMenu]: genericEvent(() => ({
    eventName: 'menu_close',
  })),
  [changeLocale]: genericEvent(
    ({ payload: { locale: localeTo } }, { language: { locale: localeFrom } }) => ({
      eventName: 'language_change',
      language_from: localeFrom,
      language_to: localeTo,
    }),
  ),
  [back]: genericEvent(() => ({
    eventName: 'back',
  })),
  [menuClick]: genericEvent(({ payload: { label } }) => ({
    eventName: 'menu_click',
    menu_label: label,
  })),
};

export default pageViewEvents;
