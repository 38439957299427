import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  createRequest,
  flowRequest,
  warrantyResponse,
  downloadRequest,
  downloadResponse,
} from './actions';

export const initialState = {
  loading: false,
  data: null,
  downloadLoading: false,
};

const mutableReducer = handleActions(
  {
    [createRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [warrantyResponse]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload;
    }),
    [flowRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [downloadRequest]: produce((draft) => {
      draft.downloadLoading = true;
    }),
    [downloadResponse]: produce((draft) => {
      draft.downloadLoading = false;
    }),
  },
  initialState,
);

export default mutableReducer;
