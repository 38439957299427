import { createActions } from 'redux-actions';

const collection = 'connect';

export const { getConnectRequest, getConnectResponse, resetConnect } = createActions(
  {
    GET_CONNECT_REQUEST: ({ sessionId }) => ({ sessionId }),
    GET_CONNECT_RESPONSE: (data) => data,
    RESET_CONNECT: () => ({}),
  },
  { prefix: collection },
);
