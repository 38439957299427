import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { authenticationGeolocateResponse } from '../actions';

export default function* flow({ payload: { authenticationId, latitude, longitude } }) {
  const url = 'authentication/v3/geolocate';

  try {
    yield call(fetchWrapper, {
      method: 'POST',
      url,
      params: {
        authenticationId,
        latitude,
        longitude,
      },
    });

    yield put(authenticationGeolocateResponse());
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    yield put(authenticationGeolocateResponse());
  }
}
