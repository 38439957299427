import { Div } from '@ebay-certilogo/design-system-web';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled(Div)`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 20px);
`;
