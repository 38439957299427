import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'error_component.title',
    defaultMessage: 'Ooops',
  },
  text: {
    id: 'error_component.text',
    defaultMessage: 'An error has occurred',
  },
});
