import { takeLatest, all } from 'redux-saga/effects';
import {
  getAuthenticationRequest,
  createAuthenticationRequest,
  flowRequest,
  getPlacesRequest,
  getPlaceDetails,
  getStoresRequest,
  addStoreRequest,
  resetAuthentication,
  authenticationResponse,
  imageUploadRequest,
  imageDeleteRequest,
  imageListRequest,
  getAuthenticationResponseConfRequest,
  confirmAuthenticationRequest,
  getConfirmAuthenticationRequest,
  authenticationResultRequest,
  authenticationGeolocateRequest,
  getAuthenticationSilentRequest,
} from '../actions';
import getAuthentication from './getAuthentication';
import createAuthentication from './createAuthentication';
import resetAuthenticationSaga from './resetAuthentication';
import authenticationResponseSaga from './authenticationResponse';
import flow from './flow';
import getPlaces from './getPlaces';
import getStores from './getStores';
import getPlaceDetailsSaga from './getPlaceDetails';
import createStore from './createStore';
import { imageUpload, imageDelete, imageList } from './image';
import getAuthenticationResponseConf from './getAutenticationResponseConf';
import getConfirmAuthentication from './getConfirmAuthentication';
import confirmAuthentication from './confirmAuthentication';
import authenticationResultRequestSaga from './authenticationResultRequest';
import geolocate from './geolocate';

export default function* rootSaga() {
  yield all([takeLatest(getAuthenticationRequest, getAuthentication)]);
  yield all([takeLatest(getAuthenticationSilentRequest, getAuthentication)]);
  yield all([takeLatest(createAuthenticationRequest, createAuthentication)]);
  yield all([takeLatest(authenticationResponse, authenticationResponseSaga)]);
  yield all([takeLatest(resetAuthentication, resetAuthenticationSaga)]);
  yield all([takeLatest(flowRequest, flow)]);
  yield all([takeLatest(getPlacesRequest, getPlaces)]);
  yield all([takeLatest(getPlaceDetails, getPlaceDetailsSaga)]);
  yield all([takeLatest(getStoresRequest, getStores)]);
  yield all([takeLatest(addStoreRequest, createStore)]);
  yield all([takeLatest(imageUploadRequest, imageUpload)]);
  yield all([takeLatest(imageDeleteRequest, imageDelete)]);
  yield all([takeLatest(imageListRequest, imageList)]);
  yield all([takeLatest(getAuthenticationResponseConfRequest, getAuthenticationResponseConf)]);
  yield all([takeLatest(getConfirmAuthenticationRequest, getConfirmAuthentication)]);
  yield all([takeLatest(confirmAuthenticationRequest, confirmAuthentication)]);
  yield all([takeLatest(authenticationResultRequest, authenticationResultRequestSaga)]);
  yield all([takeLatest(authenticationGeolocateRequest, geolocate)]);
}
