import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPageType, setPageType } from 'containers/App/actions';
import { selectPageTypes } from 'containers/App/selectors';

function usePageType(name, forcePageType) {
  const dispatch = useDispatch();
  const pageTypes = useSelector(selectPageTypes);
  const pageType = useMemo(
    () => forcePageType || pageTypes[name],
    [name, forcePageType, pageTypes],
  );

  useEffect(() => {
    dispatch(setPageType(pageType));
    return () => dispatch(resetPageType());
  }, [pageType]);

  return pageType;
}

export default usePageType;
