import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { flowResponse } from '../actions';

export default function* flow({ payload: { data, authenticationId, action, id, status, page } }) {
  const url = 'soa/v1/flow/:id';

  try {
    const response = yield call(fetchWrapper, {
      method: 'PUT',
      url: url.replace(':id', id),
      params: {
        action,
        status,
        page,
        data: { ...data },
      },
    });

    const authentication = response.authentication;
    const isSoaInsideAuthenticationFlow = authentication && authentication.status === 'soa';

    if (isSoaInsideAuthenticationFlow && response.page === 'completed') {
      yield put(push(`/authentication/${authenticationId}/soa/completed-seller`));
    } else if (response.status === 'sold') {
      yield put(push(`/authentication/${authenticationId}/soa/sold`));
    } else {
      yield put(push(`/authentication/${authenticationId}/soa/${response.page}`));
    }
    yield put(flowResponse(response));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
