import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { authenticationResponse } from '../actions';

export default function* authenticationResultRequest({ payload: { hash } }) {
  const url = `authentication/v3/result/${hash}`;

  try {
    const response = yield call(fetchWrapper, {
      url,
    });

    yield put(authenticationResponse(response));
    if (response.authentication) {
      yield put(
        push(`/authentication/${response.authentication.id}/${response.authentication.status}`),
      );
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
    yield put(push(`/code`));
  }
}
