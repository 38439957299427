import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  changeColorType,
  setBack,
  unsetBack,
  confRequest,
  setConf,
  resetConf,
  setMenuVisibility,
  unsetPrompt,
  setPrompt,
  openMenu,
  closeMenu,
  setMenu,
  initApp,
  initAppEnd,
  setPageType,
  resetPageType,
  setConfBrandedDomain,
  defaultConfRequest,
  setDefaultConf,
  setHeaderUrl,
  resetHeaderUrl,
  setLogo,
  clgFeatureConfRequest,
  setClgFeatureConf,
} from './actions';

export const initialState = {
  appLoading: true,
  back: null,
  defaultConf: {
    loading: false,
    data: null,
  },
  conf: {
    loading: false,
    data: null,
  },
  clgFeatureConf: {
    loading: false,
    data: null,
  },
  pageType: null,
  colorType: 'light',
  menuVisible: true,
  promptActive: false,
  menuIsOpen: false,
  backgroundColor: null,
  headerUrl: '/',
  logo: {
    light: null,
    dark: null,
  },
};

const mutableReducer = handleActions(
  {
    [initApp]: produce((draft) => {
      draft.appLoading = true;
    }),
    [initAppEnd]: produce((draft) => {
      draft.appLoading = false;
    }),
    [defaultConfRequest]: produce((draft) => {
      draft.defaultConf.loading = true;
    }),
    [setDefaultConf]: produce((draft, { payload: { conf } }) => {
      draft.defaultConf.data = conf;
    }),
    [confRequest]: produce((draft) => {
      draft.conf.loading = true;
    }),
    [setConf]: produce((draft, { payload: { conf } }) => {
      draft.conf.data = conf;
    }),
    [clgFeatureConfRequest]: produce((draft) => {
      draft.conf.loading = true;
    }),
    [setClgFeatureConf]: produce((draft, { payload: { conf } }) => {
      draft.clgFeatureConf.data = conf;
    }),
    [setConfBrandedDomain]: produce((draft, { payload: { conf } }) => {
      draft.conf.data.logoLight = conf.logoLight;
      draft.conf.data.logoDark = conf.logoDark;
    }),
    [resetConf]: produce(() => {}),
    [changeColorType]: produce((draft, { payload: { colorType } }) => {
      draft.colorType = colorType;
    }),
    [setBack]: produce((draft, { payload: { back } }) => {
      draft.back = back;
    }),
    [unsetBack]: produce((draft) => {
      draft.back = null;
    }),
    [setMenuVisibility]: produce((draft, { payload }) => {
      draft.menuVisible = payload;
    }),
    [unsetPrompt]: produce((draft, { payload }) => {
      draft.promptActive = payload;
    }),
    [setPrompt]: produce((draft, { payload }) => {
      draft.promptActive = payload;
    }),
    [openMenu]: produce((draft, { payload }) => {
      draft.menuIsOpen = payload;
    }),
    [closeMenu]: produce((draft, { payload }) => {
      draft.menuIsOpen = payload;
    }),
    [setMenu]: produce((draft, { payload }) => {
      draft.menuIsOpen = payload;
    }),
    [setPageType]: produce((draft, { payload }) => {
      draft.pageType = payload;
    }),
    [resetPageType]: produce((draft) => {
      draft.pageType = initialState.pageType;
    }),
    [setHeaderUrl]: produce((draft, { payload }) => {
      draft.headerUrl = payload;
    }),
    [resetHeaderUrl]: produce((draft) => {
      draft.headerUrl = initialState.headerUrl;
    }),
    [setLogo]: produce((draft, { payload: { logo } }) => {
      draft.conf.data.logoLight = logo.light;
      draft.conf.data.logoDark = logo.dark;
    }),
  },
  initialState,
);

export default mutableReducer;
