import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Route as RRDRoute } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { closeMenu } from 'containers/App/actions';
import { selectMenuIsOpen } from 'containers/App/selectors';
import { selectIsAuthenticated, selectIsConfirmed } from 'containers/Auth/selectors';
import { push } from 'connected-react-router';

const SentryRoute = Sentry.withSentryRouting(RRDRoute);

function Route({ children, isWhitelisted, ...rest }) {
  const pathname = get(rest, 'location.pathname');
  const dispatch = useDispatch();
  const sidebarOpen = useSelector(selectMenuIsOpen);
  const userIsAuthenticated = useSelector(selectIsAuthenticated);
  const userIsConfirmed = useSelector(selectIsConfirmed);

  useEffect(() => {
    if (userIsAuthenticated && userIsConfirmed === false) dispatch(push('/auth/confirm-email'));
  }, [userIsAuthenticated, userIsConfirmed]);

  useEffect(() => {
    if (sidebarOpen) dispatch(closeMenu());
  }, [pathname]);

  return <SentryRoute {...rest}>{children}</SentryRoute>;
}

Route.propTypes = {
  children: PropTypes.node,
  isWhitelisted: PropTypes.bool,
};

export default Route;
