import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'claim_ownership.complete.title1',
    defaultMessage: 'Evaluation in progress',
  },
  description: {
    id: 'claim_ownership.complete.text1',
    defaultMessage:
      'One of our experts is verifying your product. You will receive a response via email. This usually takes less than 24 hours.',
  },
  cta: {
    id: 'claim_ownership.complete.cta',
    defaultMessage: 'Discover the brand',
  },
});
