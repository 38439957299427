import { call, put, select } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { authenticationResponse } from '../actions';
import { selectAuthenticationData } from '../selectors';

export default function* flow({ payload: { data, authenticationId, action, status } }) {
  const url = 'authentication/v3/flow/:id';

  try {
    const authentication = yield select(selectAuthenticationData);

    if (authentication.isFirstStatus && authentication.service === 'connect' && action === 'prev') {
      yield put(authenticationResponse());
      yield put(push(`/connect/${authentication?.sessionId}`));
    } else {
      const locale = yield select(makeSelectLocale);

      const response = yield call(fetchWrapper, {
        method: 'PUT',
        url: url.replace(':id', authenticationId),
        params: {
          locale,
          action,
          status: status || authentication?.status,
          data: { ...data },
        },
      });

      yield put(
        authenticationResponse(
          response,
          `/authentication/${authenticationId}/${response.authentication.status}`,
        ),
      );
      yield put(push(`/authentication/${authenticationId}/${response.authentication.status}`));
    }
  } catch ({ response }) {
    if (response && [400, 404].includes(response.status)) yield put(push(`/code`));
    // yield put(authenticationResponse());
  }
}
