import { createSelector } from 'reselect';
import get from 'lodash/get';

const selectClaimOwnershipDomain = () => (state) => state.claimOwnership;

export const selectClaimOwnershipLoading = createSelector(selectClaimOwnershipDomain(), (state) =>
  get(state, 'loading'),
);

export const selectClaimOwnershipData = createSelector(selectClaimOwnershipDomain(), (state) =>
  get(state, 'data'),
);

export const selectClaimOwnershipImageUploadLoading = createSelector(
  selectClaimOwnershipDomain(),
  (state) => get(state, 'imageUploadLoading'),
);

export const selectClaimOwnershipImageDeleteLoading = createSelector(
  selectClaimOwnershipDomain(),
  (state) => get(state, 'imageDeleteLoading'),
);

export const selectClaimOwnershipImages = createSelector(selectClaimOwnershipDomain(), (state) =>
  get(state, 'data.props.images', []),
);

export const selectClaimOwnershipImagesCount = createSelector(
  selectClaimOwnershipDomain(),
  (state) => get(state, 'data.props.images', []).length,
);

export const selectFinalize = createSelector(selectClaimOwnershipDomain(), (state) =>
  get(state, 'finalize.data'),
);

export const selectFinalizeError = createSelector(selectClaimOwnershipDomain(), (state) =>
  get(state, 'finalize.error'),
);

export const selectFinalizeLoading = createSelector(selectClaimOwnershipDomain(), (state) =>
  get(state, 'finalize.loading'),
);
