import { createActions } from 'redux-actions';

const collection = 'APP';

export const {
  initApp,
  initAppEnd,
  defaultConfRequest,
  setDefaultConf,
  confRequest,
  clgFeatureConfRequest,
  setClgFeatureConf,
  authenticatorConfSetter,
  setConf,
  setConfBrandedDomain,
  resetConf,
  changeColorType,
  setBack,
  unsetBack,
  setMenuVisibility,
  unsetPrompt,
  setPrompt,
  openMenu,
  closeMenu,
  setMenu,
  setPageType,
  resetPageType,
  logCta,
  setHeaderUrl,
  resetHeaderUrl,
  setLogo,
} = createActions(
  {
    INIT_APP: () => ({}),
    INIT_APP_END: () => ({}),
    DEFAULT_CONF_REQUEST: (type) => ({ type }),
    SET_DEFAULT_CONF: (conf) => ({ conf }),
    CONF_REQUEST: (type) => ({ type }),
    SET_CONF: (conf) => ({ conf }),
    CLG_FEATURE_CONF_REQUEST: (type) => ({ type }),
    SET_CLG_FEATURE_CONF: (conf) => ({ conf }),
    AUTHENTICATOR_CONF_SETTER: (data) => ({ data }),
    SET_CONF_BRANDED_DOMAIN: (conf) => ({ conf }),
    RESET_CONF: () => ({}),
    CHANGE_COLOR_TYPE: ({ colorType }) => ({
      colorType,
    }),
    SET_BACK: ({ back }) => ({ back }),
    UNSET_BACK: null,
    SET_MENU_VISIBILITY: (visible) => visible,
    SET_PROMPT: () => true,
    UNSET_PROMPT: () => false,
    OPEN_MENU: () => true,
    CLOSE_MENU: () => false,
    SET_MENU: (value) => value,
    LOG_CTA: (data) => ({ ...data }),
    SET_PAGE_TYPE: (value) => value,
    RESET_PAGE_TYPE: () => null,
    SET_HEADER_URL: (value) => value,
    RESET_HEADER_URL: () => null,
    SET_LOGO: (logo) => ({ logo }),
  },
  { prefix: collection },
);
