import { put } from 'redux-saga/effects';
import { resetConf } from 'containers/App/actions';
import { resetProxy } from 'containers/Proxy/actions';

export default function* resetAuthentication() {
  try {
    yield put(resetConf());
    yield put(resetProxy());
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
