import { call, put } from 'redux-saga/effects';
import { getAutocompleteSuggestions } from 'utils/Api/geolocation';
import { getPlacesResponse } from '../actions';

export default function* getPlaces({ payload: { value } }) {
  try {
    const places = yield call(getAutocompleteSuggestions, value);
    yield put(getPlacesResponse({ places }));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
