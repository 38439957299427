import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { signUpLoginRequest, tokenVerifyResponse } from '../actions';

export default function* tokenVerify({ payload: { token } }) {
  const url = `users/v2/password/email`;

  try {
    const { email } = yield call(fetchWrapper, {
      url,
      withQuery: true,
      params: {
        token,
      },
    });
    yield put(tokenVerifyResponse({ email }));
    yield put(signUpLoginRequest({ email }));
  } catch ({ response }) {
    // eslint-disable-next-line no-console
    console.log('error', response);
  }
}
