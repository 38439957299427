import { createBrowserHistory } from 'history';
import config from 'config';
import { initGtm } from 'utils/gtm';

const history = createBrowserHistory({
  basename: config.basename,
});

initGtm();

export default history;
