import { Image as ClgImage, Div } from '@ebay-certilogo/design-system-web';
import styled from 'styled-components';

export const SliderWrapper = styled(Div)`
  height: 240px;
`;

export const Image = styled(ClgImage)`
  margin-left: auto;
  margin-right: auto;
  height: 230px;
  width: auto;
  object-fit: contain;
`;
