import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { getMarketplaceSoaRequest } from '../actions';

export default function* removeMarketplaceSoa({ payload: { soaId, aliasId } }) {
  const url = `soa/v1/${soaId}/alias/${aliasId}`;

  try {
    yield call(fetchWrapper, {
      url,
      method: 'DELETE',
    });

    yield put(getMarketplaceSoaRequest(soaId));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
