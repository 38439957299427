import { createActions } from 'redux-actions';

const collection = 'codeReview';

export const {
  createRequest,
  updateRequest,
  codeReviewResponse,
  imageUploadRequest,
  imageUploadResponse,
} = createActions(
  {
    CREATE_REQUEST: ({ token, authenticationId }) => ({ token, authenticationId }),
    UPDATE_REQUEST: ({ token }) => ({ token }),
    CODE_REVIEW_RESPONSE: (data) => ({ ...data }),
    IMAGE_UPLOAD_REQUEST: ({ token, file }) => ({
      token,
      file,
    }),
    IMAGE_UPLOAD_RESPONSE: (data) => ({ ...data }),
  },
  { prefix: collection },
);
