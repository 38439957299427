import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Div } from '@ebay-certilogo/design-system-web';
import { useDispatch } from 'react-redux';
import { useClickAway } from 'ahooks';

import { changeLocale } from 'containers/LanguageProvider/actions';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import useLocale from 'components/hooks/useLocale';
import messages from './messages';
import selectOptions from './languages';

const ControlStyle = styled(Div)`
  cursor: pointer;
`;

const Wrapper = styled(Div)`
  [class$='-menu'] {
    width: auto;
    margin-top: '3px';
  }
  div {
    max-height: none;
  }
`;

function Control({ selectProps: { setIsOpen, menuIsOpen } }) {
  const handleOnClick = () => setIsOpen(!menuIsOpen);
  const ref = useClickAway(() => setIsOpen(false));

  return (
    <ControlStyle ref={ref} onClick={handleOnClick}>
      <FormattedMessage {...messages.language} />
    </ControlStyle>
  );
}

Control.propTypes = {
  selectProps: PropTypes.object,
};

function LanguageSelector({ colorType, onChange }) {
  const dispatch = useDispatch();
  const locale = useLocale();
  const [isOpen, setIsOpen] = useState(false);
  const handleOnChange = ({ value }) => {
    dispatch(changeLocale(value));
    if (onChange) onChange();
  };
  return (
    <Wrapper>
      <Select
        colorType={colorType}
        options={selectOptions}
        onChange={handleOnChange}
        components={{ Control }}
        menuIsOpen={isOpen}
        setIsOpen={setIsOpen}
        defaultValue={{ value: locale }}
        maxHeight="none"
      />
    </Wrapper>
  );
}

LanguageSelector.propTypes = {
  colorType: PropTypes.string,
  onChange: PropTypes.func,
};

export default LanguageSelector;
