import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Div } from '@ebay-certilogo/design-system-web';
import { secondaryColor } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import { breakpoints } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import { isWebGLAvailable } from 'utils/webgl';
import Particles from './particles';

const ParticlesWrapper = styled(Div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${secondaryColor};

  canvas {
    height: 100% !important;
    width: 100% !important;
  }
`;

function Animation() {
  const ref = useRef(null);
  let particles = null;

  if (!isWebGLAvailable()) return null;

  const setResponsiveProps = () => {
    if (window.innerWidth > breakpoints.tablet) {
      // $('.cta-auth, .cta-web').off('hover');
      particles.setCurveTo('base');
    } else {
      // $('.cta-auth, .cta-web').on('hover');
      particles.setCurveTo('mobile');
    }
  };

  useEffect(() => {
    particles = new Particles({
      parent: ref.current,
    });

    particles.start();
    setResponsiveProps();

    window.addEventListener('resize', () => {
      setResponsiveProps();
    });
    return () => particles.stop();
  }, []);

  return <ParticlesWrapper ref={ref} />;
}

export default Animation;
