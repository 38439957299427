import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { optinResponse } from '../actions';

export default function* getOptin() {
  const url = `privacy-policy-service/preference/filter`;

  try {
    const response = yield call(fetchWrapper, {
      url,
    });

    yield put(optinResponse({ data: response }));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
