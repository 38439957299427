import { composeTheme } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/utils';
import { selectDefaultTheme } from 'containers/App/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useDefaultTheme = (name) => {
  const defaultTheme = useSelector(selectDefaultTheme);
  const theme = useMemo(() => composeTheme(name, defaultTheme), [defaultTheme]);
  return theme;
};

export default useDefaultTheme;
