import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { updateResponse } from '../actions';

export default function* update({ payload: { params, action, id, status, goToHome = false } }) {
  const url = `fakeReport/v3/flow/${id}`;
  try {
    const data = yield call(fetchWrapper, {
      method: 'PUT',
      url,
      params: {
        status,
        action,
        data: { ...params },
      },
    });

    yield put(updateResponse(data));
    if (goToHome) yield put(push('/'));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
