/*
 *
 * LanguageProvider reducer
 *
 */

import produce from 'immer';
import { handleActions } from 'redux-actions';
import { getLocale } from '../../getLocale';
import { changeLocale } from './actions';

export const initialState = {
  locale: getLocale(),
};

const languageProviderReducer = handleActions(
  {
    [changeLocale]: produce((draft, { payload: { locale } }) => {
      draft.locale = locale;
    }),
  },
  initialState,
);

export default languageProviderReducer;
