import { Button as CertilogoButton, ButtonCta, Text } from '@ebay-certilogo/design-system-web';
import ButtonSimple from '@ebay-certilogo/design-system-web/dist/components/ButtonSimple';
import Image from './Image';
import Link from './Link';

export default {
  button: CertilogoButton,
  button_cta: ButtonCta,
  link: Link,
  button_slim: ButtonSimple,
  text: Text,
  image: Image,
};
