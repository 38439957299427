/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import get from 'lodash/get';
import { initialState } from './reducer';

/**
 * Direct selector to the language domain
 */

const selectLanguage = () => (state) => get(state, 'language') || initialState;

/**
 * Select the language locale
 */

export const makeSelectLocale = createSelector(selectLanguage(), (state) => get(state, 'locale'));
