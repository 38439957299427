import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'resell_with_ebay.complete.title1',
    defaultMessage: 'A draft of your listing is being created',
  },
  buttonListing: {
    id: 'resell_with_ebay.complete.button_listing',
    defaultMessage: 'Your listing',
  },
  buttonHome: {
    id: 'resell_with_ebay.complete.button_home',
    defaultMessage: 'Home',
  },
  bottomMessage: {
    id: 'resell_with_ebay.complete.bottom_message',
    defaultMessage: 'YOUR PRODUCT IS AUTHENTIC<br/>100% ANIMAL FREE.',
  },
  error: {
    id: 'resell_with_ebay.complete.error',
    defaultMessage:
      "Something's gone wrong with our system. Our tech team has been notified. Please try again later.",
  },
  redirect: {
    id: 'resell_with_ebay.complete.redirect',
    defaultMessage: 'Redirecting you to eBay...',
  },
});
