import { call } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';

export default function* setOptin({ payload: { id, type, value } }) {
  const url = `privacy-policy-service/preference`;

  try {
    yield call(fetchWrapper, {
      url,
      method: 'PUT',
      params: {
        id,
        type,
        value,
      },
    });
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
