import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { signUpLoginResponse, setAuthErrors } from '../actions';

export default function* signUpLogin({ payload: { email, redirect } }) {
  const url = `users/v2/registration/${email}`;

  try {
    const { setPassword, setData, user, mailValidation } = yield call(fetchWrapper, {
      url,
    });
    yield put(
      signUpLoginResponse({
        setPassword,
        setData: setData || (mailValidation && true),
        user: user || { email },
        mailgun: mailValidation,
      }),
    );

    if (redirect) {
      if (mailValidation) {
        if (!mailValidation.valid && !mailValidation.didYouMean) {
          yield put(setAuthErrors({ email: 'email.notValid' }));
          throw Error();
        }
        if (!mailValidation.didYouMean) {
          yield put(push(`/auth/create-password`));
        } else {
          yield put(push(`/auth/login-sign-up-error-mailgun?email=${email}`));
        }
      } else if (setPassword) {
        yield put(push(`/auth/create-password`));
      } else {
        yield put(push(`/auth/enter-password`));
      }
    } else {
      yield put(signUpLoginResponse({ setPassword, setData, user, mailgun: mailValidation }));
    }
  } catch ({ response = {}, packet, ...e }) {
    // if (response.status === 404) {
    //   console.log('Error, email doesn’t exist >>>>>>> ');
    // }
    if (response.status === 406) {
      // eslint-disable-next-line no-console
      console.log('Error, mailgun >>>>>>> ');
    }
  }
}
