
import { Box } from '@ebay-certilogo/design-system-web';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { selectConf } from 'containers/App/selectors';

export default function HeaderBox(props) {
  const conf = useSelector(selectConf);
  const { style, ...headerBox } = get(conf, 'authenticationConf.headerBox') || {};
  return <Box {...props} {...headerBox} {...style} />;
}
