import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { unsetBack } from 'containers/App/actions';
import { Button, Div, Heading, Text } from '@ebay-certilogo/design-system-web';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import useColorType from 'components/hooks/useColorType';
import { finalizeRequest } from 'containers/ResellWithEbay/actions';
import {
  selectFinalize,
  selectFinalizeError,
  selectFinalizeLoading,
} from 'containers/ResellWithEbay/selectors';
import { push } from 'connected-react-router';
import EbayButton from 'components/EbayButton';
import messages from './messages';
import { BottomText, Loader, Wrapper } from './styles';

const LISTING_DRAFT_STATUSES = {
  drafted: 'drafted',
  published: 'published',
};

function Complete({ data }) {
  const colorType = useColorType('resellWithEbay');
  const dispatch = useDispatch();
  const isLoading = useSelector(selectFinalizeLoading);
  const finalizeData = useSelector(selectFinalize);
  const finalizeError = useSelector(selectFinalizeError);

  const goToListing = () => {
    window.location.href = finalizeData.listingDraftUrl;
  };

  const handleClickHome = () => {
    dispatch(push(`/connect/${data?.sessionId}`));
  };

  useEffect(() => {
    dispatch(unsetBack());
    dispatch(finalizeRequest(data.id));
  }, []);

  useEffect(() => {
    if (
      finalizeData?.listingDraftUrl &&
      finalizeData?.listingDraftStatus === LISTING_DRAFT_STATUSES.drafted &&
      !isLoading
    ) {
      goToListing();
    }
  }, [finalizeData, isLoading]);

  const renderFinalize = useMemo(() => {
    if (finalizeData) {
      return (
        <Div>
          <EbayButton
            colorType={colorType}
            arrow={false}
            marginTop="m"
            textTransform="none"
            onClick={goToListing}
            to={finalizeData?.listingDraftUrl}
            fontSize="base"
            fontWeight="400"
          >
            <FormattedMessageMarkdown {...messages.buttonListing} />
          </EbayButton>
          <Button colorType={colorType} buttonType="link" arrow={false} onClick={handleClickHome}>
            <FormattedMessageMarkdown {...messages.buttonHome} />
          </Button>
        </Div>
      );
    }
    if (finalizeError) {
      return (
        <Text themeField="form.errorColor" weight="extrabold">
          <FormattedMessageMarkdown {...messages.error} />
        </Text>
      );
    }
    return null;
  }, [finalizeData, finalizeError]);

  return (
    <Wrapper>
      <Div>
        <Heading type="h1" colorType={colorType} marginBottom="m" marginTop="m">
          <FormattedMessageMarkdown {...messages.title} />
        </Heading>
      </Div>
      {isLoading ? (
        <Div textAlign="center">
          <Loader colorType={colorType} size={40} />
          <Text size="s" colorType={colorType}>
            <FormattedMessageMarkdown {...messages.redirect} />
          </Text>
        </Div>
      ) : (
        renderFinalize
      )}
      <BottomText textAlign="center" colorType={colorType}>
        <FormattedMessageMarkdown {...messages.bottomMessage} />
      </BottomText>
    </Wrapper>
  );
}

Complete.propTypes = {
  colorType: PropTypes.string,
  data: PropTypes.object,
};

export default Complete;
