import { defineMessages } from 'react-intl';

export default defineMessages({
  meta: {
    title: {
      id: 'meta.title',
      defaultMessage: 'Certilogo: Connecting Products with People for Brands',
    },
    description: {
      id: 'meta.description',
      defaultMessage:
        'The leading connected product and authentication platform for Fashion & Luxury.',
    },
  },
});
