import { createActions } from 'redux-actions';

const collection = 'onboarding';

export const { getRequest, getResponse } = createActions(
  {
    GET_REQUEST: ({ id }) => ({ id }),
    GET_RESPONSE: (data) => ({ ...data }),
  },
  { prefix: collection },
);
