import { defineMessages } from 'react-intl';

export default defineMessages({
  text: {
    id: 'upload_after_appeal_1.title1',
    defaultMessage:
      'Please take some well-lit, close-up photos - not screenshot - of the CLG tag or label and your product.',
  },
  uploadPictures: {
    id: 'upload_after_appeal_1.button1',
    defaultMessage: 'Upload pictures',
  },
  uploadMorePictures: {
    id: 'upload_after_appeal_2.button1',
    defaultMessage: 'Upload more',
  },
  doneButton: {
    id: 'upload_after_appeal_2.button2',
    defaultMessage: 'Done',
  },
  sizeError: {
    id: 'upload_camera1_taking.sizeError',
    defaultMessage: 'We only accept files up to 15MB in size',
  },
  typeError: {
    id: 'upload_camera1_taking.typeError',
    defaultMessage: 'We only accept image files',
  },
});
