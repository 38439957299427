import { Div, Text } from '@ebay-certilogo/design-system-web';
import styled from 'styled-components';
import LoadingIndicator from 'components/LoadingIndicator';

export const Wrapper = styled(Div)`
  display: flex;
  height: calc(100vh - 120px);
  flex-direction: column;
  justify-content: space-between;
`;

export const BottomText = styled(Text)``;

export const Loader = styled(LoadingIndicator)`
  align-self: center;
  height: 40px;
`;
