import { createActions } from 'redux-actions';

const collection = 'warranty';

export const { createRequest, flowRequest, warrantyResponse, downloadRequest, downloadResponse } =
  createActions(
    {
      CREATE_REQUEST: ({ authenticationId }) => ({ authenticationId }),
      CREATE_RESPONSE: (data) => ({ ...data }),
      FLOW_REQUEST: ({ authenticationId, page, data, action }) => ({
        authenticationId,
        page,
        data,
        action,
      }),
      WARRANTY_RESPONSE: (data) => ({ ...data }),
      DOWNLOAD_REQUEST: ({ token }) => ({
        token,
      }),
      DOWNLOAD_RESPONSE: () => ({}),
    },
    { prefix: collection },
  );
