import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { confirmAuthenticationResponse } from '../actions';

export default function* getConfirmAuthentication({ payload: { a, u, e, v } }) {
  const url = 'authentication/v3/confirm';

  try {
    const { authentication } = yield call(fetchWrapper, {
      method: 'GET',
      url,
      withQuery: true,
      params: { a, u, e, v },
    });

    yield put(confirmAuthenticationResponse());
    if (authentication.id) {
      yield put(push(`/authentication/${authentication.id}/${authentication.status}`));
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  } finally {
    yield put(confirmAuthenticationResponse());
  }
}
