import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useParams, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import Route from 'components/Route';
import usePageType from 'components/hooks/usePageType';
import useColorType from 'components/hooks/useColorType';
import { createRequest, getRequest, resetResellWithEbay } from './actions';
import saga from './saga';
import { selectResellWithEbayLoading, selectResellWithEbayData } from './selectors';
import { Wrapper } from './styles';
import ImageUpload from './states/ImageUpload';
import Complete from './states/Complete';

function ResellWithEbay() {
  const colorType = useColorType('resellWithEbay');
  usePageType('resellWithEbay');
  useInjectSaga({ key: 'resellWithEbay', saga });
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const location = useLocation();
  const sessionId = location?.state?.sessionId;
  const { resellWithEbayId } = useParams();
  const isLoading = useSelector(selectResellWithEbayLoading);
  const data = useSelector(selectResellWithEbayData);

  useEffect(() => {
    if (resellWithEbayId) {
      dispatch(getRequest({ id: resellWithEbayId }));
    } else if (sessionId) {
      dispatch(createRequest({ sessionId }));
    }
    return () => {
      dispatch(resetResellWithEbay());
    };
  }, []);

  if (isLoading || !data) return <LoadingIndicator />;

  return (
    <Wrapper>
      <Switch>
        <Route path={`${path}/image_upload`}>
          <ImageUpload colorType={colorType} data={data || {}} />
        </Route>
        <Route path={`${path}/complete`}>
          <Complete colorType={colorType} data={data || {}} />
        </Route>
      </Switch>
    </Wrapper>
  );
}

ResellWithEbay.propTypes = {
  colorType: PropTypes.string,
};

export default ResellWithEbay;
