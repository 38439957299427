
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import { FormFormik } from '@ebay-certilogo/design-system-web';
import { Formik, Field } from 'formik';
import { useDispatch } from 'react-redux';
import { addMarketplaceSoaRequest, removeMarketplaceSoaRequest } from 'containers/Soa/actions';
import Form from 'components/form/FormComponent';
import FormikAutoSave from 'components/FormikAutoSave';

const validationSchema = Yup.object().shape({
  value: Yup.bool(),
});

function MarketplaceToggleForm({ colorType, soaId, aliasId, alias, marketplaceId, value }) {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    if (values.value === false) {
      dispatch(removeMarketplaceSoaRequest({ soaId, aliasId }));
    } else {
      dispatch(addMarketplaceSoaRequest({ marketplaceId, alias, soaId }));
    }
  };

  return (
    <Formik
      initialValues={{ value }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange
      key={aliasId}
    >
      <Form>
        <Field name="value" component={FormFormik.Toggle} colorType={colorType} />
        <FormikAutoSave onSave={handleSubmit} />
      </Form>
    </Formik>
  );
}

MarketplaceToggleForm.propTypes = {
  colorType: PropTypes.string,
  soaId: PropTypes.string,
  aliasId: PropTypes.string,
  alias: PropTypes.string,
  marketplaceId: PropTypes.string,
  value: PropTypes.bool,
};

export default injectIntl(MarketplaceToggleForm);
