import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { getSoaResponse } from '../actions';

const SERVICE_PAGES = {
  active: 'manage',
  sold: 'sold',
};

export default function* create({ payload: { id } }) {
  const url = `soa/v1/${id}`;

  try {
    const response = yield call(fetchWrapper, {
      method: 'GET',
      url,
    });

    yield put(getSoaResponse());
    yield put(
      push(
        `/authentication/${response.authentication.id}/soa/${
          SERVICE_PAGES[response.status] || response.page
        }`,
      ),
    );
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
