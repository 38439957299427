import { createSelector } from 'reselect';
import get from 'lodash/get';

const selectCodeReviewDomain = () => (state) => state.codeReview;

export const selectCodeReviewLoading = createSelector(selectCodeReviewDomain(), (state) =>
  get(state, 'loading'),
);

export const selectCodeReviewData = createSelector(selectCodeReviewDomain(), (state) =>
  get(state, 'data'),
);

export const selectCodeReviewUploadLoading = createSelector(selectCodeReviewDomain(), (state) =>
  get(state, 'uploadLoading'),
);

export const selectCodeReviewUploadImages = createSelector(selectCodeReviewDomain(), (state) =>
  get(state, 'uploadImages'),
);
