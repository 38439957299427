import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { marketplaceResponse } from '../actions';

export default function* marketplace() {
  const url = 'users/v1/seller';

  try {
    const callResponse = yield call(fetchWrapper, {
      url,
      withQuery: true,
      params: {
        online: false,
      },
    });

    const response = callResponse.map((el) => ({
      id: `${el.store.id}-${el.alias}`,
      ...el,
    }));

    yield put(marketplaceResponse(response));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
