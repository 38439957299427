import { defineMessages } from 'react-intl';

export default defineMessages({
  ctaAuth: {
    prefix: {
      id: 'splashpage.cta.1.a',
      defaultMessage: 'check',
    },
    text: {
      id: 'splashpage.cta.1.b',
      defaultMessage: 'authenticity',
    },
  },
  ctaWeb: {
    prefix: {
      id: 'splashpage.cta.2.a',
      defaultMessage: 'visit the',
    },
    text: {
      id: 'splashpage.cta.2.b',
      defaultMessage: 'website',
    },
  },
});
