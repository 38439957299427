import { createSelector } from 'reselect';
import get from 'lodash/get';

const selectFakeReportDomain = () => (state) => state.fakeReport;

export const selectFakeReportLoading = createSelector(selectFakeReportDomain(), (state) =>
  get(state, 'loading'),
);

export const selectFakeReportData = createSelector(selectFakeReportDomain(), (state) =>
  get(state, 'data'),
);
export const selectFakeReportDownloadLoading = createSelector(selectFakeReportDomain(), (state) =>
  get(state, 'downloadLoading'),
);
