import { defineMessages } from 'react-intl';

export default defineMessages({
  home: {
    id: 'navigation.home',
    defaultMessage: 'Home',
  },
  connectedBrands: {
    id: 'navigation.connectedbrands',
    defaultMessage: 'Connected Brands',
  },
  faq: {
    id: 'navigation.faq',
    defaultMessage: 'FAQ',
  },
  help: {
    id: 'navigation.help',
    defaultMessage: 'Help',
  },
  terms: {
    id: 'navigation.terms',
    defaultMessage: 'Terms & conditions',
  },
  privacy: {
    id: 'navigation.privacy',
    defaultMessage: 'Privacy',
  },
  cookie: {
    id: 'navigation.cookie',
    defaultMessage: 'Cookie policy',
  },
  settings: {
    id: 'navigation.settings',
    defaultMessage: 'Privacy settings',
  },
  info: {
    id: 'navigation.info',
    defaultMessage: 'Certilogo info',
  },
  infoAuth: {
    id: 'navigation.info_auth',
    defaultMessage: 'Certilogo settings & info',
  },
  logout: {
    id: 'navigation.logout',
    defaultMessage: 'Logout',
  },
  login: {
    id: 'navigation.login',
    defaultMessage: 'Login',
  },
  forgotPassword: {
    id: 'navigation.forgotPassword',
    defaultMessage: 'Forgot Password?',
  },
  language: {
    id: 'navigation.language',
    defaultMessage: 'Language',
  },
});
