import get from 'lodash/get';
import isObject from 'lodash/isObject';
import config from 'config';

export const getLocalImage = (relativeUrl) => {
  const baseUrl = config.basename ? `/${config.basename}` : '';
  return `${baseUrl}${relativeUrl || ''}`;
};

export const getImage = (image) => {
  if (isObject(image)) {
    const imageUrl = get(image, 'url');
    const provider = get(image, 'provider');
    const urlPrefix = provider === 'local' ? config.api.cms : '';
    return imageUrl ? `${urlPrefix}${imageUrl}` : '';
  }
  return getLocalImage(image);
};

export default {
  getImage,
  getLocalImage,
};
