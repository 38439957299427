import { defineMessages } from 'react-intl';

export default defineMessages({
  done: {
    id: 'authentic2_soa_sold.title1',
    defaultMessage: 'Done',
  },
  sealClosed: {
    id: 'authentic2_soa_sold.text1',
    defaultMessage: 'Seal closed',
  },
  caption: {
    id: 'authentic2_soa_sold.text2',
    defaultMessage: 'Thanks',
  },
  linkResponse: {
    id: 'authentic2_soa_sold.text3',
    defaultMessage: 'Go back to the response',
  },
});
