import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { List, Text } from '@ebay-certilogo/design-system-web';
import LoadingIndicator from 'components/LoadingIndicator';
import { Waypoint } from 'react-waypoint';

import OneRow from './OneRow';
import TwoRow from './TwoRow';
import PlaceRow from './PlaceRow';
import NotFoundRow from './NotFoundRow';

/* eslint-disable */
const rowType = {
  oneRow: ({ accessoryRow, item, colorType, left, right, onClick, index, bordered, rowPadding, id }) => (
    <OneRow
      key={index}
      colorType={colorType}
      left={left} right={right}
      text={get(item, accessoryRow)}
      onClick={() => onClick(item)}
      bordered={bordered}
      rowPadding={rowPadding}
      item={item}
      id={id}
    />
  ),
  twoRow: ({ accessoryRow, accessoryRowRight, item, colorType, left, right, onClick, index, bordered, rowPadding, id }) => {
    const [title, description] = accessoryRow;
    return (
      <TwoRow
        key={index}
        colorType={colorType}
        left={left}
        right={accessoryRowRight ? item[accessoryRowRight] : right}
        title={get(item, title)}
        description={get(item, description)}
        onClick={() => onClick(item)}
        accessoryRowRight={accessoryRowRight}
        bordered={bordered}
        rowPadding={rowPadding}
        item={item}
        id={id}
      />
    );
  },
  placeRow: ({ accessoryRow, accessoryRowRight, item, colorType, left, right, onClick, index, bordered, rowPadding, highlightFirst }) => {
    const [title, distance, description] = accessoryRow;
    return (
      <PlaceRow
        key={index}
        highlight={highlightFirst && index === 0}
        colorType={colorType}
        left={left}
        right={accessoryRowRight ? item[accessoryRowRight] : right}
        title={get(item, title)}
        description={get(item, description)}
        distance={get(item, distance)}
        onClick={() => onClick(item)}
        accessoryRowRight={accessoryRowRight}
        bordered={bordered}
        rowPadding={rowPadding}
        item={item}
      />
    );
  },
};
/* eslint-enable */

function Lists({
  colorType,
  onClick,
  data,
  type,
  title,
  bordered,
  accessoryRow,
  left,
  right,
  isLoading,
  marginTop,
  marginBottom,
  accessoryRowRight,
  notFound,
  rowPadding,
  onPageBottom,
  highlightFirst,
  header,
  footer,
}) {
  const listRef = useRef(null);
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    listRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }, [data]);
  return (
    <>
      {title && (
        <Text
          colorType={colorType}
          weight="extrabold"
          margin={{ size: 's', position: 'vertical' }}
          marginTop={marginTop}
        >
          {title}
        </Text>
      )}
      {header}

      <List colorType={colorType} bordered={bordered} marginBottom={marginBottom}>
        {data.map((item, index) => (
          <div ref={index === 0 ? listRef : null}>
            {rowType[type]({
              accessoryRow,
              accessoryRowRight,
              item,
              colorType,
              left,
              right,
              onClick,
              index,
              bordered,
              rowPadding,
              highlightFirst,
            })}
          </div>
        ))}
        {onPageBottom && <Waypoint onEnter={onPageBottom} />}
        {!data.length && (notFound || <NotFoundRow colorType={colorType} />)}
        {isLoading && <LoadingIndicator colorType={colorType} size={30} />}
        {footer}
      </List>
    </>
  );
}

Lists.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.array,
  onClick: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  bordered: PropTypes.bool,
  accessoryRow: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  left: PropTypes.node,
  right: PropTypes.node,
  isLoading: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  accessoryRowRight: PropTypes.string,
  notFound: PropTypes.node,
  rowPadding: PropTypes.object,
  onPageBottom: PropTypes.func,
  highlightFirst: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  footer: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

export default Lists;
