import { createActions } from 'redux-actions';

const collection = 'survey';

export const { getRequest, getResponse, sendRequest, sendResponse, sendReset } = createActions(
  {
    GET_REQUEST: ({ id, service, sessionId }) => ({ id, service, sessionId }),
    GET_RESPONSE: (data) => ({ ...data }),
    SEND_REQUEST: (data) => ({ ...data }),
    SEND_RESPONSE: () => ({}),
  },
  { prefix: collection },
);
