
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import map from 'lodash/map';
import Markdown from 'components/Markdown';

function FormattedMessageMarkdown({ colorType, style, className, styleProps, isInline, ...rest }) {
  return (
    <FormattedMessage {...rest}>
      {(messages) =>
        map(messages, (msg) => (
          <Markdown
            colorType={colorType}
            style={style}
            className={className}
            styleProps={styleProps}
            isInline={isInline}
          >
            {msg}
          </Markdown>
        ))
      }
    </FormattedMessage>
  );
}

FormattedMessageMarkdown.propTypes = {
  colorType: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  styleProps: PropTypes.object,
  className: PropTypes.string,
  markdownOptions: PropTypes.object,
  isInline: PropTypes.bool,
};

export default FormattedMessageMarkdown;
