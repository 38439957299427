
import PropTypes from 'prop-types';
import CmsImage from 'components/cms/dataTypes/Image';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import { Text } from '@ebay-certilogo/design-system-web';
import { Wrapper, Overlay } from './styles';

const Image = ({
  colorType,
  text,
  icon,
  image,
  imageVar,
  align,
  fontSize,
  textTransform,
  onClick,
  ...rest
}) =>
  (text || icon || image || imageVar) && (
    <Wrapper onClick={onClick}>
      <Overlay align={align}>
        {text && (
          <Text
            colorType={colorType}
            marginBottom="s"
            size={fontSize}
            textTransform={textTransform}
          >
            <FormattedMessageMarkdown id={text} defaultMessage={text} />
          </Text>
        )}
        {icon}
      </Overlay>
      <CmsImage image={image} {...rest} />
    </Wrapper>
  );

Image.propTypes = {
  colorType: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.object,
  imageVar: PropTypes.string,
  icon: PropTypes.element,
  align: PropTypes.string,
  fontSize: PropTypes.string,
  textTransform: PropTypes.string,
  onClick: PropTypes.func,
  gtm: PropTypes.object,
};

export default Image;
