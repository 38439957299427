import { createActions } from 'redux-actions';

const collection = 'resellWithEbay';

export const {
  getRequest,
  createRequest,
  flowRequest,
  resellWithEbayResponse,
  imageUploadRequest,
  imageUploadResponse,
  imageDeleteRequest,
  imageDeleteResponse,
  resetResellWithEbay,
  finalizeRequest,
  finalizeResponse,
} = createActions(
  {
    GET_REQUEST: ({ id }) => ({ id }),
    CREATE_REQUEST: ({ sessionId }) => ({ sessionId }),
    FLOW_REQUEST: ({ id, action, status, data }) => ({
      id,
      action,
      status,
      data,
    }),
    RESELL_WITH_EBAY_RESPONSE: (data) => ({ ...data }),
    IMAGE_UPLOAD_REQUEST: ({ resellWithEbayId, type, file }) => ({
      resellWithEbayId,
      type,
      file,
    }),
    IMAGE_UPLOAD_RESPONSE: (data) => ({ ...data }),
    IMAGE_DELETE_REQUEST: (id) => ({ id }),
    IMAGE_DELETE_RESPONSE: (id) => ({ id }),
    RESET_RESELL_WITH_EBAY: () => null,
    FINALIZE_REQUEST: (id) => ({ id }),
    FINALIZE_RESPONSE: (data) => data,
  },
  { prefix: collection },
);
