import { takeLatest, all } from 'redux-saga/effects';
import {
  createRequest,
  finalizeRequest,
  flowRequest,
  getRequest,
  imageDeleteRequest,
  imageUploadRequest,
} from '../actions';
import create from './create';
import flow from './flow';
import { remove, upload } from './image';
import finalize from './finalize';
import get from './get';

export default function* rootSaga() {
  yield all([takeLatest(createRequest, create)]);
  yield all([takeLatest(getRequest, get)]);
  yield all([takeLatest(flowRequest, flow)]);
  yield all([takeLatest(imageUploadRequest, upload)]);
  yield all([takeLatest(imageDeleteRequest, remove)]);
  yield all([takeLatest(finalizeRequest, finalize)]);
}
