import {
  getPrivacyPolicySignupConfigRequest,
  getPrivacyPolicySignupConfigResponse,
} from 'containers/Auth/actions';
import { put, take, all, call } from 'redux-saga/effects';
import { isBrandedDomain } from 'utils/utils';
import {
  defaultConfRequest,
  unsetPrompt,
  initAppEnd,
  setDefaultConf,
  clgFeatureConfRequest,
  setClgFeatureConf,
} from '../actions';
import loadConfWebFont from './loadConfWebFont';

export default function* initApp() {
  try {
    yield put(defaultConfRequest());
    yield put(getPrivacyPolicySignupConfigRequest({}));
    if (isBrandedDomain) {
      yield put(clgFeatureConfRequest());
    }
    yield call(loadConfWebFont);
    yield put(unsetPrompt());
    yield all([
      take(setDefaultConf),
      take(setClgFeatureConf),
      take(getPrivacyPolicySignupConfigResponse),
    ]);
    yield put(initAppEnd());
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
