import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { getStoresResponse } from '../actions';

export default function* getStores({
  payload: {
    map,
    online = false,
    swLat,
    swLng,
    neLat,
    neLng,
    authenticationId,
    value,
    latitude,
    longitude,
    geolocation,
    url,
  },
}) {
  const apiUrl = 'store/v3/search';

  try {
    const stores = yield call(fetchWrapper, {
      url: apiUrl,
      withQuery: true,
      params: {
        search: value,
        url,
        online,
        swLat,
        swLng,
        neLat,
        neLng,
        authId: authenticationId,
        latitude,
        longitude,
      },
    });
    yield put(getStoresResponse({ stores, map, geolocation }));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
