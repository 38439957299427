import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Heading, Text, Div } from '@ebay-certilogo/design-system-web';
import ButtonComponent from 'components/ButtonComponent';

import config from 'config';

import useColorType from 'components/hooks/useColorType';
import { setBack } from '../actions';
import messages from './messages';

const Wrapper = styled(Div)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: calc(100vh - 20px);
`;

function ErrorPage() {
  const colorType = useColorType('genericError');
  const dispatch = useDispatch();
  const hystory = useHistory();

  useEffect(() => {
    dispatch(
      setBack({
        back: 'history',
      }),
    );
  }, []);

  return (
    <Wrapper>
      <Heading themeField="primaryAccentColor" colorType="dark">
        <FormattedMessage {...messages.title} />
      </Heading>
      <Text colorType={colorType} marginBottom="s">
        <FormattedMessage {...messages.text} />
      </Text>
      <Button colorType={colorType} arrow={false} noMargin onClick={() => hystory.goBack()}>
        <FormattedMessage {...messages.tryAgain} />
      </Button>
      <ButtonComponent
        component={Text}
        weight="semibold"
        href={config.discoverUrl}
        colorType={colorType}
        buttonType="link"
        arrow={false}
        margin={{ size: 'm', position: 'vertical' }}
      >
        <FormattedMessage {...messages.link} />
      </ButtonComponent>
    </Wrapper>
  );
}

ErrorPage.propTypes = {
  colorType: PropTypes.string,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default ErrorPage;
