import { call, put, select } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { selectIsAuthenticated } from 'containers/Auth/selectors';
import { setRedirect } from 'containers/Auth/actions';
import history from 'utils/history';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { authenticationResponse } from '../actions';

export default function* getAuthentication({ payload: { authenticationId, disableRedirect } }) {
  const url = `authentication/v3/show/${authenticationId}`;

  try {
    const locale = yield select(makeSelectLocale);
    const response = yield call(fetchWrapper, {
      url,
      withQuery: true,
      params: {
        locale,
      },
    });

    yield put(
      authenticationResponse(
        response,
        `/authentication/${authenticationId}/${response.authentication.status}`,
      ),
    );
    if (!disableRedirect || response.authentication.status === 'login')
      yield put(
        push(
          `/authentication/${authenticationId}/${response.authentication.status}${history.location.hash}`,
        ),
      );
  } catch ({ response }) {
    if (response) {
      if (response.status === 400) yield put(push(`/code`));
      if (response.status === 404) {
        const isAuthenticated = yield select(selectIsAuthenticated);
        if (isAuthenticated) {
          yield put(push(`/code`));
        } else {
          yield put(push(`/auth/sign-up`));
          yield put(setRedirect(`/authentication/${authenticationId}`));
        }
      }
    }
    yield put(authenticationResponse());
  }
}
