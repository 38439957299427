
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { selectIsAuthenticated } from 'containers/Auth/selectors';
import { setRedirect } from 'containers/Auth/actions';
import Route from 'components/Route';

function AuthenticatedRoute({ children, location: { pathname }, ...rest }) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  if (!isAuthenticated) {
    dispatch(setRedirect(pathname));
    return <Redirect to="/auth/sign-up" />;
  }

  return <Route {...rest}>{children}</Route>;
}

AuthenticatedRoute.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
};

export default AuthenticatedRoute;
