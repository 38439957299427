import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'soa_creation_pictures1.title1',
    defaultMessage: 'Get the Certilogo Seal of Authentication',
  },
  text: {
    id: 'soa_creation_marketplace.text1',
    defaultMessage:
      'Identify the marketplaces you will list this product and the username you use for each:',
  },
  addMarketplace: {
    id: 'soa_creation_marketplace.button1',
    defaultMessage: 'add marketplace',
  },
  button: {
    id: 'soa_creation_marketplace.button2',
    defaultMessage: 'done',
  },
});
