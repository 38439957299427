
import { Heading } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

function ErrorComponent() {
  return (
    <>
      <Heading type="h2">
        <FormattedMessage {...messages.title} />
      </Heading>
      <Heading type="h3">
        <FormattedMessage {...messages.text} />
      </Heading>
    </>
  );
}

export default ErrorComponent;
