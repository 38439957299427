export default function* authenticatorConfSetter({ payload: { conf } }) {
  try {
    if (conf?.webFontConf) {
      // eslint-disable-next-line no-undef
      yield WebFont.load(conf?.webFontConf);
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
