import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectUiDesignSystem } from 'containers/App/selectors';

const useUiDesginSystemComponentSwitcher = (componentsMap = {}) => {
  const uiDesignSystem = useSelector(selectUiDesignSystem);

  const Component = useMemo(() => {
    if (componentsMap[uiDesignSystem]) {
      return componentsMap[uiDesignSystem];
    }
    console.error(`Component key "${uiDesignSystem}" not found in components map.`);

    return null;
  }, [componentsMap, uiDesignSystem]);

  return Component;
};

export default useUiDesginSystemComponentSwitcher;
