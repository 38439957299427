import { takeLatest, all } from 'redux-saga/effects';
import {
  signUpLoginRequest,
  loginRequest,
  forgotPasswordRequest,
  tokenVerifyRequest,
  passwordUpdateRequest,
  getProfileRequest,
  setProfileRequest,
  loginResponse as loginResponseAction,
  getOptinRequest,
  setOptinRequest,
  logout,
  deleteUser,
  setMailgun,
  confirmEmailRequest,
  changeEmailRequest,
  getPrivacyPolicySignupConfigRequest,
  codeLoginRequest,
  codeLoginCheckRequest,
  setProfileEbayRequest,
} from '../actions';
import signUpLogin from './signUpLogin';
import login from './login';
import forgotPassword from './forgotPassword';
import tokenVerify from './tokenVerify';
import passwordUpdate from './passwordUpdate';
import getProfile from './getProfile';
import setProfile from './setProfile';
import getOptin from './getOptin';
import setOptin from './setOptin';
import loginResponse from './loginResponse';
import logoutSaga from './logout';
import deleteUserSaga from './deleteUser';
import setMailgunSaga from './setMailgun';
import confirmEmail from './confirmEmail';
import changeEmail from './changeEmail';
import getPrivacyPolicySignupConfigSaga from './getPrivacyPolicySignupConfig';
import codeLoginRequestSaga from './codeLoginRequest';
import codeLoginCheck from './codeLoginCheck';
import setProfileEbay from './setProfileEbay';

export default function* rootSaga() {
  yield all([
    takeLatest(signUpLoginRequest, signUpLogin),
    takeLatest(codeLoginRequest, codeLoginRequestSaga),
    takeLatest(codeLoginCheckRequest, codeLoginCheck),
    takeLatest(loginRequest, login),
    takeLatest(forgotPasswordRequest, forgotPassword),
    takeLatest(tokenVerifyRequest, tokenVerify),
    takeLatest(passwordUpdateRequest, passwordUpdate),
    takeLatest(getProfileRequest, getProfile),
    takeLatest(setProfileRequest, setProfile),
    takeLatest(setProfileEbayRequest, setProfileEbay),
    takeLatest(getPrivacyPolicySignupConfigRequest, getPrivacyPolicySignupConfigSaga),
    takeLatest(getOptinRequest, getOptin),
    takeLatest(setOptinRequest, setOptin),
    takeLatest(loginResponseAction, loginResponse),
    takeLatest(logout, logoutSaga),
    takeLatest(deleteUser, deleteUserSaga),
    takeLatest(setMailgun, setMailgunSaga),
    takeLatest(confirmEmailRequest, confirmEmail),
    takeLatest(changeEmailRequest, changeEmail),
  ]);
}
