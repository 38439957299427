import { createActions } from 'redux-actions';

const collection = 'gtm/generic';

export const { back, menuClick, closeMenu } = createActions(
  {
    BACK: () => ({}),
    MENU_CLICK: (label) => ({ label }),
    CLOSE_MENU: () => ({}),
  },
  { prefix: collection },
);
