import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { fetchWrapper } from 'utils/Api/fetchWrapper';
import { selectJwt } from 'containers/Auth/selectors';
import LoadingIndicator from 'components/LoadingIndicator';
import { Div } from '@ebay-certilogo/design-system-web';

const StyledImage = styled.img`
  width: 100%;
  height: auto;
`;

const LoaderWrapper = styled(Div)`
  display: flex;
  justify-content: center;
`;

const Loading = styled(LoadingIndicator)`
  width: initial;
  height: initial;
  padding: 10px;
  margin: 0;
  svg {
    height: 100%;
    width: 100%;
    max-width: 40px;
    max-height: 40px;
  }
`;

function Image({ url, className }) {
  const [image, setImage] = useState();
  const jwtToken = useSelector(selectJwt);

  useEffect(() => {
    if (url) {
      fetchWrapper({ url: url.substr(1), options: { headers: { jwtToken } }, image: true })
        .then((imageUrl) => {
          setImage(imageUrl);
        })
        .catch((error) => {
          console.log(error);
          setImage('error');
        });
    }
  }, [url]);

  if (!image) {
    return <LoaderWrapper className={className}>{url && <Loading />}</LoaderWrapper>;
  }
  if (image === 'error') {
    return null;
  }

  return <StyledImage src={image} className={className} alt="" />;
}

Image.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
};

export default Image;
