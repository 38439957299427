import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch, Redirect } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import withColorType from 'components/HOC/withColorType';

import { useInjectSaga } from 'utils/injectSaga';
import { createRequest } from '../actions';
import { selectCodeReviewLoading, selectCodeReviewData } from '../selectors';
import saga from '../saga';

function Create() {
  useInjectSaga({ key: 'code-review', saga });
  const { path } = useRouteMatch();
  const { authenticationId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(createRequest({ authenticationId }));
  }, []);

  const isLoading = useSelector(selectCodeReviewLoading);
  const data = useSelector(selectCodeReviewData);

  if (isLoading || !data) return <LoadingIndicator />;

  return <Redirect to={`${path}/${data.token}/upload`} />;
}

Create.propTypes = {
  colorType: PropTypes.string,
};

export default withColorType(Create);
