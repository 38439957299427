import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button as CertilogoButton, Div } from '@ebay-certilogo/design-system-web';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { Link } from 'react-router-dom';
import { config, useTransition, animated } from '@react-spring/web';
import { Lock } from '@ebay-certilogo/design-system-web/dist/components/icons';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import styled from 'styled-components';
import Image from 'components/cms/dataTypes/Image';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import { getImage } from 'utils/images';
import { makeAction } from './actions';
import types from './types';

const Icon = styled(Image)`
  display: flex;
  svg {
    margin: 0;
    height: ${({ height }) => height}px;
    width: ${({ width }) => width}px;
  }
`;

export const IconWrapper = styled(Div)`
  height: ${({ height = 20 }) => height}px;
  width: ${({ width = 20 }) => width}px;
`;

function Button({
  buttonType,
  blank,
  text,
  url,
  style,
  action,
  completeAction,
  logEventName,
  colorType,
  locked,
  size,
  icon: _icon,
  iconOpen: _iconOpen,
  iconSize = 20,
  iconRight: _iconRight,
  iconRightOpen: _iconRightOpen,
  iconRightSize = 20,
  image,
  imageVar,
  propsBag,
  propsBag: { logger } = {},
  children,
  className,
  componentType,
  buttonComponentClassName,
  gtm,
  isOpen,
  ...rest
}) {
  const ButtonComponent = types[buttonType || 'button'] || CertilogoButton;
  const handleClick = () => {
    if (logger) {
      logger({
        action: 'click',
        type: componentType,
        label: text,
        locked,
        link: url,
        step: 'finish',
        start: 1,
        finish: 1,
        gtm,
        event: { type: 'link', name: logEventName, category: 'link' },
        params: { target: url, label: text },
      });
    }
    makeAction(action, { completeAction, propsBag });
  };

  const transitions = useTransition(isOpen, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: isOpen,
    delay: 200,
    config: config.wobbly,
  });

  const iconTransitions = (iconComponent, iconComponentOpen, iconComponentSize) =>
    iconComponentOpen ? (
      transitions(({ opacity }, _isOpen) =>
        _isOpen ? (
          <animated.div
            style={{
              position: 'absolute',
              opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
            }}
          >
            <Icon image={iconComponentOpen} width={iconComponentSize} height={iconComponentSize} />
          </animated.div>
        ) : (
          <animated.div
            style={{
              position: 'absolute',
              opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
            }}
          >
            <Icon image={iconComponent} width={iconComponentSize} height={iconComponentSize} />
          </animated.div>
        ),
      )
    ) : (
      <Icon image={iconComponent} width={iconComponentSize} height={iconComponentSize} />
    );

  const triggerIcon = getImage(_icon);
  const triggerIconOpen = getImage(_iconOpen);
  const triggerIconRight = getImage(_iconRight);
  const triggerIconRightOpen = getImage(_iconRightOpen);
  const icon = useMemo(
    () =>
      triggerIcon && (
        <IconWrapper height={iconSize} width={iconSize}>
          {iconTransitions(triggerIcon, triggerIconOpen, iconSize)}
        </IconWrapper>
      ),
    [isOpen, triggerIcon, triggerIconOpen, iconSize],
  );
  const iconRight = useMemo(
    () =>
      locked ? (
        <Lock />
      ) : (
        triggerIconRight && (
          <IconWrapper height={iconRightSize} width={iconRightSize}>
            {iconTransitions(triggerIconRight, triggerIconRightOpen, iconRightSize)}
          </IconWrapper>
        )
      ),
    [locked, isOpen, triggerIconRight, triggerIconRightOpen, iconRightSize],
  );

  const content =
    !isNil(text) && !isEmpty(text) ? (
      <FormattedMessageMarkdown id={text} defaultMessage={text} values={propsBag} />
    ) : (
      children
    );

  const isAvailable = useMemo(
    () =>
      (text || icon || iconRight || image || content) &&
      (url || action || rest.onClick || rest.type),
    [text, icon, iconRight, image, content, url, action],
  );

  return isAvailable ? (
    <Div {...pick(style, ['margin', 'noMargin'])} className={className}>
      <ButtonComponent
        className={buttonComponentClassName}
        colorType={colorType}
        as={!isEmpty(url) && (!action ? 'a' : Link)}
        href={!action && url}
        buttonType={buttonType}
        to={action === 'inner_link' && url}
        alignSelf="flex-end"
        onClick={handleClick}
        target={blank && '_blank'}
        locked={locked}
        size={size || 'l'}
        icon={icon}
        iconSize={iconSize}
        iconRight={iconRight}
        iconRightSize={iconRightSize}
        labelIcon={iconRight}
        noMargin
        image={image}
        imageVar={imageVar}
        text={text}
        style={{ minHeight: style?.height, width: style?.width }}
        {...omit(style, ['margin', 'noMargin'])}
        {...rest}
      >
        {buttonType === 'button_slim' ? icon : content}
      </ButtonComponent>
    </Div>
  ) : null;
}

Button.propTypes = {
  children: PropTypes.node,
  colorType: PropTypes.string,
  componentType: PropTypes.string,
  className: PropTypes.string,
  buttonType: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  style: PropTypes.object,
  action: PropTypes.string,
  completeAction: PropTypes.func,
  logEventName: PropTypes.string,
  size: PropTypes.string,
  blank: PropTypes.bool,
  locked: PropTypes.bool,
  propsBag: PropTypes.object,
  icon: PropTypes.object,
  iconSize: PropTypes.number,
  iconRightSize: PropTypes.number,
  iconOpen: PropTypes.object,
  iconRight: PropTypes.object,
  iconRightOpen: PropTypes.object,
  gtm: PropTypes.object,
  image: PropTypes.object,
  imageVar: PropTypes.string,
  buttonComponentClassName: PropTypes.string,
  isOpen: PropTypes.bool,
};

export default Button;
