import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { authenticationResponse, resetAuthentication } from '../actions';

export default function* create({ payload: { sessionId, flow, ...params } }) {
  const url = 'authentication/v4/create';
  // const url = 'http://localhost:3102/authentication/v4/create';

  try {
    const { authentication, conf } = yield call(fetchWrapper, {
      method: 'POST',
      url,
      params: {
        sessionId,
        flow,
        ...params,
      },
    });

    yield put(authenticationResponse({ conf, authentication }));

    yield put(push(`/authentication/${authentication.id}/${authentication.status}`));
  } catch ({ response }) {
    yield put(authenticationResponse());
    if (response?.status === 400) {
      yield put(resetAuthentication());
      yield put(push(`/code`));
    }
  }
}
