import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { profileResponse } from '../actions';

export default function* getProfile() {
  const url = `users/v3/profile/`;

  try {
    const response = yield call(fetchWrapper, {
      url,
    });

    yield put(profileResponse({ data: response }));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
