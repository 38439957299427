import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'formik';

class FormikAutoSave extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    isSaving: false,
    saveError: null,
  };

  save = _.debounce(() => {
    const {
      onSave,
      formik: { isValid, values },
    } = this.props;
    const { saveError } = this.state;
    if (!isValid) {
      return;
    }
    this.setState({ isSaving: true, saveError: null }, () => {
      Promise.resolve(onSave(values)).then(
        () => this.setState({ isSaving: false, lastSaved: new Date() }),
        () => this.setState({ isSaving: false, saveError }),
      );
    });
    // eslint-disable-next-line react/destructuring-assignment
  }, this.props.debounce);

  componentDidUpdate(prevProps) {
    const {
      formik: { values: newValues },
    } = this.props;
    const {
      formik: { values: oldValues },
    } = prevProps;
    if (!_.isEqual(oldValues, newValues)) {
      this.save();
    }
  }

  render() {
    const { render } = this.props;
    return render ? render(this.state) : null;
  }
}
FormikAutoSave.defaultProps = {
  debounce: 500,
};
FormikAutoSave.propTypes = {
  render: PropTypes.func,
  formik: PropTypes.object,
  onSave: PropTypes.func,
  debounce: PropTypes.number,
};
export default connect(FormikAutoSave);
