import { takeLatest, all } from 'redux-saga/effects';
import { createRequest, updateRequest, downloadFakeReportRequest } from '../actions';
import create from './create';
import update from './update';
import downloadFakeReport from './downloadFakeReport';

export default function* rootSaga() {
  yield all([takeLatest(createRequest, create)]);
  yield all([takeLatest(updateRequest, update)]);
  yield all([takeLatest(downloadFakeReportRequest, downloadFakeReport)]);
}
