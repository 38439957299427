import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { getMarketplaceSoaRequest } from '../actions';

export default function* addMarketplaceSoa({ payload: { soaId, ...data } }) {
  const url = `soa/v1/${soaId}/alias`;

  try {
    yield call(fetchWrapper, {
      url,
      method: 'POST',
      params: data,
    });

    yield put(getMarketplaceSoaRequest(soaId));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
