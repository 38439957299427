import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { logout } from '../actions';

export default function* deleteUser({ payload: { id } }) {
  const url = `users/v2/user/${id}`;

  try {
    yield call(fetchWrapper, {
      url,
      method: 'DELETE',
    });
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  } finally {
    yield put(logout());
  }
}
