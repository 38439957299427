import { Div } from '@ebay-certilogo/design-system-web';
import styled from 'styled-components';

export const Header = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Body = styled(Div)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: calc(100vh - 20px);
`;
