import { call, put, select } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { imageLabelUploadResponse, imageProductUploadResponse, flowRequest } from '../actions';
import { selectSoaUploadLabels } from '../selectors';

export default function* imageUpload({ payload: { soaId, type, file: fileData, nextOption } }) {
  const url = 'soa/v1/image';

  const params = new FormData();
  params.append('file', fileData);
  params.append('type', type);
  params.append('soaId', soaId);

  try {
    const response = yield call(fetchWrapper, {
      method: 'POST',
      url,
      formData: params,
    });
    if (type === 'label') {
      const uploadLabels = yield select(selectSoaUploadLabels);
      if (uploadLabels.length > 0) yield put(flowRequest(nextOption));
      yield put(imageLabelUploadResponse(response));
    }
    if (type === 'product') {
      yield put(imageProductUploadResponse(response));
      yield put(flowRequest(nextOption));
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
