import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Layout as ClgLayout, Flex } from '@ebay-certilogo/design-system-web';
import { useHistory } from 'react-router-dom';
import { withTheme } from 'styled-components';
import PoweredBy from 'components/PoweredBy';
import {
  selectGlobalBack,
  selectBackgroundImage,
  selectPoweredBy,
  selectPromptIsActive,
  selectGlobalColorType,
} from 'containers/App/selectors';
import NavigationPrompt from './NavigationPrompt';
import Header from './Header';

function Layout({ children }) {
  const dispatch = useDispatch();
  const colorType = useSelector(selectGlobalColorType);
  const contentRef = useRef();
  const history = useHistory();
  const backgroundImage = useSelector(selectBackgroundImage);
  const promptIsActive = useSelector(selectPromptIsActive);
  const isPoweredBy = useSelector(selectPoweredBy);
  const back = useSelector(selectGlobalBack);

  const when = back && (back !== 'history' || (back.type && back.type !== 'history'));

  return (
    <ClgLayout backgroundImage={backgroundImage}>
      <Flex id="layout-root-portal" />
      <Header contentRef={contentRef} colorType={colorType} />
      <ClgLayout.Content
        colorType={colorType}
        ref={contentRef}
        footer={isPoweredBy && <PoweredBy />}
      >
        {children}
        <NavigationPrompt
          when={when}
          navigate={(path) => history.push(path)}
          backCallback={() => dispatch(back)}
          promptIsActive={promptIsActive}
          shouldBlockNavigation={(location) => {
            if (location.pathname.includes('authentication')) {
              return true;
            }
            return false;
          }}
        />
      </ClgLayout.Content>
    </ClgLayout>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withTheme(Layout);
