import { uniqBy, find, get } from 'lodash';
import { getDistance } from 'geolib';

export const getGeoProp = (geocode, key) =>
  find(geocode.address_components, ({ types }) => types.includes(key)) || {};

export const getGeocodeDetails = (input) =>
  new Promise(async (resolve, reject) => {
    try {
      // eslint-disable-next-line no-undef
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(input, (results) => {
        const result = results[0];
        if (result) {
          resolve({
            address: result.formatted_address,
            city: getGeoProp(result, 'locality').long_name,
            countryName: getGeoProp(result, 'country').long_name,
            latitude: get(result, 'geometry.location').lat(),
            longitude: get(result, 'geometry.location').lng(),
          });
        }
      });
    } catch (error) {
      reject(error);
    }
  });

export const getAutocompleteSuggestions = (value) =>
  new Promise(async (resolve, reject) => {
    try {
      // eslint-disable-next-line no-undef
      const autocompleteService = new google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions({ input: value }, (predictions) => {
        resolve(predictions);
      });
    } catch (error) {
      reject(error);
    }
  });

const createAttrDiv = (id) => {
  const divAttrSelector = document.getElementById(id);

  if (divAttrSelector !== null) return divAttrSelector;
  const el = document.createElement('div');
  el.setAttribute('id', id);
  return el;
};

function distanceFormatting(value, dp) {
  return `${parseFloat(value, 0).toFixed(dp)} m`;
}

const arrayWithDistance = (data, myPosition) => {
  return data.map(({ geometry: { location, ...geometry }, ...rest }) => {
    if (location || myPosition) {
      const distance = getDistance(
        { latitude: location.lat(), longitude: location.lng() },
        myPosition,
      );
      return {
        distance: distanceFormatting(distance, 0),
        geometry: { location, ...geometry },
        ...rest,
      };
    }
    return {
      ...rest,
    };
  });
};

let searchData = [];
let searchValue = '';
export const getPlaceSearch = ({
  position = {},
  positionIP = {},
  radius,
  type,
  callBack,
  value,
}) => {
  // eslint-disable-next-line no-undef
  const service = new google.maps.places.PlacesService(createAttrDiv('div-attr-search'));

  service.textSearch(
    {
      // eslint-disable-next-line no-undef
      location: new google.maps.LatLng(
        (position || {}).latitude || positionIP?.latitude,
        (position || {}).longitude || positionIP?.longitude,
      ),
      radius,
      type,
      // eslint-disable-next-line no-undef
      rankBy: radius ? undefined : google.maps.places.RankBy.DISTANCE,
      query: value,
    },
    (results, status, pagination) => {
      if (status !== 'OK') return;
      if (value !== searchValue) searchData = [];
      searchValue = value;
      searchData = uniqBy([...searchData, ...results], 'place_id');
      let resultsOverride = searchData;
      if ((position || {}).latitude && (position || {}).longitude) {
        resultsOverride = arrayWithDistance(resultsOverride, position);
      }

      callBack({
        results: resultsOverride,
        status,
        pagination,
      });
    },
  );
};

export const getCurrentPosition = (geoSuccess, geoError) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
  } else {
    geoError();
  }
};

let nearByData = [];
export const getNearByPlaces = ({ position: { latitude, longitude }, radius, type, callBack }) => {
  // eslint-disable-next-line no-undef
  const service = new google.maps.places.PlacesService(createAttrDiv('div-attr-near-by'));
  service.nearbySearch(
    {
      location: { lat: latitude, lng: longitude },
      radius,
      type,
      // eslint-disable-next-line no-undef
      rankBy: radius ? undefined : google.maps.places.RankBy.DISTANCE,
    },
    (results, status, pagination) => {
      if (status !== 'OK') return;
      nearByData = uniqBy([...nearByData, ...results], 'place_id');
      let resultsOverride = nearByData;
      if (latitude && longitude) {
        resultsOverride = arrayWithDistance(resultsOverride, { latitude, longitude });
      }

      callBack({
        results: resultsOverride,
        status,
        pagination,
      });
    },
  );
};

export const checkGeolocationAvailability = (callBack) => {
  if (navigator.permissions) {
    navigator.permissions.query({ name: 'geolocation' }).then((PermissionStatus) => {
      if (PermissionStatus.state === 'denied') {
        callBack(false);
      } else {
        callBack(true);
      }
    });
  } else if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      () => {
        callBack(true);
      },
      () => {
        callBack(false);
      },
    );
  }
};
