import { createActions } from 'redux-actions';

const collection = 'soa';

export const {
  createRequest,
  createResponse,
  flowRequest,
  flowResponse,
  imageUploadRequest,
  imageLabelUploadResponse,
  imageProductUploadResponse,
  marketplaceRequest,
  marketplaceResponse,
  addMarketplaceRequest,
  addMarketplaceResponse,
  editMarketplaceRequest,
  editMarketplaceResponse,
  resetSoa,
  getMarketplaceSoaRequest,
  getMarketplaceSoaResponse,
  addMarketplaceSoaRequest,
  removeMarketplaceSoaRequest,
  downloadSoaRequest,
  downloadSoaResponse,
  getSoaRequest,
  getSoaResponse,
} = createActions(
  {
    CREATE_REQUEST: ({ authenticationId, channel, redirect }) => ({
      authenticationId,
      channel,
      redirect,
    }),
    CREATE_RESPONSE: (data) => ({ ...data }),
    FLOW_REQUEST: ({ params, action, authenticationId, id, status, page, data }) => ({
      params,
      action,
      authenticationId,
      id,
      status,
      page,
      data,
    }),
    FLOW_RESPONSE: (data) => ({ ...data }),
    IMAGE_UPLOAD_REQUEST: ({ soaId, type, file, nextOption }) => ({
      soaId,
      type,
      file,
      nextOption,
    }),
    IMAGE_LABEL_UPLOAD_RESPONSE: (data) => ({ ...data }),
    IMAGE_PRODUCT_UPLOAD_RESPONSE: (data) => ({ ...data }),
    MARKETPLACE_REQUEST: () => ({}),
    MARKETPLACE_RESPONSE: (data) => data,
    ADD_MARKETPLACE_REQUEST: ({ data }) => ({
      data,
    }),
    ADD_MARKETPLACE_RESPONSE: (data) => data,
    EDIT_MARKETPLACE_REQUEST: ({ data }) => ({
      data,
    }),
    EDIT_MARKETPLACE_RESPONSE: (data) => data,
    ADD_MARKETPLACE_SOA_REQUEST: ({ alias, marketplaceId, soaId }) => ({
      alias,
      marketplaceId,
      soaId,
    }),
    REMOVE_MARKETPLACE_SOA_REQUEST: ({ aliasId, soaId }) => ({
      aliasId,
      soaId,
    }),
    RESET_SOA: () => null,
    GET_MARKETPLACE_SOA_REQUEST: (id) => ({ id }),
    GET_MARKETPLACE_SOA_RESPONSE: (data) => data,
    DOWNLOAD_SOA_REQUEST: (id) => ({ id }),
    DOWNLOAD_SOA_RESPONSE: (data) => data,
    GET_SOA_REQUEST: (id) => ({ id }),
    GET_SOA_RESPONSE: (data) => data,
  },
  { prefix: collection },
);
