import { createSelector } from 'reselect';
import get from 'lodash/get';

const selectProxyDomain = () => (state) => state.proxy;

export const selectProxyLoading = createSelector(selectProxyDomain(), (state) =>
  get(state, 'loading'),
);

export const selectProxyData = createSelector(selectProxyDomain(), (state) => get(state, 'data'));

export const selectProxySession = createSelector(selectProxyDomain(), (state) =>
  get(state, 'data.session'),
);
