import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { addMarketplaceResponse } from '../actions';

export default function* addMarketplace({ payload: { data } }) {
  const url = 'users/v1/seller/addStore';

  try {
    const response = yield call(fetchWrapper, {
      url,
      method: 'POST',
      params: data,
    });

    yield put(addMarketplaceResponse(response));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
