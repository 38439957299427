import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { resellWithEbayResponse } from '../actions';

export default function* flow({ payload: { id, action, status, data } }) {
  // const url = 'resell-with-ebay/v1/flow/:id';
  const url = 'resell-with-ebay/v1/flow/:id';

  try {
    const response = yield call(fetchWrapper, {
      method: 'PUT',
      url: url.replace(':id', id),
      params: {
        action,
        status,
        data,
      },
    });

    yield put(push(`/resell-with-ebay/${response?.id}/${response?.status}`));
    yield put(resellWithEbayResponse(response));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
