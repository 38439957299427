import { getBaseObject } from './utils';

const genericEvent = (eventDef) => (action, prevState, nextState) => {
  const { skipEvent, ...event } = eventDef(action, prevState, nextState);

  if (skipEvent) return null;

  const baseObject = getBaseObject({ action, prevState, nextState });
  return {
    ...baseObject,
    event: 'GAevent',
    method: null,
    menu_label: null,
    language_from: null,
    language_to: null,
    brand: null,
    when: null,
    where: null,
    status: null,
    click: null,
    ...event,
  };
};

export default genericEvent;
