/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import config from 'config';
import { makeSelectLocale } from './selectors';

const getLocaleMessages = (hostname, locale) => {
  return new Promise((resolve, reject) => {
    fetch(`${hostname}/${locale}.json?t=${Date.now()}`)
      .then((response) => response.json())
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};
export function LanguageProvider({ messages: _messages, children }) {
  const locale = useSelector(makeSelectLocale);
  const [messages, setMessages] = useState(_messages[locale]);
  useEffect(() => {
    getLocaleMessages(config.localesUrl, locale)
      .then((localeMessages) => setMessages(localeMessages))
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('An error occurred retrieving locales.');
      });
  }, [locale]);

  return (
    <IntlProvider locale={locale} key={locale} messages={messages || {}} textComponent="span">
      {React.Children.only(children)}
    </IntlProvider>
  );
}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

export default LanguageProvider;
