import { call, put } from 'redux-saga/effects';
import { getGeocodeDetails } from 'utils/Api/geolocation';
// import { push } from 'connected-react-router';
import { setSelectedPlace } from '../actions';

export default function* getPlaceDetails({ payload: { placeId } }) {
  try {
    const details = yield call(getGeocodeDetails, { placeId });

    yield put(setSelectedPlace({ details }));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
