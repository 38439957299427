import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  createRequest,
  createResponse,
  updateRequest,
  updateResponse,
  downloadFakeReportRequest,
  downloadFakeReportResponse,
} from './actions';

export const initialState = {
  loading: true,
  data: null,
  downloadLoading: true,
  download: null,
};

const mutableReducer = handleActions(
  {
    [createRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [createResponse]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload;
    }),
    [updateRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [updateResponse]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload;
    }),
    [downloadFakeReportRequest]: produce((draft) => {
      draft.downloadLoading = true;
    }),
    [downloadFakeReportResponse]: produce((draft) => {
      draft.downloadLoading = false;
    }),
  },
  initialState,
);

export default mutableReducer;
