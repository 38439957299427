export const PAGE_TYPES = {
  default: 'default',
  clgFeature: 'clgFeature',
  whitelabel: 'whitelabel',
  null: null,
};

export default {
  authInfo: PAGE_TYPES.default,
  authProfile: PAGE_TYPES.clgFeature,
  authentication: PAGE_TYPES.null,
  connect: PAGE_TYPES.whitelabel,
  soa: PAGE_TYPES.clgFeature,
  fakeReport: PAGE_TYPES.whitelabel,
  warranty: PAGE_TYPES.whitelabel,
  resellWithEbay: PAGE_TYPES.whitelabel,
  claimOwnership: PAGE_TYPES.whitelabel,
};
