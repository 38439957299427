import { select } from 'redux-saga/effects';
// import {
//   refreshTokenRequest,
//   refreshTokenSuccess,
//   refreshTokenFail,
//   logoutRequest,
// } from 'containers/Auth/actions';
import { selectJwt } from 'containers/Auth/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
// import jwtDecode from 'jwt-decode';

export default function* getHeaders({ noCheckJWT }) {
  const headers = {};
  headers.locale = yield select(makeSelectLocale);
  const jwtToken = yield select(selectJwt);
  if (!noCheckJWT && jwtToken) {
    headers.jwtToken = jwtToken;
    // const oldToken = jwtDecode(jwtToken);
    // const expMillis = oldToken.exp * 1000;
    // if (expMillis < Date.now()) {
    //   yield put(refreshTokenRequest());
    //   const { success, fail } = yield race({
    //     success: take(refreshTokenSuccess),
    //     fail: take(refreshTokenFail),
    //   });
    //   // Success call ApiWrapper
    //   if (success) headers = { ...headers, jwtToken: success.data.accessToken };
    //   else if (fail) yield put(logoutRequest());
  }
  return headers;
}
