import { takeLatest, all } from 'redux-saga/effects';
import { createRequest, imageUploadRequest, updateRequest } from '../actions';
import create from './create';
import update from './update';
import imageUpload from './imageUpload';

export default function* rootSaga() {
  yield all([takeLatest(createRequest, create)]);
  yield all([takeLatest(imageUploadRequest, imageUpload)]);
  yield all([takeLatest(updateRequest, update)]);
}
