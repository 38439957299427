
import styled, { css } from 'styled-components';
import { Div, Loader as _loader } from '@ebay-certilogo/design-system-web';

const Loader = styled(_loader).attrs(({ size }) => ({
  width: size,
  height: size,
}))`
  color: #000000;
  ${({ size = 40 }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
`;

const Wrapper = styled(Div)`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  flex: 1;
  display: flex;
  background: #ffffff;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const LoaderWrapper = styled(Div)`
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
`;

function AppLoader() {
  return (
    <Wrapper colorType="dark">
      <LoaderWrapper>
        <Loader colorType="light" size={40} />
      </LoaderWrapper>
    </Wrapper>
  );
}

export default AppLoader;
