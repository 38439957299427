import { takeLatest, all } from 'redux-saga/effects';
import {
  createRequest,
  flowRequest,
  imageUploadRequest,
  marketplaceRequest,
  addMarketplaceRequest,
  editMarketplaceRequest,
  getMarketplaceSoaRequest,
  addMarketplaceSoaRequest,
  removeMarketplaceSoaRequest,
  downloadSoaRequest,
  getSoaRequest,
} from '../actions';
import create from './create';
import flow from './flow';
import imageUpload from './imageUpload';
import marketplace from './marketplace';
import addMarketplace from './addMarketplace';
import editMarketplace from './editMarketplace';
import getMarketplaceSoa from './getMarketplaceSoa';
import removeMarketplaceSoa from './removeMarketplaceSoa';
import addMarketplaceSoa from './addMarketplaceSoa';
import download from './download';
import getSoa from './getSoa';

export default function* rootSaga() {
  yield all([takeLatest(createRequest, create)]);
  yield all([takeLatest(flowRequest, flow)]);
  yield all([takeLatest(imageUploadRequest, imageUpload)]);
  yield all([takeLatest(marketplaceRequest, marketplace)]);
  yield all([takeLatest(addMarketplaceRequest, addMarketplace)]);
  yield all([takeLatest(addMarketplaceSoaRequest, addMarketplaceSoa)]);
  yield all([takeLatest(removeMarketplaceSoaRequest, removeMarketplaceSoa)]);
  yield all([takeLatest(editMarketplaceRequest, editMarketplace)]);
  yield all([takeLatest(getMarketplaceSoaRequest, getMarketplaceSoa)]);
  yield all([takeLatest(downloadSoaRequest, download)]);
  yield all([takeLatest(getSoaRequest, getSoa)]);
}
