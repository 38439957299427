import { call, put, select } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import {
  selectRedirectErrorState,
  selectRedirectErrorUrl,
  selectRedirectState,
  selectRedirectUrl,
} from '../selectors';
import { profileResponse, setRedirect } from '../actions';

export default function* setProfileEbay({ payload: { code, feature, redirect } }) {
  const url = `users/v3/profile/ebay/`;
  const redirectUrl = yield select(selectRedirectUrl);
  const redirectState = yield select(selectRedirectState);
  const redirectErrorUrl = yield select(selectRedirectErrorUrl);
  const redirectErrorState = yield select(selectRedirectErrorState);

  try {
    const response = yield call(fetchWrapper, {
      url,
      method: 'PUT',
      withQuery: true,
      params: {
        code,
        featureName: feature,
      },
    });

    yield put(profileResponse({ data: response }));
    if (redirect) {
      yield put(push(`${redirectUrl || '/'}`, redirectState));
      yield put(setRedirect(null));
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);

    if (redirect) {
      yield put(push(`${redirectErrorUrl || '/'}`, redirectErrorState));
      yield put(setRedirect(null));
    }
  }
}
