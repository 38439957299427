import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, CompleteBackground } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import { unsetBack } from 'containers/App/actions';
import ButtonComponent from 'components/ButtonComponent';
import { useParams } from 'react-router-dom';
import useColorType from 'components/hooks/useColorType';
import { Title, Text } from './styles';
import messages from './messages';

function Sold() {
  const colorType = useColorType('soaSold');
  const { authenticationId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(unsetBack());
  }, []);
  return (
    <CompleteBackground>
      <Title textAlign="center" type="big" colorType={colorType} marginBottom="m">
        <FormattedMessage {...messages.done} />
      </Title>
      <Title textAlign="center" type="h1" colorType={colorType} marginBottom="m">
        <FormattedMessage {...messages.sealClosed} />
      </Title>
      <Text textAlign="center" colorType={colorType} marginBottom="m">
        <FormattedMessage {...messages.caption} />
      </Text>
      <ButtonComponent
        component={Button}
        buttonType="link"
        arrow={false}
        colorType={colorType}
        to={`/authentication/${authenticationId}`}
      >
        <FormattedMessage {...messages.linkResponse} />
      </ButtonComponent>
    </CompleteBackground>
  );
}

Sold.propTypes = {
  colorType: PropTypes.string,
};

export default Sold;
