import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { claimOwnershipResponse } from '../actions';

export default function* get({ payload: { id } }) {
  // const url = 'claim-ownership/v1/:id';
  const url = 'claim-ownership/v1/:id';

  try {
    const response = yield call(fetchWrapper, {
      method: 'GET',
      url: url.replace(':id', id),
    });
    yield put(push(`/claim-ownership/${response?.id}/${response?.status}`));
    yield put(claimOwnershipResponse(response));
  } catch (err) {
    console.log(err);
  }
}
