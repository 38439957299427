import { createSelector } from 'reselect';
import get from 'lodash/get';

const selectConnectDomain = () => (state) => state.connect;

export const selectConnectLoading = createSelector(selectConnectDomain(), (state) =>
  get(state, 'loading'),
);

export const selectConnectData = createSelector(selectConnectDomain(), (state) =>
  get(state, 'data'),
);
