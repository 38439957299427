
import PropTypes from 'prop-types';
import { Layout, Div } from '@ebay-certilogo/design-system-web';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  ChevronLeft,
  Menu as MenuIcon,
} from '@ebay-certilogo/design-system-web/dist/components/icons';
import Image from 'components/cms/dataTypes/Image';
import {
  selectMenuVisible,
  selectLogo,
  selectHeaderUrl,
  selectGlobalBack,
  selectMenuIsOpen,
} from 'containers/App/selectors';
import {
  back as backAction,
  closeMenu as gtmCloseMenu,
} from 'utils/reduxBeaconGtm/actions/generic';
import Menu from '../Menu';
import { openMenu, closeMenu, setMenu } from '../../actions';

const { Header: ClgHeader, Sidebar } = Layout;

const TopLogoLink = styled(Link)`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  max-width: 120px;
  ${Image} {
    height: 100%;
  }
`;

function Header({ colorType, contentRef }) {
  const dispatch = useDispatch();
  const menuVisible = useSelector(selectMenuVisible);
  const logo = useSelector(selectLogo);
  const headerUrl = useSelector(selectHeaderUrl);
  const back = useSelector(selectGlobalBack);
  const sidebarOpen = useSelector(selectMenuIsOpen);
  const history = useHistory();

  const toggleSidebar = (value) => {
    if (value) dispatch(openMenu());
    else if (value === false) dispatch(closeMenu());
    else dispatch(setMenu(!sidebarOpen));
  };

  const backClick = () => {
    if (back === 'history') {
      history.goBack();
    } else if (back.type && back.type === 'history') {
      history.goBack();
      dispatch(back.callback);
    } else dispatch(back);
    dispatch(backAction());
  };

  return (
    <>
      <ClgHeader
        colorType={colorType}
        left={
          <Div>
            {back && (
              <ChevronLeft onClick={backClick} colorType={colorType} width={33} height={33} />
            )}
          </Div>
        }
        right={
          <Div>{menuVisible && <MenuIcon onClick={toggleSidebar} colorType={colorType} />}</Div>
        }
        center={
          <TopLogoLink to={headerUrl}>{logo && <Image image={logo} alt="logo" />}</TopLogoLink>
        }
        scrollRef={contentRef}
      />
      <Sidebar
        colorType={colorType}
        isOpen={sidebarOpen}
        onClose={() => {
          toggleSidebar(false);
          dispatch(gtmCloseMenu());
        }}
      >
        <Menu colorType={colorType} />
      </Sidebar>
    </>
  );
}

Header.propTypes = {
  colorType: PropTypes.string,
  contentRef: PropTypes.object,
};

export default Header;
