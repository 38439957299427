import styled from 'styled-components';
import { Flex, Image as _image } from '@ebay-certilogo/design-system-web';
import { margin } from '@ebay-certilogo/design-system-web/dist/shared/styles';

export const Wrapper = styled(Flex)`
  height: 100%;
  flex-direction: column;
`;

export const Body = styled(Flex)`
  margin: auto;
  justify-items: center;
  display: flex;
  flex-direction: column;
`;

export const Image = styled(_image)`
  margin: auto;
  width: auto;
  height: 70px;
  ${margin('m', 'bottom')}
`;
