import { call, put, select } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { selectRedirectState, selectRedirectUrl } from '../selectors';
import { profileResponse, setRedirect } from '../actions';

export default function* setProfile({
  payload: { email, firstName, lastName, birthdate, gender, redirect, signup },
}) {
  const url = `users/v2/profile/`;
  const redirectUrl = yield select(selectRedirectUrl);
  const redirectState = yield select(selectRedirectState);

  try {
    const response = yield call(fetchWrapper, {
      url,
      method: 'PUT',
      params: {
        email,
        firstName,
        lastName,
        birthdate,
        gender,
      },
    });

    yield put(profileResponse({ data: response, signup }));
    if (redirect) {
      yield put(push(`${redirectUrl || '/'}`, redirectState));
      yield put(setRedirect(null));
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
