import React, { useEffect } from 'react';
import { Flex } from '@ebay-certilogo/design-system-web';
import LoadingIndicator from 'components/LoadingIndicator';
import { selectQueryParams } from 'containers/App/selectors';
import {
  loginRequest,
  setProfileEbayRequest,
  setRedirect,
  setRedirectError,
} from 'containers/Auth/actions';
import {
  selectEbayOauthFeature,
  selectIsAuthenticated,
  selectRedirectErrorState,
  selectRedirectErrorUrl,
} from 'containers/Auth/selectors';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import useColorType from 'components/hooks/useColorType';
import { push } from 'connected-react-router';

export const Wrapper = styled(Flex)`
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

function Callback() {
  const colorType = useColorType('auth');
  const dispatch = useDispatch();
  const params = useSelector(selectQueryParams);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const redirectErrorUrl = useSelector(selectRedirectErrorUrl);
  const redirectErrorState = useSelector(selectRedirectErrorState);
  const ebayOauthFeature = useSelector(selectEbayOauthFeature);

  useEffect(() => {
    if (params?.code) {
      if (isAuthenticated) {
        dispatch(
          setProfileEbayRequest({
            code: params.code,
            feature: ebayOauthFeature,
            redirect: true,
          }),
        );
      } else {
        dispatch(
          loginRequest({
            network: 'ebay',
            code: params?.code,
            feature: ebayOauthFeature,
            redirect: true,
          }),
        );
      }
    } else if (params?.error) {
      if (redirectErrorUrl) {
        dispatch(push(redirectErrorUrl, redirectErrorState));
        dispatch(setRedirect(null));
        dispatch(setRedirectError(null));
      } else {
        dispatch(push('/auth/sign-up'));
      }
    }
  }, [params]);

  return <LoadingIndicator colorType={colorType} />;
}

Callback.propTypes = {
  setError: PropTypes.func,
};

export default Callback;
