import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { getBrandDomainName, isBrandedDomain } from 'utils/utils';
import { changeEmailResponse, loginResponse } from '../actions';

export default function* changeEmail({ payload: { email } }) {
  const url = `users/v2/confirm/resend`;

  try {
    const response = yield call(fetchWrapper, {
      url,
      method: 'POST',
      params: {
        email,
        domain: isBrandedDomain() ? getBrandDomainName() : null,
      },
    });
    yield put(changeEmailResponse({}));
    yield put(loginResponse({ data: response }));
  } catch ({ packet, response }) {
    if (response.status >= 400) {
      yield put(changeEmailResponse({ errors: packet.errors || ['confirm_email.error'] }));
    }
  }
}
