import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { CompleteBackground, Button } from '@ebay-certilogo/design-system-web';
import withColorType from 'components/HOC/withColorType';
import { FormattedMessage } from 'react-intl';
import { unsetBack } from 'containers/App/actions';
import ButtonComponent from 'components/ButtonComponent';
import config from 'config';
import { Title, Text } from './styles';
import messages from './messages';

function Opened({ colorType }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(unsetBack());
  }, []);

  return (
    <CompleteBackground>
      <Title type="h1" textAlign="center" colorType={colorType} marginBottom="l">
        <FormattedMessage {...messages.title} />
      </Title>
      <Text textAlign="center" colorType={colorType} marginBottom="m">
        <FormattedMessage {...messages.text} />
      </Text>
      <ButtonComponent
        colorType={colorType}
        target="_blank"
        href={config.discoverUrl}
        component={Button}
      >
        <FormattedMessage {...messages.button1} />
      </ButtonComponent>
    </CompleteBackground>
  );
}

Opened.propTypes = {
  colorType: PropTypes.string,
};

export default withColorType(Opened);
