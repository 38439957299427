import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { addStoreResponse, flowRequest } from '../actions';

export default function* create({ payload: { params, authenticationId, status, soa } }) {
  const url = 'store/v3/create';

  try {
    const { id } = yield call(fetchWrapper, {
      method: 'POST',
      url,
      params,
    });

    yield put(addStoreResponse());
    if (soa) {
      yield put(push('marketplace_alias/insert_alias', { marketplaceId: id }));
    } else {
      yield put(flowRequest({ action: 'next', authenticationId, status, data: { store: id } }));
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
