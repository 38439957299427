import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useParams, Switch, useRouteMatch } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import withColorType from 'components/HOC/withColorType';
import Route from 'components/Route';

import { createRequest } from '../actions';
import saga from '../saga';
import { selectCodeReviewLoading, selectCodeReviewData } from '../selectors';
import Upload from './Upload';
import Opened from './Opened';
import Closed from './Closed';

function Status({ colorType }) {
  useInjectSaga({ key: 'code-review', saga });
  const { path } = useRouteMatch();

  const { token } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(createRequest({ token }));
  }, []);

  const isLoading = useSelector(selectCodeReviewLoading);
  const data = useSelector(selectCodeReviewData);

  if (isLoading || !data) return <LoadingIndicator />;

  return (
    <Switch>
      <Route path={`${path}/upload`}>
        <Upload colorType={colorType} data={data || {}} token={token} />
      </Route>
      <Route path={`${path}/opened`}>
        <Opened data={data || {}} colorType="light" />
      </Route>
      <Route path={`${path}/closed`}>
        <Closed data={data || {}} colorType="light" />
      </Route>
    </Switch>
  );
}

Status.propTypes = {
  colorType: PropTypes.string,
};

export default withColorType(Status);
