import { getBaseObject } from './utils';

const featureLoadEvent = (eventDef) => (action, prevState, nextState) => {
  const event = eventDef(action, prevState, nextState);
  const baseObject = getBaseObject({ action, prevState, nextState });

  return {
    ...baseObject,
    event: 'featureLoad',
    ...event,
  };
};

export default featureLoadEvent;
