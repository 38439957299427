import { defineMessages } from 'react-intl';

const introLaterPopup = defineMessages({
  message: {
    id: 'resell_with_ebay.image_upload.intro.later_popup.message1',
    defaultMessage:
      'This is a mandatory step, take the required photo to create your listing or skip to resume later',
  },
  primaryButton: {
    id: 'resell_with_ebay.image_upload.intro.later_popup.button1',
    defaultMessage: 'Ok, got it',
  },
  secondaryButton: {
    id: 'resell_with_ebay.image_upload.intro.later_popup.text1',
    defaultMessage: 'Skip',
  },
});

const intro = defineMessages({
  title: {
    id: 'resell_with_ebay.image_upload.intro.title1',
    defaultMessage:
      'Take a well-lit, close up photo of the entire TAG showing the CLG Code and a photo of the product',
  },
  buttonStart: {
    id: 'resell_with_ebay.image_upload.intro.button1',
    defaultMessage: 'Take a photo',
  },
  buttonSkip: {
    id: 'resell_with_ebay.image_upload.intro.text1',
    defaultMessage: "I can't do it now",
  },
  laterPopup: introLaterPopup,
});

const cameraHelp = defineMessages({
  title1: {
    id: 'resell_with_ebay.image_upload.camera.help.title1',
    defaultMessage: 'What should I take a photo of?',
  },
  text1: {
    id: 'resell_with_ebay.image_upload.camera.help.text1',
    defaultMessage:
      'The CLG TAG: could be a label OR a sticker OR a card OR a hangtag with the "CLG +12 numbers" on it. Sometimes we might ask you to take a picture of the product, too.<br/><br/>The Product: take a photo showing your item whole and frontal. This photo will be the main photo of your listing.',
  },
  title2: {
    id: 'resell_with_ebay.image_upload.camera.help.title2',
    defaultMessage: 'How to take the perfect picture?',
  },
  text2: {
    id: 'resell_with_ebay.image_upload.camera.help.text2',
    defaultMessage:
      'It is very important that you frame the label or TAG in full, that you pay attention not to cover it in any of its parts and that the photo is clear and well-lit.',
  },
  title3: {
    id: 'resell_with_ebay.image_upload.camera.help.title3',
    defaultMessage: 'What if I cannot take pictures now?',
  },
  text3: {
    id: 'resell_with_ebay.image_upload.camera.help.text3',
    defaultMessage:
      "If you cannot provide them immediately we can't create your listing.<br/>Skip forward so you can complete the process later.",
  },
});

const cameraSkipPopupMessages = defineMessages({
  skipPopup1Message: {
    id: 'resell_with_ebay.upload1_skip_popup.message1',
    defaultMessage:
      'A photo of the product is required to create the listing. Take a photo now or skip to resume later.',
  },
  skipPopup2Message: {
    id: 'resell_with_ebay.upload2_skip_popup.message1',
    defaultMessage:
      'A photo of the label is required to create the listing. Take a photo now or skip to resume later.',
  },
  skipPopup3Message: {
    id: 'resell_with_ebay.upload3_skip_popup.message1',
    defaultMessage:
      'A photo of the product is required to create the listing. Take a photo now or skip to resume later.',
  },
});

const cameraSkipPopup = defineMessages({
  messages: [
    cameraSkipPopupMessages.skipPopup1Message,
    cameraSkipPopupMessages.skipPopup2Message,
    cameraSkipPopupMessages.skipPopup3Message,
  ],
  primaryButton: {
    id: 'upload1_skip_popup.button1',
    defaultMessage: 'Ok, got it',
  },
  secondaryButton: {
    id: 'upload1_skip_popup.text1',
    defaultMessage: 'Skip',
  },
});

const cameraCaptionsLabel = defineMessages({
  caption1Label: {
    id: 'resell_with_ebay.image_upload.camera.frame1.text1',
    defaultMessage: 'Frame the entire label or TAG showing the CLG Code',
  },
  caption2Label: {
    id: 'resell_with_ebay.image_upload.camera.frame2.text1',
    defaultMessage: 'Take a picture of your product to use as the cover for your listing',
  },
});

const cameraCaptions = [
  {
    label: cameraCaptionsLabel.caption1Label,
  },
  {
    label: cameraCaptionsLabel.caption2Label,
  },
];

const cameraSorry = defineMessages({
  title: {
    id: 'upload1_sorry.title1',
    defaultMessage: 'Try again',
  },
  text: {
    id: 'upload1_sorry.text1',
    defaultMessage:
      'The photo cannot be processed.\nTake another one making sure that it is well lit, clear and that the label or TAG is fully visible.',
  },
  button: {
    id: 'upload1_sorry.button1',
    defaultMessage: 'Take new photo',
  },
  link: {
    id: 'upload1_sorry.text2',
    defaultMessage: 'How to take the perfect picture',
  },
});

const cameraPreprocessingTimeoutModal = defineMessages({
  text: {
    id: 'preprocessingTimeout.text',
    defaultMessage:
      'Scan unsuccessful\n<br />\nAlign the TAG and QR code with the guide. Check that the label is flat, well lit and in focus. Hold the camera still and ensure your hands are not covering the label.',
  },
  retry: { id: 'preprocessingTimeout.button', defaultMessage: 'RETRY' },
  skip: { id: 'preprocessingTimeout.link', defaultMessage: 'I cannot find the TAG' },
});

const camera = defineMessages({
  confirm: {
    id: 'resell_with_ebay.image_upload.camera.confirm',
    defaultMessage: 'Confirm',
  },
  retake: {
    id: 'resell_with_ebay.image_upload.camera.retake',
    defaultMessage: 'Retake',
  },
  progress: {
    id: 'resell_with_ebay.image_upload.camera.progress',
    defaultMessage: 'Processing and uploading photo',
  },
  skip: {
    id: 'resell_with_ebay.image_upload.camera.skip',
    defaultMessage: 'Skip',
  },
  help: cameraHelp,
  skipPopup: cameraSkipPopup,
  captions: cameraCaptions,
  sorry: cameraSorry,
  preprocessingTimeoutModal: cameraPreprocessingTimeoutModal,
});

export default defineMessages({
  intro,
  camera,
});
