
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, ModalAssistant, Heading, Text, Div } from '@ebay-certilogo/design-system-web';
import messages from './messages';

const Wrapper = styled(Div)``;

function ImportantModal({ colorType, setIsOpen, isOpen, handleSubmit, values }) {
  return (
    <ModalAssistant
      colorType={colorType}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      text={
        <Wrapper>
          <Heading
            themeField="modalAssistant.color"
            type="h2"
            margin={{ size: 's', position: 'bottom' }}
          >
            <FormattedMessage {...messages.title} />
          </Heading>
          <Text themeField="modalAssistant.color" weight="medium">
            <FormattedMessage {...messages.description} />
          </Text>
        </Wrapper>
      }
      footer={[
        <Button colorType={colorType} onClick={() => handleSubmit(values)} noMargin>
          <FormattedMessage {...messages.continue} />
        </Button>,
        <Button
          buttonType="link"
          arrow={false}
          colorType={colorType}
          onClick={() => setIsOpen(false)}
          noMargin
        >
          <FormattedMessage {...messages.back} />
        </Button>,
      ]}
    />
  );
}

ImportantModal.propTypes = {
  colorType: PropTypes.string,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
};

export default ImportantModal;
