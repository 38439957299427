import { createActions } from 'redux-actions';

const collection = 'proxy';

export const { serviceRequest, serviceResponse, resetProxy } = createActions(
  {
    SERVICE_REQUEST: (data) => ({ ...data }),
    SERVICE_RESPONSE: (data, pathname) => ({ pathname, ...data }),
    RESET_PROXY: () => ({}),
  },
  { prefix: collection },
);
