import produce from 'immer';
import { handleActions } from 'redux-actions';
import { getRequest, getResponse, sendRequest, sendResponse } from './actions';

export const initialState = {
  loading: true,
  data: {},
  sendLoading: false,
};

const mutableReducer = handleActions(
  {
    [getRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [getResponse]: produce((draft, { payload: { id, service, data } }) => {
      draft.loading = false;
      draft.data[`${id}${service}`] = data;
    }),
    [sendRequest]: produce((draft) => {
      draft.sendLoading = true;
    }),
    [sendResponse]: produce((draft) => {
      draft.sendLoading = false;
    }),
  },
  initialState,
);

export default mutableReducer;
