import {
  primaryAccentColor,
  primaryColor,
  secondaryColor,
} from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import styled from 'styled-components';
import {
  Div,
  Camera as _Camera,
  Image,
  Flex,
  ProgressBarTimer,
  ButtonSimple,
} from '@ebay-certilogo/design-system-web';
import {
  contentWrapper,
  margin,
  padding,
} from '@ebay-certilogo/design-system-web/dist/shared/styles';
import ImageComponent from 'components/Image';
import Svg from 'components/Svg';
import shotSvg from './assets/shot.svg';
import librarySvg from './assets/library.svg';
import helpSvg from './assets/help.svg';

export const Camera = styled(_Camera)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${secondaryColor};
  z-index: 3;
  flex: 1;
`;

export const Overlay = styled(Div)`
  z-index: 3;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Header = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  ${padding('s')}
`;

export const Body = styled(Div)`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 280px;
  align-self: center;
  ${padding('s')}
`;

export const Thumbnails = styled(Flex)`
  justify-content: center;
  ${margin('m', 'bottom')}
`;

export const ThumbnailWrapper = styled(Div)`
  width: 35px;
  height: 35px;
  border: 2px solid ${primaryColor};
  ${margin('s', 'horizontal')}
`;

export const Thumbnail = styled(ImageComponent)`
  height: 100%;
  object-fit: cover;
`;

export const FrameWrapper = styled(Div)`
  position: relative;
  width: 280px;
  height: 280px;
  ${margin('m', 'bottom')}
`;

export const FrameSvg = styled(Svg).attrs({
  width: 280,
  height: 280,
  containerStyle: { width: 280, height: 280 },
})`
  color: ${({ active }) => (active ? primaryAccentColor : primaryColor)};
  height: 280px;
`;

export const ScanAnimationSvg = styled(Svg).attrs({
  width: 280,
  height: 280,
  containerStyle: { width: 280, height: 280 },
})`
  position: absolute;
  color: ${primaryAccentColor};
  height: 280px;
`;

export const Footer = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: ${primaryColor};
  ${padding('s')}
`;

export const ImageWrapper = styled(Div)`
  display: flex;
  justify-content: center;
  height: 280px;
  width: 280px;
  overflow: hidden;
`;

export const ImagePreview = styled(Image)`
  height: fit-content;
  width: auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
`;

export const ModalBody = styled(Flex)`
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${contentWrapper}
`;

export const ProgressBar = styled(ProgressBarTimer).attrs({
  themeField: 'primaryAccentColor',
})`
  ${margin('l', 'top')}
  ${margin('s', 'bottom')}
`;

export const Shot = styled(Svg).attrs({
  src: shotSvg,
  source: shotSvg,
  width: 30,
  height: 30,
  containerStyle: { width: 70, height: 70 },
})`
  color: ${primaryColor};
  height: 70px;
  width: 70px;
`;

export const Library = styled(Svg).attrs({
  src: librarySvg,
  source: librarySvg,
  width: 30,
  height: 30,
  containerStyle: { width: 45, height: 45 },
})`
  color: ${primaryColor};
  height: 45px;
  width: 45px;
`;

export const Help = styled(Svg).attrs({
  src: helpSvg,
  source: helpSvg,
  width: 30,
  height: 30,
  containerStyle: { width: 45, height: 45 },
})`
  color: ${primaryColor};
  height: 45px;
  width: 45px;
`;

export const ButtonFooter = styled(ButtonSimple)`
  color: ${primaryColor};
  width: auto;
  height: auto;
`;
