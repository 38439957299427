import { createSelector } from 'reselect';
import get from 'lodash/get';
import { chain, forEach } from 'lodash';

const selectSoaDomain = () => (state) => state.soa;

export const selectSoaLoading = createSelector(selectSoaDomain(), (state) => get(state, 'loading'));

export const selectSoaData = createSelector(selectSoaDomain(), (state) => get(state, 'data'));

export const selectSoaUploadLoading = createSelector(selectSoaDomain(), (state) =>
  get(state, 'uploadLoading'),
);

export const selectSoaUploadLabels = createSelector(selectSoaDomain(), (state) =>
  get(state, 'uploadLabels'),
);

export const selectSoaProductImage = createSelector(selectSoaDomain(), (state) =>
  get(state, 'productImage'),
);

export const selectUserMarketplace = createSelector(selectSoaDomain(), (state) =>
  get(state, 'marketplace'),
);

export const selectUserMarketplaceLoading = createSelector(selectSoaDomain(), (state) =>
  get(state, 'marketplaceLoading'),
);

export const selectSoaMarketplace = createSelector(selectSoaDomain(), (state) =>
  get(state, 'marketplaceSoa.data'),
);

export const selectSoaMarketplaceLoading = createSelector(selectSoaDomain(), (state) =>
  get(state, 'marketplaceSoa.loading'),
);

export const selectMarketplace = createSelector(selectSoaDomain(), (state) => {
  const marketplace = [];
  const soaMarketplaces = get(state, 'marketplaceSoa.data');
  const userMarketplaces = get(state, 'marketplace');

  forEach(soaMarketplaces, (el) => {
    if (el.enabled) {
      marketplace.push({
        id: el.id,
        name: get(el, 'marketplace.name'),
        alias: el.alias,
        aliasId: el.id,
        marketplaceId: get(el, 'marketplace.id'),
        enabled: el.enabled,
      });
    }
  });

  forEach(userMarketplaces, (el) => {
    if (!el.deleted) {
      marketplace.push({
        id: el.id,
        name: get(el, 'store.name'),
        alias: el.alias,
        aliasId: null,
        marketplaceId: get(el, 'store.id'),
        enabled: el.enabled,
      });
    }
  });

  return chain(marketplace)
    .uniqBy(({ marketplaceId, alias }) => JSON.stringify([marketplaceId, alias]))
    .orderBy(['name', 'alias'])
    .value();
});

export const selectSoaSearchLoading = createSelector(selectSoaDomain(), (state) =>
  get(state, 'searchLoading'),
);

export const selectSoaSearch = createSelector(selectSoaDomain(), (state) => get(state, 'search'));

export const selectDownloadSoaLoading = createSelector(selectSoaDomain(), (state) =>
  get(state, 'downloadSoaLoading'),
);
