import { flowRequest } from 'containers/Authentication/actions';
import { serviceRequest } from 'containers/Proxy/actions';
import { isEmpty } from 'lodash';
import { checkAutenticity, imageUploadTakePicture, optinPrivacy } from '../actions/authentication';
import genericEvent from './definitions/genericEvent';

const flowEvent = ({ payload: { status, action, data: { purpose, bought } = {}, data } }) => ({
  eventName: `auth_${status}`,
  when: purpose,
  where: bought,
  skip: ['retailer_store', 'retailer_offline'].includes(status) && isEmpty(data),
  skipEvent: action !== 'next',
});

const authenticationEvents = {
  [checkAutenticity]: genericEvent(() => ({
    eventName: 'check_autenticity',
  })),
  [serviceRequest]: genericEvent(({ payload: { origin } }) => ({
    eventName: 'clg-insert',
    skipEvent: origin !== 'typed',
  })),
  [flowRequest]: genericEvent(flowEvent),
  [imageUploadTakePicture]: genericEvent(() => ({
    eventName: 'auth_image_upload_take_picture',
  })),
  [optinPrivacy]: genericEvent(() => ({
    eventName: 'auth_optin_privacy',
  })),
};

export default authenticationEvents;
