import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'soa_creation_pictures1.title1',
    defaultMessage: 'Get the Certilogo Seal of Authentication',
  },
  addStore: {
    id: 'soa_creation_add_market.text1',
    defaultMessage: 'Add store',
  },
  editStore: {
    id: 'soa_creation_edit_market.text1',
    defaultMessage: 'Edit store',
  },
  nameOrAddress: {
    id: 'soa_creation_add_market.placeholder1',
    defaultMessage: 'Enter marketplace name or address',
  },
  alias: {
    id: 'soa_creation_add_market.placeholder2',
    defaultMessage: 'Enter alias',
  },
  button: {
    id: 'soa_creation_add_market.button1',
    defaultMessage: 'Go',
  },
});
