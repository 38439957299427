import { createActions } from 'redux-actions';

const collection = 'authentication';

export const {
  getAuthenticationRequest,
  getAuthenticationSilentRequest,
  createAuthenticationRequest,
  flowRequest,
  authenticationResponse,
  getPlacesRequest,
  getPlacesResponse,

  getPlaceDetails,
  setSelectedPlace,

  getStoresRequest,
  getStoresResponse,
  resetMapStores,

  addStoreRequest,
  addStoreResponse,
  resetAuthentication,
  imageUploadRequest,
  imageUploadResponse,
  resetImageUpload,
  imageDeleteRequest,
  imageDeleteResponse,
  imageListRequest,
  imageListResponse,

  getAuthenticationResponseConfRequest,
  getAuthenticationResponseConfResponse,

  getConfirmAuthenticationRequest,
  confirmAuthenticationRequest,
  confirmAuthenticationResponse,

  authenticationResultRequest,

  authenticationGeolocateRequest,
  authenticationGeolocateResponse,
} = createActions(
  {
    GET_AUTHENTICATION_REQUEST: ({ authenticationId, disableRedirect }) => ({
      authenticationId,
      disableRedirect,
    }),
    GET_AUTHENTICATION_SILENT_REQUEST: ({ authenticationId, disableRedirect }) => ({
      authenticationId,
      disableRedirect,
    }),
    CREATE_AUTHENTICATION_REQUEST: ({ sessionId, flow, ...params }) => ({
      sessionId,
      flow,
      ...params,
    }),
    FLOW_REQUEST: ({ action, status, data, authenticationId }) => ({
      action,
      status,
      authenticationId,
      data,
    }),
    AUTHENTICATION_RESPONSE: (data, pathname) => ({ pathname, ...data }),

    GET_PLACES_REQUEST: ({ value }) => ({ value }),
    GET_PLACES_RESPONSE: ({ places }) => places,
    GET_STORES_REQUEST: ({
      map,
      value,
      url,
      online,
      authenticationId,
      neLat,
      neLng,
      swLat,
      swLng,
      latitude,
      longitude,
      geolocation,
    }) => ({
      map,
      value,
      url,
      online,
      authenticationId,
      neLat,
      neLng,
      swLat,
      swLng,
      latitude,
      longitude,
      geolocation,
    }),
    GET_STORES_RESPONSE: ({ stores: { stores }, map, geolocation }) => ({
      stores,
      map,
      geolocation,
    }),

    GET_PLACE_DETAILS: ({ placeId }) => ({ placeId }),
    SET_SELECTED_PLACE: ({ details }) => ({ details }),
    RESET_MAP_STORES: null,

    ADD_STORE_REQUEST: ({ params, authenticationId, status, soa }) => ({
      params,
      authenticationId,
      status,
      soa,
    }),
    ADD_STORE_RESPONSE: () => null,
    RESET_AUTHENTICATION: () => ({}),
    IMAGE_UPLOAD_REQUEST: ({ source, sourceId, file, position, acquisitionType, qrString }) => ({
      file,
      source,
      sourceId,
      position,
      acquisitionType,
      qrString,
    }),
    IMAGE_UPLOAD_RESPONSE: (data) => ({ ...data }),
    RESET_IMAGE_UPLOAD: () => null,
    IMAGE_DELETE_REQUEST: ({ source, sourceId, imageId }) => ({
      source,
      sourceId,
      imageId,
    }),
    IMAGE_DELETE_RESPONSE: (data) => ({ ...data }),
    IMAGE_LIST_REQUEST: ({ source, sourceId }) => ({
      source,
      sourceId,
    }),
    IMAGE_LIST_RESPONSE: (data) => ({ ...data }),

    GET_AUTHENTICATION_RESPONSE_CONF_REQUEST: ({ authenticationId }) => ({
      authenticationId,
    }),
    GET_AUTHENTICATION_RESPONSE_CONF_RESPONSE: (data) => ({ ...data }),

    GET_CONFIRM_AUTHENTICATION_REQUEST: ({ a, u, e, v }) => ({
      a,
      u,
      e,
      v,
    }),
    CONFIRM_AUTHENTICATION_REQUEST: ({ authenticationId, email }) => ({
      authenticationId,
      email,
    }),
    CONFIRM_AUTHENTICATION_RESPONSE: () => ({}),

    AUTHENTICATION_RESULT_REQUEST: ({ hash }) => ({
      hash,
    }),

    AUTHENTICATION_GEOLOCATE_REQUEST: ({ authenticationId, latitude, longitude }) => ({
      authenticationId,
      latitude,
      longitude,
    }),
    AUTHENTICATION_GEOLOCATE_RESPONSE: () => ({}),
  },
  { prefix: collection },
);
