import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeColorType } from 'containers/App/actions';
import { selectColorTypes } from 'containers/App/selectors';

function useColorType(name) {
  const dispatch = useDispatch();
  const colorTypes = useSelector(selectColorTypes);
  const colorType = colorTypes[name];

  useEffect(() => {
    dispatch(changeColorType({ colorType }));
  }, [colorType]);

  return colorType;
}

export default useColorType;
