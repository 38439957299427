import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'authentic2_soa_manage_store.title1',
    defaultMessage: 'Your Seal of Authentication',
  },
  buttonDownload: {
    id: 'authentic2_soa_manage_store.button1',
    defaultMessage: 'Download the Seal',
  },
  pending: {
    id: 'authentic2_soa_pending_store.text1',
    defaultMessage: 'This is a pending request, but you can ...',
  },
  buttonSell: {
    id: 'authentic2_soa_manage_store.text1',
    defaultMessage: 'I have sold this product',
  },
  soldModal: {
    title: {
      id: 'authentic2_soa_sold_popup.text1',
      defaultMessage: 'Important',
    },
    description: {
      id: 'authentic2_soa_sold_popup.text2',
      defaultMessage: "You won't be able to see the seal anymore",
    },
    continue: {
      id: 'authentic2_soa_sold_popup.button1',
      defaultMessage: 'I have sold this product',
    },
    cancel: {
      id: 'authentic2_soa_sold_popup.text3',
      defaultMessage: 'Cancel',
    },
  },
});
