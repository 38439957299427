import get from 'lodash/get';
import { put, select } from 'redux-saga/effects';
import { confRequest } from '../actions';
import { selectConf } from '../selectors';

export default function* confReset() {
  try {
    const conf = yield select(selectConf);
    if (get(conf, 'name') !== 'default') {
      yield put(confRequest('default'));
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
