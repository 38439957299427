import styled from 'styled-components';
import { Div } from '@ebay-certilogo/design-system-web';

const Wrapper = styled(Div)`
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
`;

export default Wrapper;
