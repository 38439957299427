
import PropTypes from 'prop-types';
import withColorType from 'components/HOC/withColorType';
import { Redirect } from 'react-router-dom';
import get from 'lodash/get';

function Closed({ data }) {
  return <Redirect to={`/authentication/${get(data, 'authentication.id')}`} />;
}

Closed.propTypes = {
  data: PropTypes.object,
};

export default withColorType(Closed);
