import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CompleteBackground } from '@ebay-certilogo/design-system-web';
import { selectQueryParams } from 'containers/App/selectors';
import { FormattedMessage } from 'react-intl';
import { unsetBack } from 'containers/App/actions';
import { downloadFakeReportRequest } from 'containers/FakeReport/actions';
import { selectFakeReportDownloadLoading } from 'containers/FakeReport/selectors';
import { useInjectSaga } from 'utils/injectSaga';
import saga from 'containers/FakeReport/saga';
import LoadingIndicator from 'components/LoadingIndicator';
import useColorType from 'components/hooks/useColorType';
import { Title, Text } from './styles';
import messages from './messages';

function Download() {
  const colorType = useColorType('fakeReportDownload');
  useInjectSaga({ key: 'fakeReport', saga });
  const dispatch = useDispatch();
  const { token } = useSelector(selectQueryParams);
  const isLoading = useSelector(selectFakeReportDownloadLoading);

  useEffect(() => {
    dispatch(unsetBack());
  }, []);
  useEffect(() => {
    if (token) {
      setTimeout(() => dispatch(downloadFakeReportRequest({ token })), 1);
    }
  }, []);

  if (isLoading) return <LoadingIndicator />;

  return (
    <CompleteBackground>
      <Title type="big" textAlign="center" colorType={colorType}>
        <FormattedMessage {...messages.done} />
      </Title>
      <Title type="h1" textAlign="center" colorType={colorType}>
        <FormattedMessage {...messages.downloadComplete} />
      </Title>
      <Text textAlign="center" colorType={colorType}>
        <FormattedMessage {...messages.description} />
      </Text>
    </CompleteBackground>
  );
}

Download.propTypes = {
  colorType: PropTypes.string,
};

export default Download;
