import { createMetaReducer, createMiddleware } from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import events from './events';

// Create or import an events map.
// See "getting started" pages for instructions.

const gtm = GoogleTagManager();

export const gtmMiddleware = createMiddleware(events, gtm);
export const gtmMetaReducer = createMetaReducer(events, gtm);
