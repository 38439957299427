import styled from 'styled-components';
import { Div } from '@ebay-certilogo/design-system-web';

export const Wrapper = styled(Div).attrs({ marginHorizontal: 'm' })`
  position: fixed;
  margin-top: 50%;
  text-align: center;
`;

export default {
  Wrapper,
};
