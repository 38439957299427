import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'soa_creation_marketplace_popup.message1',
    defaultMessage: 'Important!',
  },
  description: {
    id: 'soa_creation_marketplace_popup.message2',
    defaultMessage:
      'Make sure you list every marketplace you plan to use. otherwise, your buyers will not be able to authenticate your product.',
  },
  continue: {
    id: 'soa_creation_marketplace_popup.button1',
    defaultMessage: 'Continue',
  },
  back: {
    id: 'soa_creation_marketplace_popup.button2',
    defaultMessage: 'Back',
  },
});
