import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';
import { codeLoginResponse } from '../actions';

export default function* codeLoginRequest({ payload: { email } }) {
  const url = `users/v2/code-login/request`;

  try {
    const data = yield call(fetchWrapper, {
      url,
      method: 'POST',
      params: {
        email,
      },
    });

    yield put(codeLoginResponse({ email, data, timeToWait: data.timeToWait }));
    yield put(push(`/auth/code-login`));
  } catch ({ packet, response }) {
    yield put(
      codeLoginResponse({
        email,
        error: packet.error || 'invalid',
        timeToWait: packet.timeToWait,
      }),
    );
  }
}
