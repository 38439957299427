import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  getAuthenticationRequest,
  createAuthenticationRequest,
  flowRequest,
  authenticationResponse,
  getPlacesRequest,
  getPlacesResponse,
  getPlaceDetails,
  setSelectedPlace,
  getStoresRequest,
  getStoresResponse,
  resetMapStores,
  addStoreRequest,
  addStoreResponse,
  resetAuthentication,
  imageUploadRequest,
  imageUploadResponse,
  getAuthenticationResponseConfRequest,
  getAuthenticationResponseConfResponse,
  confirmAuthenticationRequest,
  confirmAuthenticationResponse,
  getConfirmAuthenticationRequest,
  resetImageUpload,
  authenticationResultRequest,
  authenticationGeolocateRequest,
  authenticationGeolocateResponse,
  imageDeleteRequest,
  imageDeleteResponse,
  imageListResponse,
  imageListRequest,
} from './actions';

export const initialState = {
  loading: false,
  data: null,
  confirmAuthenticationLoading: false,
  placesLoading: false,
  places: null,
  storesLoading: false,
  stores: null,
  mapStores: null,
  geolocationStores: null,
  mapLoading: false,
  selectedPlace: null,
  addStoreLoading: false,
  imageUpload: {
    loading: false,
    data: [],
  },
  authenticationResponseConf: {
    loading: false,
    data: null,
  },
  geolocateLoading: false,
};

const mutableReducer = handleActions(
  {
    [getAuthenticationRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [createAuthenticationRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [flowRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [authenticationResponse]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload;
    }),
    [getPlacesRequest]: produce((draft) => {
      draft.placesLoading = true;
    }),
    [getPlacesResponse]: produce((draft, { payload }) => {
      draft.placesLoading = false;
      draft.places = payload;
    }),
    [getStoresRequest]: produce((draft) => {
      draft.storesLoading = true;
    }),
    [getStoresResponse]: produce((draft, { payload: { stores, map, geolocation } }) => {
      draft.storesLoading = false;
      if (map) draft.mapStores = stores;
      else if (geolocation) draft.geolocationStores = stores;
      else draft.stores = stores;
    }),
    [resetMapStores]: produce((draft) => {
      draft.mapStores = null;
    }),
    [getPlaceDetails]: produce((draft) => {
      draft.mapLoading = true;
    }),
    [setSelectedPlace]: produce((draft, { payload: { details } }) => {
      draft.mapLoading = false;
      draft.selectedPlace = details;
    }),
    [addStoreRequest]: produce((draft) => {
      draft.addStoreLoading = true;
    }),
    [addStoreResponse]: produce((draft) => {
      draft.addStoreLoading = false;
    }),
    [resetAuthentication]: produce(() => {
      return initialState;
    }),
    [imageUploadRequest]: produce((draft) => {
      draft.imageUpload.loading = true;
    }),
    [imageUploadResponse]: produce((draft, { payload }) => {
      draft.imageUpload.loading = false;
      draft.imageUpload.data[payload.position || 0] = payload;
    }),
    [imageDeleteRequest]: produce((draft) => {
      draft.imageUpload.loading = true;
    }),
    [imageDeleteResponse]: produce((draft) => {
      draft.imageUpload.loading = false;
    }),
    [imageListRequest]: produce(() => {}),
    [imageListResponse]: produce((draft, { payload: { data } }) => {
      draft.imageUpload.data = data;
    }),
    [resetImageUpload]: produce((draft) => {
      draft.imageUpload.loading = false;
      draft.imageUpload.data = [];
    }),
    [getAuthenticationResponseConfRequest]: produce((draft) => {
      draft.authenticationResponseConf.loading = true;
    }),
    [getAuthenticationResponseConfResponse]: produce((draft, { payload }) => {
      draft.authenticationResponseConf.loading = false;
      draft.authenticationResponseConf.data = payload;
    }),
    [getConfirmAuthenticationRequest]: produce((draft) => {
      draft.confirmAuthenticationLoading = true;
    }),
    [confirmAuthenticationRequest]: produce((draft) => {
      draft.confirmAuthenticationLoading = true;
    }),
    [confirmAuthenticationResponse]: produce((draft) => {
      draft.confirmAuthenticationLoading = false;
    }),
    [authenticationResultRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [authenticationGeolocateRequest]: produce((draft) => {
      draft.geolocateLoading = true;
    }),
    [authenticationGeolocateResponse]: produce((draft) => {
      draft.geolocateLoading = false;
    }),
  },
  initialState,
);

export default mutableReducer;
