import { put, select } from 'redux-saga/effects';
import get from 'lodash/get';
import { setRedirect } from 'containers/Auth/actions';
import { selectIsAuthenticated } from 'containers/Auth/selectors';

export default function* authenticationResponse({ payload: { authentication, pathname } }) {
  const isAuthenticated = yield select(selectIsAuthenticated);

  try {
    if (!isAuthenticated && get(authentication, 'props.authorization.required')) {
      yield put(setRedirect(pathname));
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
