
import { Button, Box, FormFormik } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { imageUploadRequest, updateRequest } from 'containers/CodeReview/actions';
import { Formik, Field } from 'formik';
import Form from 'components/form/FormComponent';
import * as Yup from 'yup';
import FormikAutoSave from 'components/FormikAutoSave';
import {
  selectCodeReviewUploadLoading,
  selectCodeReviewUploadImages,
} from 'containers/CodeReview/selectors';
import { isEmpty } from 'lodash';
import messages from './messages';

const { FileUpload } = FormFormik;

const FILE_SIZE = 14999999;

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const validationSchema = Yup.object().shape({
  file: Yup.mixed()
    .test(
      'fileSize',
      <FormattedMessage {...messages.sizeError} />,
      (value) => value.size || value.fileSize <= FILE_SIZE,
    )
    .test('fileFormat', <FormattedMessage {...messages.typeError} />, (value) =>
      SUPPORTED_FORMATS.includes(value.type),
    ),
});

function Request({ colorType, token }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectCodeReviewUploadLoading);
  const uploadedImages = useSelector(selectCodeReviewUploadImages);

  const handleSubmit = () => {
    dispatch(updateRequest({ token }));
  };

  const sendImage = ({ file }) => {
    dispatch(imageUploadRequest({ file, token }));
  };

  return (
    <>
      <Box monochrome colorType={colorType} text={<FormattedMessage {...messages.text} />} />

      <Formik initialValues={{}} validationSchema={validationSchema} onSubmit={sendImage}>
        {({ resetForm }) => (
          <Form>
            <Field
              name="file"
              component={FileUpload}
              colorType={colorType}
              buttonLabel={
                <FormattedMessage
                  {...messages[isEmpty(uploadedImages) ? 'uploadPictures' : 'uploadMorePictures']}
                />
              }
              isLoading={isLoading}
              overrideSubmitted
              accept="image/*"
            />

            <FormikAutoSave
              onSave={(values) => {
                if (values.file) sendImage(values);
                resetForm({ file: null });
              }}
            />
          </Form>
        )}
      </Formik>

      <Button onClick={handleSubmit} colorType={colorType} disabled={isEmpty(uploadedImages)}>
        <FormattedMessage {...messages.doneButton} />
      </Button>
    </>
  );
}

Request.propTypes = {
  colorType: PropTypes.string,
  token: PropTypes.string,
  data: PropTypes.object,
};

export default Request;
