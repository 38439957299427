import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { getPrivacyPolicySignupConfigResponse } from '../actions';

export default function* getPrivacyPolicySignupConfig({
  payload: { primaryBrand, secondaryBrand, country },
}) {
  const url = `privacy-policy-service/signup/configuration`;

  try {
    const response = yield call(fetchWrapper, {
      url,
      authenticated: false,
      withQuery: true,
      params: {
        secondaryBrand,
        primaryBrand,
        country,
        platform: 'WEB',
      },
    });

    yield put(getPrivacyPolicySignupConfigResponse(response));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
