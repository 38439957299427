/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {
  primaryColor,
  secondaryAccentColor,
  secondaryColor,
} from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import { Div } from '@ebay-certilogo/design-system-web';
import { getLocalImage } from 'utils/images';
import {
  contentWrapper,
  fontSize,
  padding,
} from '@ebay-certilogo/design-system-web/dist/shared/styles';

export const LicenceCn = styled.a`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  ${fontSize('xxxs')}
  ${padding('m')}
`;

export const ButtonAuthWrapper = styled(Div)`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${contentWrapper}
  ${padding('m', 'horizontal')}
`;

export const HeaderHome = styled(Div)`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  height: 150px;
  position: fixed;
`;

export const HeaderLogo = styled.img`
  top: 50%;
  left: 50%;
  width: 145px;
  position: absolute;
  pointer-events: auto;
  transform: translate3d(-50%, -50%, 0);
`;

export const HomeWrapper = styled(Div)`
  ${({ backgroundImage }) => backgroundImage && `background-image: url(${backgroundImage})`};
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  a {
    text-decoration: none;
  }

  .big-cta {
    opacity: 0;
    padding: 70px;
    pointer-events: none;
    color: ${secondaryColor};
    color: ${primaryColor};
    font-size: 35px;
    font-weight: 900;
    text-align: left;
    text-indent: 10%;
    position: relative;
    display: inline-block;
    z-index: 10;
    white-space: nowrap;
    transition: all 0.5s linear !important;
  }

  .big-cta,
  .big-cta *,
  .big-cta:after,
  .big-cta:before {
    transition: all 0.2s linear;
  }

  .big-cta * {
    pointer-events: none;
  }

  .big-cta .primary-text {
    z-index: 3;
    display: block;
    text-indent: 0;
    font-size: 150px;
    line-height: 105px;
    position: relative;
    color: ${primaryColor} !important;
    transform-origin: left center;
    transition: all 0.7s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  .big-cta::before {
    right: 145px;
    z-index: 0;
    height: 3px;
    width: 125px;
    content: ' ';
    bottom: 43px;
    display: block;
    position: absolute;
    background: ${secondaryColor};
    background: ${primaryColor};
  }

  .big-cta__deco {
    z-index: 0;
    right: -5px;
    width: 150px;
    height: 150px;
    bottom: -30px;
    position: absolute;
    border-radius: 50%;
    position: absolute;
    background: ${secondaryAccentColor};
  }

  .big-cta__deco::before {
    left: 0;
    top: 50%;
    width: 35%;
    z-index: 0;
    height: 3px;
    content: ' ';
    display: block;
    position: absolute;
    background: ${secondaryColor};
    transform: translate3d(0, -50%, 0);
  }

  .big-cta__deco::after {
    top: 50%;
    left: 50%;
    z-index: 0;
    width: 40px;
    height: 40px;
    content: ' ';
    display: block;
    position: absolute;
    background: url(${getLocalImage('/images/arrow-big-black.png')}) center center no-repeat;
    background-size: contain;
    transform: translate3d(-50%, -50%, 0);
  }

  .big-cta__canvas {
    left: 52%;
    opacity: 0;
    width: 10vw;
    height: 10vw;
    position: absolute;
    top: calc(65% - 30px);
    background: url(${getLocalImage('/images/cta-canvas-w.png')}) center center no-repeat;
    background-size: contain;
    transform: translate3d(-50%, -50%, 0);
    transition: all 0.7s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  .big-cta--lettere-alte .primary-text {
    font-size: 150px;
    line-height: 180px;
  }

  .big-cta--too-long .primary-text {
    font-size: 100px;
  }

  .big-cta--lettere-alte::before {
    bottom: 59px;
  }

  .big-cta--lettere-alte .big-cta__deco {
    bottom: -15px;
  }

  .big-cta.active {
    opacity: 1;
    pointer-events: auto;
  }

  .cta-auth {
    top: 34%;
    left: 50%;
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
  }

  .cta-web {
    top: 66%;
    left: 30%;
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
  }

  @media (min-width: 992px) {
    .big-cta:hover {
      color: transparent;
    }

    .big-cta:hover *,
    .big-cta:hover:before {
      opacity: 0;
    }

    .big-cta:hover .big-cta__canvas {
      opacity: 1;
      width: 15vw;
      height: 15vw;
    }

    .big-cta:hover .primary-text {
      opacity: 1;
      color: ${secondaryColor};
      transform: scale(0.6);
    }
  }

  @media (max-width: 1500px) {
    .big-cta .primary-text {
      font-size: 90px;
      line-height: 75px;
    }

    .big-cta--lettere-alte .primary-text {
      font-size: 90px;
      line-height: 90px;
    }

    .big-cta--lettere-alte .primary-text {
      font-size: 90px;
      line-height: 110px;
    }
  }

  @media (max-width: 991px) {
    #header {
      height: 80px;
    }

    .big-cta {
      padding: 20px;
      font-size: 5vw;
      white-space: nowrap;
    }

    .big-cta .primary-text {
      font-size: 10vw;
      line-height: 10vw;
    }

    .big-cta--lettere-alte .primary-text {
      font-size: 10vw;
      line-height: 12vw;
    }

    .big-cta::before {
      right: 20px;
      height: 2px;
      bottom: 9px;
      width: 60px;
    }

    .big-cta:after {
      width: 25px;
      left: -40px;
      height: 25px;
    }

    .big-cta .primary-text::after {
      bottom: -28px;
      font-size: 60px;
      left: calc(100% + 2px);
    }

    .big-cta__deco {
      width: 50px;
      height: 50px;
      right: -10px;
      bottom: -15px;
    }

    .big-cta__deco::before {
      width: 35%;
      height: 2px;
    }

    .big-cta__deco::after {
      width: 20px;
      height: 20px;
    }

    .cta-auth {
      top: 45%;
      left: 20px;
      transform: translate3d(0, -50%, 0);
    }

    .cta-web {
      top: 70%;
      left: 20px;
      transform: translate3d(0, -50%, 0);
    }

    #background-video__element--desktop {
      display: none;
    }

    #background-video__element--mobile {
      display: block;
    }

    .background-video__element {
      height: 173.536036036vw;
      width: 0.5762491888vh;
      transform: translate3d(-50%, -50%, 0) scale(1.15);
    }
  }
`;

// export const BigCtaDeco = styled(Div)`
//   z-index: 0;
//   right: -5px;
//   width: 150px;
//   height: 150px;
//   bottom: -30px;
//   position: absolute;
//   border-radius: 50%;
//   position: absolute;
//   background: ${secondaryAccentColor};

//   &:before {
//     left: 0;
//     top: 50%;
//     width: 35%;
//     z-index: 0;
//     height: 3px;
//     content: ' ';
//     display: block;
//     position: absolute;
//     background: ${secondaryColor};
//     transform: translate3d(0, -50%, 0);
//   }
//   &:after {
//     top: 50%;
//     left: 50%;
//     z-index: 0;
//     width: 40px;
//     height: 40px;
//     content: ' ';
//     display: block;
//     position: absolute;
//     background: url('/images/arrow-big-black.png') center center no-repeat;
//     background-size: contain;
//     transform: translate3d(-50%, -50%, 0);
//   }
// `;

// export const BigCtaCanvas = styled(Div)`
//   left: 52%;
//   opacity: 0;
//   width: 10vw;
//   height: 10vw;
//   position: absolute;
//   top: calc(65%-30px);
//   background: url('/images/cta-canvas-w.png') center center no-repeat;
//   background-size: contain;
//   transform: translate3d(-50%, -50%, 0);
//   transition: all 0.7s cubic-bezier(0.785, 0.135, 0.15, 0.86);
// `;

// export const CtaAuth = styled(Div)`
//   top: 34%;
//   left: 50%;
//   position: absolute;
//   transform: translate3d(-50%, -50%, 0);

//   ${media.desktop`
//     top: 45%;
//     left: 20px;
//     transform: translate3d(0, -50%, 0);
//   `}
// `;

// export const CtaWeb = styled(Div)`
//   top: 66%;
//   left: 30%;
//   position: absolute;
//   transform: translate3d(-50%, -50%, 0);

//   ${media.desktop`
//     top: 70%;
//     left: 20px;
//     transform: translate3d(0, -50%, 0);
//   `}
// `;

// export const BigCta = styled(Div)`
//   opacity: 0;
//   padding: 70px;
//   pointer-events: none;
//   color: ${primaryColor};
//   font-size: 35px;
//   font-weight: 900;
//   text-align: left;
//   text-indent: 10%;
//   position: relative;
//   display: inline-block;
//   z-index: 10;
//   white-space: nowrap;
//   transition: all 0.5s linear !important;

//   *,
//   :before,
//   :after {
//     transition: all 0.2s linear;
//   }

//   * {
//     pointer-events: none;
//   }

//   ${media.desktop`
//     &:hover {
//       color: transparent;
//       *,
//       &:before {
//         opacity: 0;
//       }
//       ${BigCtaCanvas} {
//         opacity: 1;
//         width: 15vw;
//         height: 15vw;
//       }
//       span {
//         opacity: 1;
//         color: ${secondaryColor};
//         transform: scale(0.6);
//       }
//     }

//     padding: 20px;
//     font-size: 5vw;
//     white-space: nowrap;

//     span {
//       font-size: 10vw;
//       line-height: 10vw;
//       &:after {
//         bottom: -28px;
//         font-size: 60px;
//         left: calc(100% + 2px);
//       }
//     }

//     &:before {
//       right : 20px;
//       height: 2px;
//       bottom: 9px;
//       width : 60px
//     }

//     &:after {
//       width : 25px;
//       left  : -40px;
//       height: 25px
//     }

//     ${BigCtaDeco} {
//       width : 50px;
//       height: 50px;
//       right : -10px;
//       bottom: -15px;
//       &:before {
//         width : 35%;
//         height: 2px;
//       }
//       &:after {
//         width : 20px;
//         height: 20px
//       }
//     }
// 	`}

//   ${media.desktop`
//     span {
//       font-size: 90px;
//       line-height: 75px
//     }
// 	`}

//   span {
//     z-index: 3;
//     display: block;
//     text-indent: 0;
//     font-size: 150px;
//     line-height: 105px;
//     position: relative;
//     color: ${primaryColor} !important;
//     transform-origin: left center;
//     transition: all 0.7s cubic-bezier(0.785, 0.135, 0.15, 0.86);
//   }

//   &:before {
//     right: 145px;
//     z-index: 0;
//     height: 3px;
//     width: 125px;
//     content: ' ';
//     bottom: 43px;
//     display: block;
//     position: absolute;
//     background: ${primaryColor};
//   }
//   ${({ highFont }) =>
//     highFont &&
//     css`
//       span {
//         line-height: 180px;
//       }
//       &:before {
//         bottom: 59px;
//       }
//       ${BigCtaDeco} {
//         bottom: -15px;
//       }
//       ${media.tablet`
//         span {
//           font-size  : 10vw;
//           line-height: 12vw
//         }
//       `}
//       ${media.desktop`
//         span {
//           font-size  : 90px;
//           line-height: 110px
//         }
//       `}
//     `}
//   ${({ active }) =>
//     active &&
//     css`
//       opacity: 1;
//       pointer-events: auto;
//     `}
// `;
