
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import useColorType from 'components/hooks/useColorType';
import { Div } from '@ebay-certilogo/design-system-web';
import { color } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';

const Wrapper = styled(Div)`
  color: ${color};
`;

function Privacy() {
  const colorType = useColorType('privacy');
  return (
    <Wrapper colorType={colorType}>
      <FormattedMessageMarkdown
        colorType={colorType}
        id="certilogo.privacy"
        defaultMessage="Privacy"
        isInline={false}
      />
    </Wrapper>
  );
}

Privacy.propTypes = {
  colorType: PropTypes.string,
  children: PropTypes.node,
};

export default Privacy;
