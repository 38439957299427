import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Layout } from '@ebay-certilogo/design-system-web';
import { Link as _Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsAuthenticated, selectPrivacyPolicySignupConfig } from 'containers/Auth/selectors';
import { logout } from 'containers/Auth/actions';
import { menuClick } from 'utils/reduxBeaconGtm/actions/generic';
import LanguageSelector from 'components/LanguageSelector';
import { closeMenu } from 'containers/App/actions';
import { get } from 'lodash';
import {
  Places,
  Document,
  User,
  Gear,
  Email,
  Exit,
  Enter,
} from '@ebay-certilogo/design-system-web/dist/components/icons';
import messages from './messages';

const { Sidebar } = Layout;

const Link = styled(_Link)`
  text-decoration: none;
`;

const Anchor = styled.a`
  text-decoration: none;
`;

const Separator = styled.div`
  height: 30px;
`;

function Menu({ colorType }) {
  const dispatch = useDispatch();
  const isAutenticated = useSelector(selectIsAuthenticated);
  const privacyPolicySignupConfig = useSelector(selectPrivacyPolicySignupConfig);

  const menuItems = useMemo(
    () => [
      {
        target: '_blank',
        href: privacyPolicySignupConfig?.privacyDocument?.url,
        message: messages.privacy,
        label: 'privacy_policy',
        icon: <Document />,
      },
      {
        to: '/cookie-policy',
        message: messages.cookie,
        label: 'cookie_policy',
        icon: <Document />,
      },
      {
        target: '_blank',
        href: privacyPolicySignupConfig?.tcDocument?.url,
        message: messages.terms,
        label: 'terms_&_conditions',
        icon: <Document />,
      },
    ],
    [privacyPolicySignupConfig],
  );

  return (
    <>
      <Sidebar.Item colorType={colorType} icon={<Places />}>
        <LanguageSelector
          colorType={colorType === 'dark' ? 'light' : 'dark'}
          onChange={() => {
            dispatch(closeMenu());
            dispatch(menuClick('language'));
          }}
        />
      </Sidebar.Item>
      <Separator />
      {menuItems.map(({ message, label, icon, ...rest }) => {
        const LinkComponent = rest.href ? Anchor : Link;
        return (
          <Sidebar.Item colorType={colorType} key={message.id} icon={icon}>
            <LinkComponent {...rest} onClick={() => dispatch(menuClick(label))}>
              <FormattedMessage {...message} />
            </LinkComponent>
          </Sidebar.Item>
        );
      })}
      <Separator />
      <Sidebar.Item colorType={colorType} icon={<User />}>
        <Link to="/auth/info" onClick={() => dispatch(menuClick('info'))}>
          <FormattedMessage {...get(messages, `info${isAutenticated ? 'Auth' : ''}`)} />
        </Link>
      </Sidebar.Item>
      {isAutenticated && (
        <Sidebar.Item colorType={colorType} icon={<Gear />}>
          <Link to="/auth/profile/settings" onClick={() => dispatch(menuClick('profile'))}>
            <FormattedMessage {...messages.settings} />
          </Link>
        </Sidebar.Item>
      )}
      <Sidebar.Item colorType={colorType} icon={<Email />}>
        <Link
          as="a"
          href="https://discover.certilogo.com/pages/help"
          target="_blank"
          onClick={() => dispatch(menuClick('help'))}
        >
          <FormattedMessage {...messages.help} />
        </Link>
      </Sidebar.Item>
      <Separator />
      {isAutenticated ? (
        <Sidebar.Item
          colorType={colorType}
          onClick={() => {
            dispatch(logout());
            dispatch(menuClick('logout'));
          }}
          icon={<Exit />}
        >
          <FormattedMessage {...messages.logout} />
        </Sidebar.Item>
      ) : (
        <Sidebar.Item colorType={colorType} icon={<Enter />}>
          <Link to="/auth/sign-up" onClick={() => dispatch(menuClick('login'))}>
            <FormattedMessage {...messages.login} />
          </Link>
        </Sidebar.Item>
      )}
    </>
  );
}

Menu.propTypes = {
  colorType: PropTypes.string,
};

export default Menu;
