import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'soa_creation_feedback.title1',
    defaultMessage: 'Request Sent',
  },
  text: {
    id: 'soa_creation_feedback.text1',
    defaultMessage:
      "Thank you! We'll shortly send your Certilogo Seal by email. You will soon find out if your product is Authentic.",
  },
});
