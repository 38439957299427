import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectConf } from 'containers/App/selectors';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import { composeTheme } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/utils';
import get from 'lodash/get';

function ThemeProvider({ children }) {
  const conf = useSelector(selectConf);
  const theme = composeTheme(get(conf, 'name', 'default'), get(conf, 'theme'));
  return <SCThemeProvider theme={theme}>{children}</SCThemeProvider>;
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
