
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function ButtonComponent({ children, component: Component, ...rest }) {
  return (
    <Component as={rest.href ? 'a' : (rest.to && Link) || 'div'} {...rest}>
      {children}
    </Component>
  );
}

ButtonComponent.propTypes = {
  children: PropTypes.node,
  component: PropTypes.node,
};

export default ButtonComponent;
