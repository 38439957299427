/* eslint-disable import/prefer-default-export */
/*
 *
 * LanguageProvider actions
 *
 */

import { createActions } from 'redux-actions';

const collection = 'language';

export const { changeLocale } = createActions(
  {
    CHANGE_LOCALE: (locale) => ({
      locale,
    }),
  },
  { prefix: collection },
);
