import * as React from 'react';

function SvgEbay(props) {
  return (
    <svg
      width="45"
      height="19"
      viewBox="0 0 45 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.1984 12.3963C33.1984 13.0544 33.2172 13.704 33.274 14.289H31.1962C31.1408 13.8073 31.1206 13.3287 31.1206 12.8645C29.9973 14.2624 28.6613 14.6643 26.8078 14.6643C24.0588 14.6643 22.5869 13.1933 22.5869 11.4917C22.5869 9.0274 24.5888 8.1585 28.0642 8.0779C29.0147 8.05591 30.0822 8.05265 30.9648 8.05265L30.9646 7.81188C30.9646 6.16213 29.9197 5.4838 28.1091 5.4838C26.7649 5.4838 25.7741 6.04696 25.6721 7.02011H23.3253C23.5736 4.59371 26.0917 3.97998 28.3057 3.97998C30.9581 3.97998 33.1984 4.93541 33.1984 7.77563V12.3963ZM28.2172 9.5939C26.1919 9.66112 24.9335 10.0311 24.9335 11.3816C24.9335 12.2561 25.622 13.2039 27.3699 13.2039C29.7133 13.2039 30.9643 11.9107 30.9643 9.78961L30.9644 9.55628C30.1428 9.55628 29.1296 9.56366 28.2172 9.5939V9.5939Z"
        fill="#F5AF02"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.27467 9.89434C2.39522 11.9468 3.79321 13.1607 5.71544 13.1607C7.04725 13.1607 8.23193 12.6114 8.62861 11.4162H10.9322C10.4842 13.8411 7.93916 14.6642 5.74845 14.6642C1.7653 14.6642 0 12.4433 0 9.44839C0 6.15135 1.8259 3.97998 5.78483 3.97998C8.93596 3.97998 11.2448 5.64955 11.2448 9.29364V9.89434H2.27467ZM8.89813 8.3905C8.81145 6.27519 7.30334 5.48364 5.69154 5.48364C3.95354 5.48364 2.56591 6.37402 2.32287 8.3905H8.89813Z"
        fill="#E53238"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.242 0H13.4751L13.4749 5.68017C14.5694 4.35982 16.0774 3.97645 17.5617 3.97645C20.0503 3.97645 22.8144 5.67662 22.8144 9.34755C22.8144 12.4216 20.6161 14.6607 17.5202 14.6607C15.8997 14.6607 14.3743 14.0721 13.4336 12.9061C13.4336 13.3719 13.4079 13.8413 13.3576 14.2854H11.166C11.2041 13.5675 11.242 12.6735 11.242 11.9503V0ZM16.9715 13.1143C19.0473 13.1143 20.4677 11.6016 20.4677 9.31883C20.4677 7.0364 19.0473 5.52354 16.9715 5.52354C14.9074 5.52354 13.4751 7.0364 13.4751 9.31883C13.4751 11.6016 14.9074 13.1143 16.9715 13.1143V13.1143Z"
        fill="#0064D2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.5717 4.35547L37.6656 18.0865H35.1649L37.1503 14.2737L31.9531 4.35547H34.5662L38.3906 12.1047L42.2043 4.35547H44.5717Z"
        fill="#86B817"
      />
    </svg>
  );
}

export default SvgEbay;
