import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { imageUploadResponse } from '../actions';

export default function* imageUpload({ payload: { token, file: fileData } }) {
  const url = 'codeReview/v3/image/';

  const params = new FormData();
  params.append('file', fileData);
  params.append('token', token);

  try {
    yield call(fetchWrapper, {
      method: 'POST',
      url,
      formData: params,
    });

    yield put(imageUploadResponse({ filename: fileData.name }));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
