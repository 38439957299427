/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBlacklistFilter } from 'redux-persist-transform-filter';

import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import globalReducer from 'containers/App/reducer';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import fakeReport from 'containers/FakeReport/reducer';
import soa from 'containers/Soa/reducer';
import authentication from 'containers/Authentication/reducer';
import connect from 'containers/Connect/reducer';
import onboarding from 'containers/Onboarding/reducer';
import survey from 'containers/Survey/reducer';
import auth from 'containers/Auth/reducer';
import codeReview from 'containers/CodeReview/reducer';
import warranty from 'containers/Warranty/reducer';
import resellWithEbay from 'containers/ResellWithEbay/reducer';
import claimOwnership from 'containers/ClaimOwnership/reducer';
import proxy from 'containers/Proxy/reducer';
import history from 'utils/history';
import { gtmMetaReducer } from 'utils/reduxBeaconGtm';
import storage from './storage';

const blacklistGlobalFilter = createBlacklistFilter('global', [
  'appLoading',
  'promptActive',
  'menuIsOpen',
  'menuVisible',
]);

const persistConfig = {
  key: 'authenticator-mystique:root',
  storage,
  whitelist: ['global', 'language', 'auth'],
  transforms: [blacklistGlobalFilter],
  stateReconciler: autoMergeLevel2,
};

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    global: globalReducer,
    language: languageProviderReducer,
    router: connectRouter(history),
    auth,
    fakeReport,
    soa,
    connect,
    authentication,
    onboarding,
    survey,
    codeReview,
    warranty,
    resellWithEbay,
    claimOwnership,
    proxy,
    gtmMetaReducer,
    ...injectedReducers,
  });

  return persistReducer(persistConfig, rootReducer);
}
