
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Loader as _loader } from '@ebay-certilogo/design-system-web';
import { selectGlobalColorType } from 'containers/App/selectors';
import Wrapper from './Wrapper';

const Loader = styled(_loader).attrs(({ size }) => ({
  width: size,
  height: size,
}))`
  ${({ size = 40 }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
`;

function LoadingIndicator({ size = 40, colorType: _colorType, ...props }) {
  const colorType = _colorType || useSelector(selectGlobalColorType);
  return (
    <Wrapper {...props}>
      <Loader colorType={colorType} size={size} />
    </Wrapper>
  );
}

LoadingIndicator.propTypes = {
  colorType: PropTypes.string,
  size: PropTypes.number,
};

export default LoadingIndicator;
