import { selectIsAuthenticated, selectProfile, selectUserData } from 'containers/Auth/selectors';
import { selectProxySession } from 'containers/Proxy/selectors';
import { selectAuthenticationData } from 'containers/Authentication/selectors';
import { selectConnectData } from 'containers/Connect/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';

const defaultObj = {
  event: null,
  eventName: null,
  origin: null,
  userID: null,
  userSignupDate: null,
  userBirthday: null,
  userGender: null,
  sessionID: null,
  flow: null,
  first_seconduser: null,
  consoleName: null,
  consoleType: null,
  primaryBrand: null,
  secondaryBrand: null,
  logged: null,
  language: null,
};

export const formatData = (timestamp) => {
  if (!timestamp) return null;

  const date = new Date(
    `${timestamp.slice(0, 4)}-${timestamp.slice(4, 6)}-${timestamp.slice(6, 8)}`,
  );
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  return `${year}-${month}-${day}`;
};

export const getBaseObject = ({ action, nextState }) => {
  const isAuthenticated = selectIsAuthenticated(nextState);
  const userData = selectUserData(nextState);
  const userProfile = selectProfile(nextState);
  const connect = selectConnectData(nextState);
  const authentication = selectAuthenticationData(nextState);
  const language = makeSelectLocale(nextState);
  const userGender = ['m', 'f'].includes(userProfile?.gender) ? userProfile?.gender : 'x';
  const logged = userData?.id ? 'second' : 'first';
  const sessionId = selectProxySession(nextState);

  return {
    ...defaultObj,
    origin: action?.payload?.origin,
    userID: userProfile?.id,
    userLoginType: userProfile?.network,
    userSignupDate: isAuthenticated ? formatData(userProfile?.createdAt) : null,
    userBirthday: isAuthenticated ? formatData(userProfile?.birthdate) : null,
    userGender: isAuthenticated ? userGender : null,
    first_seconduser: isAuthenticated ? logged : null,
    logged: isAuthenticated ? 'logged' : 'not_logged',
    sessionID: sessionId,
    flow: authentication?.flow,
    consoleName: connect?.connect?.name,
    consoleType: connect?.authentication?.response || 'default',
    primaryBrand: connect?.info?.primaryBrandId || authentication?.primaryBrand?.id,
    secondaryBrand: connect?.info?.tmBrandId || authentication?.tmBrand?.id,
    language,
  };
};

export default {
  getBaseObject,
  formatData,
};
