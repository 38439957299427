import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { getMarketplaceSoaResponse } from '../actions';

export default function* getSoa({ payload: { id } }) {
  const url = `soa/v1/${id}`;

  try {
    const { marketplaceAliases } = yield call(fetchWrapper, {
      method: 'GET',
      url,
    });

    yield put(getMarketplaceSoaResponse(marketplaceAliases));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
}
