import produce from 'immer';
import { handleActions } from 'redux-actions';
import { filter, isEmpty } from 'lodash';
import {
  createRequest,
  flowRequest,
  claimOwnershipResponse,
  imageUploadRequest,
  imageUploadResponse,
  imageDeleteResponse,
  resetClaimOwnership,
  imageDeleteRequest,
  getRequest,
} from './actions';

export const initialState = {
  loading: false,
  data: null,
  imageUploadLoading: false,
  imageDeleteLoading: false,
};

const mutableReducer = handleActions(
  {
    [createRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [getRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [flowRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [claimOwnershipResponse]: produce((draft, { payload }) => {
      draft.loading = false;
      draft.data = payload;
    }),
    [imageUploadRequest]: produce((draft) => {
      draft.imageUploadLoading = true;
    }),
    [imageUploadResponse]: produce((draft, { payload: image }) => {
      draft.imageUploadLoading = false;
      if (!isEmpty(image)) {
        draft.data.props.images = [...draft.data.props.images, image];
      }
    }),
    [imageDeleteRequest]: produce((draft) => {
      draft.imageDeleteLoading = true;
    }),
    [imageDeleteResponse]: produce((draft, { payload: { id } }) => {
      draft.imageDeleteLoading = false;
      if (id) {
        draft.data.props.images = filter(draft.data.props.images, ({ imageId }) => id !== imageId);
      }
    }),
    [resetClaimOwnership]: () => initialState,
  },
  initialState,
);

export default mutableReducer;
